/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types"

import { MediaQuery } from "@utils/style"

ContentContainer.propTypes = {
  wrapper: PropTypes.bool,
}
ContentContainer.defaultProps = {
  wrapper: false,
}

function ContentContainer(props) {
  const { wrapper } = props

  if (wrapper)
    return (
      <div css={styles.container} className="content-container">
        <div
          css={[styles.wrapper, styles.tableWrapper]}
          className="content-wrapper"
        >
          {props.children}
        </div>
      </div>
    )

  return (
    <div css={styles.container} className="content-container">
      {props.children}
    </div>
  )
}

const styles = {
  container: {
    backgroundColor: "#FFF",
    overflow: "hidden",
    borderRadius: 4,
    margin: "8px 16px",
    position: "relative",
    [MediaQuery[0]]: {
      margin: 8,
    },
    "& .ant-form-item": {
      marginBottom: 0,
    },
  },
  wrapper: {
    padding: "0 24px",
    [MediaQuery[0]]: {
      padding: "0 16px",
    },
  },
  tableWrapper: {
    padding: "24px 24px 16px",
    [MediaQuery[0]]: {
      padding: "8px 16px",
    },
  },
}

export default ContentContainer
