import React, { useEffect } from "react"
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom"
import { observer } from "mobx-react"
import { ConfigProvider } from "antd"
import "@app/assets/styles/customTheme.less"

import routes from "@app/routes"
import PageLoading from "@components/common/Loading"
import { useMobxStores } from "@stores/stores"
import "dayjs/locale/ko"
import locale from "antd/lib/locale/ko_KR"

import { AxiosInterceptorsSetup } from "@api/index"
import "@app/assets/styles/App.less"

function AxiosInterceptorNavigate() {
  let navigate = useNavigate()
  AxiosInterceptorsSetup(navigate)
  return <></>
}

export default observer(function App() {
  const { userStore } = useMobxStores()

  useEffect(() => {
    userStore?.initAuthStore()
  }, [])

  return (
    <>
      <ConfigProvider locale={locale}>
        <BrowserRouter>
          {<AxiosInterceptorNavigate />}
          <React.Suspense fallback={PageLoading()}>
            <Routes>
              <Route element={<PrivateRoute />}>
                {routes?.authRoutes?.map((route, i) => {
                  return (
                    <Route key={i} element={<route.layout />}>
                      {route?.contents?.map((contents, j) => {
                        return (
                          <Route
                            key={j}
                            path={contents.path}
                            name={contents.name}
                            element={<contents.component />}
                          />
                        )
                      })}
                    </Route>
                  )
                })}
              </Route>
              {routes?.noAuthRoutes?.map((route, i) => {
                return (
                  <Route key={i} element={<route.layout />}>
                    {route?.contents?.map((contents, j) => {
                      return (
                        <Route
                          key={j}
                          path={contents.path}
                          name={contents.name}
                          element={<contents.component />}
                        />
                      )
                    })}
                  </Route>
                )
              })}
              <Route path="*" element={<Navigate to={routes.defaultPath} />} />
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </>
  )
})

// 로그인이 안되었을 경우 로그인 페이지로 이동
const PrivateRoute = observer(() => {
  const { userStore } = useMobxStores()
  const accessToken =
    userStore.accessToken || localStorage.getItem("access-token")
  return accessToken ? <Outlet /> : <Navigate to="/login" />
})
