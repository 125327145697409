/** @jsxImportSource @emotion/react */
import { Collapse } from "antd";
import PageContainer from "@components/container/PageContainer";
import PageHeader from "@components/container/HeaderContainer";
import ContentContainer from "@components/container/ContentContainer";

import serviceStat from "@app/assets/images/serviceStat.jpg";
import callLog from "@app/assets/images/callLog.jpg";
import myInfo from "@app/assets/images/myInfo.jpg";
import record from "@app/assets/images/record.jpg";
import coloring from "@app/assets/images/coloring.png";
import coloringDetail from "@app/assets/images/coloringDetail.PNG";
import stats from "@app/assets/images/stats.jpg";
import virtualNumber from "@app/assets/images/virtualNumber.PNG";
import virtualNumberKeyword from "@app/assets/images/virtualNumberKeyword.jpeg";
import virtualNumberDetail from "@app/assets/images/virtualNumberDetail.PNG";
import externalColoring from "@app/assets/images/externalColoring.PNG";
import vnHistory from "@app/assets/images/vnHistory.PNG";
import vnHistoryList from "@app/assets/images/vnHistoryList.PNG";

export default function AgentStatusPage() {
  return (
    <div css={styles.wrap}>
      <PageContainer>
        <PageHeader title="050비즈콜 어드민 매뉴얼" />
        <ContentContainer>
          <Collapse>
            <Collapse.Panel header="서비스현황" key="1">
              <img src={serviceStat} />
              <p>
                서비스 현황 : 현재 등록되어있는 컬러링, 알림멘트 수가
                표시됩니다.
              </p>
            </Collapse.Panel>
            <Collapse.Panel header="컬러링, 알림멘트 관리" key="2">
              <img src={coloring} />
              <div style={{ display: "flex" }}>
                <p>
                  1) 검색 <br />
                  1-1) 조회 날짜 : 컬러링 또는 알림멘트를 등록한 날짜를 조건으로
                  검색할 수 있습니다. <br />
                  1-2) 음원 형식 : 컬러링 또는 알림멘트의 음원이 등록된 형식에
                  따라 검색하실 수 있습니다. <br />
                  1-3) 종류 : 기본, 휴무, 영업종료 등의 컬러링 종류를 조건으로
                  검색할 수 있습니다. <br />
                  1-4) 설명 : 각 컬러링 또는 알림멘트에 기입한 설명 혹은 문구를
                  조건으로 검색할 수 있습니다. <br />
                  <br />
                  2) 등록, 수정 : 로컬에 있는 WAV 파일을 컬러링으로 등록 또는
                  수정 할 수 있습니다. <br />
                  파일형식의 경우에는 mp3, wav 형식의 파일만 사용 가능합니다.
                  <br />
                  2-1) TTS 음원형식 사용 권한이 있으신 경우, 음원을 TTS로 사용
                  하실 수 있습니다. TTS파일이란 문자를 사람 음성으로 변환하는
                  기술로,
                  <br />
                  문구(설명)란에 텍스트를 기입하시면, 음원으로 변환되서 별도의
                  파일없이 사용하실 수 있습니다. <br />
                  **예시){" "}
                  {`<vtml_speed value="100">
                    <vtml_pause time="900" />
                    TTS 변환 텍스트
                  </vtml_speed>`}
                  <br />
                  <br />
                  3) 리스트 <br />
                  3-1) 아이디 : 컬러링 또는 알림멘트 정보의 고유값으로, 클릭시에
                  상세(수정)화면이 보여집니다. <br />
                  3-2) 사업장 : 고객님의 사업장이 표시됩니다. <br />
                  3-3) 음원 형식 : 등록 시 어떤 음원 형식(WAV,TTS)으로
                  등록했는지 표시합니다.
                  <br /> 3-4) 종류 : 영업종류(기본, 휴무, 영업종료)를
                  표시합니다.
                  <br />
                  3-5) 설명 : 해당 컬러링 또는 알림멘트에 대한 설명 혹은 문구를
                  표시합니다. <br />
                  3-6) 설정된 번호수 : 해당 컬러링 또는 알림멘트에 맵핑 되어있는
                  가상번호 수를 표시합니다. <br />
                  3-7) 등록일 : 해당 컬러링 또는 알림멘트가 등록된
                  날짜(연월일시분초)를 표시합니다. <br />
                </p>
                <img src={coloringDetail} />
              </div>
            </Collapse.Panel>
            <Collapse.Panel header="050번호(가상번호)맵핑관리" key="3">
              <div style={{ display: "flex" }}>
                <img src={virtualNumber} />
                <img
                  src={virtualNumberKeyword}
                  style={{ marginRight: "5px", maxHeight: "400px" }}
                />
              </div>
              <img src={vnHistory} />
              <div style={{ display: "flex" }}>
                <div>
                  <br />
                  1) 검색 <br />
                  1-1) 조회 날짜 : 050번호를 등록한 날짜를 조건으로 검색할 수
                  있습니다. <br />
                  1-2) 사용 여부 : 050번호 중 사용여부를 기준으로 검색 할 수
                  있습니다.
                  <br />
                  1-3) 착신번호 : 050번호에 설정된 착신번호를 기준으로
                  검색합니다. <br />
                  1-4) 메모 : 050번호에 설정된 메모를 기준으로 검색합니다.
                  <br />
                  <br />
                  2) EXCEL <br />
                  2-1) 맵핑 폼 다운로드 : 가상번호를 매핑하실 폼을 다운로드
                  받습니다.
                  <br />
                  2-2) 일괄맵핑 업로드 : 2-1)에서 받으신 폼에 설정하실 착신번호
                  및 메모를 입력하셔서 업로드 하시면
                  <br />
                  매핑 값이 수정 됩니다. <br />
                  2-3) 다운로드 : 검색하셨던 필터 값을 기준으로 엑셀 리스트를
                  다운로드 받습니다.
                  <br />
                  <br />
                  3) 리스트 <br />
                  3-1) 업체명 : 고객님의 업체가 표시됩니다.
                  <br />
                  3-2) 가상번호(050번호) : 고객님에게 할당된 가상번호가
                  표시됩니다.
                  <br />
                  3-3) 사용여부 : 해당 가상번호의 사용 유무가 표시됩니다.
                  <br />
                  3-4) 착신번호 : 해당 가상번호로 부터 착신되는 번호가
                  표시됩니다.
                  <br />
                  3-5) 컬러링 : 해당 가상번호의 컬러링의 설명(문구)가
                  표시됩니다.
                  <br />
                  3-6) 알림멘트 : 해당 가상번호의 알림멘트의 설명(문구)가
                  표시됩니다.
                  <br />
                  3-7) 녹취여부 : 해당 가상번호의 녹취여부가 표시됩니다.
                  녹취여부의 경우 해당 권한이 있는 유저만 사용 가능합니다.
                  <br />
                  3-8) 메모 : 해당 가상번호에 대한 메모를 사용하실 수 있습니다.
                  <br />
                  3-9) 자동해지 : 날짜 및 시간을 설정하신 경우, 해당 날짜 및
                  시간에 맞춰 착신번호가 자동 해지 됩니다. <br />
                  3-10) 미 운영 컬러링 : 미 운영 시간대의 컬러링의 설정 여부가
                  표기됩니다.
                  <br />
                  3-11) 주일 컬러링 : 주일 시간대의 컬러링의 설정 여부가 표기
                  됩니다. <br />
                  3-12) 등록일 : 가상번호를 등록하신 날짜가 표기됩니다. <br />
                  3-13) 이력보기 : 버튼 클릭 시 해당 가상번호에 대한 맵핑 이력을
                  확인할 수 있습니다. 맵핑 이력이 없다면 아무 데이터도 나오지
                  않습니다..
                  <br />
                  <br />
                  4) 수정 <br />
                  4-1) 사용 여부 : 해당 가상번호에 대한 사용 여부를 수정합니다.
                  <br />
                  4-2) 착신 번호 : 해당 가상번호에 착신되는 번호를 수정 혹은
                  등록 합니다.
                  <br />
                  4-3) 컬러링 : 해당 가상번호에 사용되는 컬러링을 선택합니다.
                  컬러링은 컬러링 관리페이지에서 등록 하셔서 사용하시면 됩니다.
                  <br />
                  4-4) 알림멘트 : 해당 가상번호에 사용되는 알림멘트를
                  선택합니다. 알림멘트은 알림멘트 관리페이지에서 등록 하셔서
                  사용하시면 됩니다.
                  <br />
                  4-5) 메모 : 해당 가상번호에 대한 메모를 수정 합니다.
                  <br />
                  4-6) 자동 해지 설정 : 해당 가상번호의 착신번호가 자동 해지될
                  날짜를 설정합니다. <br />
                  4-7) 녹취여부 : 사용 권한이 있으신 경우, 가사번호에 대한
                  녹취여부를 선택 합니다.
                  <br />
                  <br />
                </div>
                <img src={virtualNumberDetail} />
              </div>
              <div style={{ display: "flex" }}>
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  5) 미 운영 컬러링 : 설정된 일자 혹은 시간 대에 호가 연결되지
                  않습니다. 해당 권한을 가지신 고객분들에게 제공되는 기능입니다.
                  <br /> 5-1) 미 운영 일 : 미운영 일에 연결되는 컬러링을
                  설정합니다. <br />
                  5-2) 미 운영 시간 : 미운영 시간에 연결되는 컬러링을
                  설정합니다. <br />
                  5-3) 미 운영 주차 : 5-4) 에서 설정한 주일컬러링의 미운영되었던
                  주차와 번호, 요일이 목록으로 표기됩니다. <br />
                  5-4) 주일 컬러링 : 설정된 요일의 시작시간과 종료시간의
                  컬러링을 설정합니다.
                </div>
                <img src={externalColoring} />
              </div>
              <div style={{ display: "flex" }}>
                6) 맵핑이력 상세 : 리스트에서 이력보기 버튼을 클릭시 보여지는
                창입니다.
                <br /> 6-1) 착신번호 : 해당 가상번호로 착신된 번호 입니다.{" "}
                <br />
                6-2) 컬러링 : 해당 가상번호에서 맵핑된 컬러링입니다. <br />
                6-3) 알림멘트 : 해당 가상번호에서 맵핑된 알림멘트입니다. <br />
                6-4) 메모 : 해당 가상번호에 맵핑 메모 입니다. <br />
                6-5) 등록일 : 해당 가상번호에 맵핑 등록된 날짜입니다. <br />
                6-6) 이력 : 해당 가상번호를 맵핑하신 유형이 표기됩니다 (수정,
                해제, 자동해제) <br />
                <img src={vnHistoryList} style={{ height: "200px" }} />
              </div>
            </Collapse.Panel>
            <Collapse.Panel header="CDR조회" key="4">
              <img src={callLog} />
              <p>
                1. 검색 <br />
                1-1) 조회 날짜 : 콜 로그 기록이 등록된 날짜를 조건으로 검색할 수
                있습니다. <br />
                1-2) 검색조건, 검색어 : 가상번호, 착신번호, 발신번호를 조건으로
                검색할 수 있습니다. 이 때 번호는 7자 이상 입력 하셔야 합니다.
                <br />
                1-3) 시간전체 : 통화시간을 기준으로 검색할 수 있습니다.
                <br />
                <br />
                2. EXCEL : 검색 조건에 따라 나온 결과를 엑셀파일로 Export할 수
                있습니다.
                <br />
                <br />
                3. 리스트(정렬은 종료시각 기준입니다.)
                <br />
                3-1) 사업장명 : 해당 콜을 기록한 업체의 이름을 표시합니다.
                <br />
                3-2) 발신번호 : 발신 측의 번호를 표시합니다. <br />
                3-3) 가상번호 : 수신 측에 맵핑 된 가상번호를 표시합니다. <br />
                3-4) 착신번호 : 수신측의 가상번호에 맵핑 된 실제 번호를
                표시합니다. <br />
                3-5) 호시작시각, 호응답시각, 호연결시각, 호종료시각: 해당 콜의
                시작, 응답, 연결, 종료시각을 표시합니다. <br />
                3-6) 통화시간 : 해당 콜의 통화시간을 초 단위로 표시합니다.
                <br />
                3-7) CAUSE : Q.850의 CAUSE 코드를 표시합니다.
              </p>
            </Collapse.Panel>
            <Collapse.Panel header="정보변경" key="5">
              <img src={myInfo} />
              <p>
                1)회원아이디 : 회원아이디는 변경할 수 없습니다. <br />
                2)변경할 비밀번호, 비밀번호 확인, 대표전화번호, E_MAIL :
                비밀번호, 전화번호, 이메일 등의 정보수정이 가능합니다.
              </p>
            </Collapse.Panel>
            <Collapse.Panel header="녹취 듣기" key="7">
              <img src={record} />
              <p>
                녹취 듣기 ) 녹취 기능을 사용하신다면 콜로그 조회부분 가장
                오른쪽에 녹취가 추가됩니다. 녹취 된 음성파일이 있다면
                [듣기]버튼을 이용하여 녹취 파일을 들을 수 있습니다.
              </p>
            </Collapse.Panel>
            <Collapse.Panel header="통계" key="8">
              <img src={stats} />
              <p>
                1. 검색 <br />
                1-1) 조회 날짜 : 월별통계 기록을 월별, 시간별, 일자별로 검색할
                수 있습니다. <br />
                1-2) 발신, 착신 : 발신과 착신 조건을 전체, 유선, 무선 조건으로
                검색할 수 있습니다.
                <br />
                1-3) 가상번호표시여부 : 기본 선택 시 가상번호, 상점명이 표기되지
                않습니다. 가상번호표시 선택 시 상점명, 가상번호가 표기됩니다.
                <br />
                <br />
                2. EXCEL : 검색 조건에 따라 나온 결과를 엑셀파일로 Export할 수
                있습니다.
                <br />
                <br />
                3. 리스트
                <br />
                3-1) 상점명(가상번호표시 시) : 해당 콜을 기록한 업체의 이름을
                표시합니다.
                <br />
                3-2) 일 : 해당 콜이 기록된 날짜를 표시합니다. <br />
                3-3) 가상번호(가상번호표시 시) : 수신 측에 맵핑 된 가상번호를
                표시합니다. <br />
                3-4) 통화시간, 착신 통화시간 : 해당 콜의 통화시간, 착신
                통화시간을 분 단위로 표시합니다. <br />
                3-5) 건수 : 해당 콜의 건 수를 표시합니다. 우측의 화살표를 눌러
                오름차순/내림차순 정렬이 가능합니다.
                <br />
                3-6) 통계 : 해당 통계의 합을 표시합니다.
                <br />
              </p>
            </Collapse.Panel>
          </Collapse>
        </ContentContainer>
      </PageContainer>
    </div>
  );
}

const styles = {
  wrap: {
    "& img": {
      padding: "5px 0 10px",
    },
  },
};
