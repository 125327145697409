// 핸드폰 번호 정규식
export const regExpPhoneNumber = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}/

// 비밀번호 체크
export const isPassword = (pw) => {
  const pwRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*()_+|?:{}])[A-Za-z\d~!@#$%^&*()_+|?:{}]{4,}$/
  return pwRegex.test(pw)
}

// 이메일 체크
export const isEmail = (email) => {
  const emailRegex =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  return emailRegex.test(email)
}
