import { useState, forwardRef, useImperativeHandle } from "react"
import { ConfigProvider, Empty } from "antd"
import dayjs from "dayjs"

import ResponsiveTable from "@components/table/ResponsiveTable"

import { commaOfNumber, phoneFormatter } from "@utils/format"
import { useMobxStores } from "@stores/stores"

import userApi from "@api/user"

export default forwardRef(function UserStatusTable(props, ref) {
  const { lastParams, setLastParams, openViewSider } = props
  const { globalStore } = useMobxStores()
  const [userList, setUserList] = useState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    // 페이징처리
    total: 0,
    current: 1,
    pageSize: 10,
  })

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchUserList({
        ...params,
        page: params?.page || 0,
        size: params?.size || pagination.pageSize,
      })
    },
  }))

  // 사업장 리스트
  async function fetchUserList(params) {
    setLoading(true)
    try {
      const userProm = await userApi.getAdmins(params)
      setUserList(userProm?.data?.data)
      const meta = userProm?.data?.meta
      const pageMeta = {
        total: meta.totalCount,
        current: meta.page.pageNumber + 1,
        pageSize: meta.page.pageSize,
        totalPage: meta.totalPage,
      }
      setPagination(pageMeta)
      setLoading(false)
      setLastParams({
        ...params,
        page: meta.page.pageNumber || 0,
        size: meta.page.pageSize || 10,
      })
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  const columns = [
    {
      key: "agentName",
      dataIndex: ["agent", "name"],
      title: "사업장",
      align: "center",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "id",
      dataIndex: "id",
      title: "아이디",
      align: "center",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "name",
      dataIndex: "name",
      title: "이름",
      align: "center",
      className: "selectable",
      onCell: (record) => ({
        onClick: () => openViewSider("detail", record.id),
      }),
    },
    {
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      title: "전화번호",
      align: "center",
      render(text) {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "email",
      dataIndex: "email",
      title: "이메일",
      align: "center",
    },
    {
      key: "userGrade",
      dataIndex: ["userGrade", "explain"],
      title: "등급",
      align: "center",
    },
    {
      key: "expire",
      title: "계정 만료",
      align: "center",
      render(_, record) {
        const returnVal = record?.expireDate
          ? new Date(record?.expireDate).getTime() - new Date().getTime() > 0
            ? "사용"
            : "만료"
          : "-"
        return returnVal
      },
    },
    {
      key: "enabled",
      dataIndex: "enabled",
      title: "계정 상태",
      align: "center",
      render(_, record) {
        return record?.enabled ? "활성" : "비활성"
      },
    },
    {
      key: "lastLoginDate",
      dataIndex: "lastLoginDate",
      title: "최근 접속일",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD") : "-"
      },
    },
    {
      key: "registrationDate",
      dataIndex: "registrationDate",
      title: "등록일",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD") : "-"
      },
    },
  ]
  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="검색결과가 없습니다." />}
    >
      <p style={{ fontWeight: "bold" }}>
        사용자 수 : {commaOfNumber(pagination?.total)}명
      </p>
      <ResponsiveTable
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={userList}
        pagination={pagination}
        onChange={(e) => {
          const filter = {
            ...lastParams,
            page: e.current - 1,
            size: e.pageSize,
          }
          fetchUserList(filter)
        }}
        scroll={{ x: 1600 }}
        loading={loading}
      />
    </ConfigProvider>
  )
})
