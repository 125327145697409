import manage from "@app/axios/manage"
import ContentContainer from "@app/components/container/ContentContainer"
import PageContainer from "@app/components/container/PageContainer"
import ColoringManageSearch from "@app/containers/management/coloring/list/ColoringManageSearch"
import ColoringManageTable from "@app/containers/management/coloring/list/ColoringManageTable"
import { useMobxStores } from "@app/stores/stores"
import { getQuery, pushQuery, removeEmpty } from "@app/utils/router"
import PageHeader from "@components/container/HeaderContainer"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom/dist"
import ColoringmanageDetail from "./ColoringManageDetail"

export default function AlarmManageListPage() {
  const [viewSiderComponent, setViewComponent] = useState(null)
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
  })
  const [alarm, setAlarm] = useState([])
  const [pagination, setPagination] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const query = getQuery(location)
  const { globalStore } = useMobxStores()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (query.id) {
      setViewComponent(
        <ColoringmanageDetail
          key={query.id}
          id={query.id}
          closeDetail={closeDetail}
          isAlarm
          fetchList={getAlarmList}
        />
      )
    } else if (query.add) {
      setViewComponent(
        <ColoringmanageDetail
          key={`add`}
          id={null}
          closeDetail={closeDetail}
          isAlarm
          fetchList={getAlarmList}
        />
      )
    } else {
      setViewComponent(null)
    }
  }, [location.search])

  useEffect(() => {
    getAlarmList()
  }, [filter])

  function getAlarmList() {
    setLoading(true)
    manage
      .getAlarmManageList({ ...filter, sort: "ringId,desc" })
      .then((res) => {
        const meta = res?.data?.meta
        setAlarm(res?.data?.data)
        const pageMeta = {
          total: meta.totalCount,
          current: meta.page.pageNumber + 1,
          pageSize: meta.page.pageSize,
          totalPage: meta.totalPage,
        }
        setPagination(pageMeta)
      })
      .catch((e) =>
        globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        )
      )
      .finally(() => setLoading(false))
  }

  function openViewSider(type, id) {
    if (type === "detail") {
      delete query.add
      pushQuery({ ...query, id: id }, navigate)
    } else if (type === "add") {
      delete query.id
      pushQuery({ ...query, add: true }, navigate)
    }
  }

  function onSearch(values) {
    const cleanObj = removeEmpty(values)
    if (values?.dateRange?.[0])
      cleanObj.fromDate = dayjs(values?.dateRange[0]).format("YYYYMMDD")
    if (values?.dateRange?.[1])
      cleanObj.toDate = dayjs(values?.dateRange[1]).format("YYYYMMDD")
    delete cleanObj.dateRange
    setFilter({ ...cleanObj, page: 0, size: 10 })
  }

  function closeDetail() {
    delete query.id
    delete query.add
    pushQuery(query, navigate)
  }

  return (
    <>
      <PageContainer viewSiderComponent={viewSiderComponent}>
        <PageHeader
          title="알림멘트 관리"
          subTitle="알림멘트를 세팅합니다.(수신자용)"
        />
        {/* 검색필터, 컬럼값 컬러링 목록과 동일함 */}
        <ColoringManageSearch
          onSearch={onSearch}
          openViewSider={openViewSider}
          setViewComponent={setViewComponent}
          query={query}
          closeDetail={closeDetail}
          isAlarm
        />
        <ContentContainer wrapper>
          <ColoringManageTable
            openViewSider={openViewSider}
            colorings={alarm}
            pagination={pagination}
            filter={filter}
            setFilter={setFilter}
            isAlarm
            loading={loading}
          />
        </ContentContainer>
      </PageContainer>
    </>
  )
}
