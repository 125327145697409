import ResponsiveTable from "@app/components/table/ResponsiveTable"
import {
  commaOfNumber,
  phoneFormatter,
  virtualNumberFormatter,
} from "@app/utils/format"
import { Table } from "antd"

export default function SKTLNumberTable(props) {
  const {
    openViewSider,
    virtualMappings,
    pagination,
    filter,
    setFilter,
    loading,
  } = props
  const columns = [
    {
      key: "agent.name",
      dataIndex: ["agent", "name"],
      title: "업체명",
      align: "center",
      width: "116px",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "virtualNumber",
      dataIndex: "virtualNumber",
      title: "가상번호",
      align: "center",
      width: "172px",
      className: "selectable",
      render: (text, record) => virtualNumberFormatter(text),
      onCell: (record) => ({
        onClick: () => openViewSider("detail", record?.virtualNumber),
      }),
      cardViewOnClick: (record) =>
        openViewSider("detail", record?.virtualNumber),
    },
    {
      key: "realNumber",
      dataIndex: "realNumber",
      title: "착신번호",
      align: "center",
      width: "130px",
      render: (text) => {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "colorRing",
      dataIndex: ["colorRing", "text"],
      title: "컬러링",
      align: "center",
      width: "165px",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "informRing",
      dataIndex: ["informRing", "text"],
      title: "알림멘트",
      align: "center",
      width: "165px",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "memo",
      dataIndex: "memo",
      title: "메모",
      align: "center",
      width: "116px",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "memo2",
      dataIndex: "memo2",
      title: "메모2",
      align: "center",
      width: "116px",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "registDatetime",
      dataIndex: "registDatetime",
      title: "등록일",
      align: "center",
      width: "163px",
      render(text) {
        return text || "-"
      },
    },
  ]
  return (
    <>
      <p style={{ fontWeight: "bold" }}>
        SKTL번호 수 : {commaOfNumber(pagination?.total || 0)}개
      </p>
      <ResponsiveTable
        columns={columns}
        dataSource={virtualMappings}
        pagination={pagination}
        rowKey={(e) => e.virtualNumber}
        onChange={(e) => {
          const page = {
            ...filter,
            page: e.current - 1,
            size: e.pageSize,
          }
          setFilter(page)
        }}
        scroll={{ x: 1430 }}
        loading={loading}
      />
    </>
  )
}
