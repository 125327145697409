import sktl from "@app/axios/sktl"
import ContentContainer from "@app/components/container/ContentContainer"
import PageContainer from "@app/components/container/PageContainer"
import SKTLNumberSearch from "@app/containers/management/sktl/SKTLNumberSearch"
import SKTLNumberTable from "@app/containers/management/sktl/SKTLNumberTable"
import { useMobxStores } from "@app/stores/stores"
import {
  getAccessKeys,
  getQuery,
  objectClean,
  pushQuery,
  removeEmpty,
} from "@app/utils/router"
import PageHeader from "@components/container/HeaderContainer"
import { Button } from "antd"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom/dist"
import SKTLNumberDetail from "./SKTLNumberDetail"
import { DownloadOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { fileDownload } from "@app/utils/file"

export default function SKTLNumberPage() {
  const { globalStore, userStore } = useMobxStores()
  const [viewSiderComponent, setViewComponent] = useState(null)
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
  })
  const [virtualMappings, setVirtualMappings] = useState([])
  const [pagination, setPagination] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const query = getQuery(location)
  const [checkList, setCheckList] = useState([])
  const [loading, setLoading] = useState(false)
  const accessKeys = getAccessKeys(userStore, location)
  const [excelDownloading, setExcelDownloading] = useState(false)

  useEffect(() => {
    if (query.id) {
      setViewComponent(
        <SKTLNumberDetail
          key={query.id}
          id={query.id}
          closeDetail={closeDetail}
          fetchList={() => getSKTLNumberList()}
          accessKeys={accessKeys}
        />
      )
    } else if (query.add) {
      //대량 가상번호 관리
      setViewComponent(
        <SKTLNumberDetail
          key={`add`}
          closeDetail={closeDetail}
          fetchList={() => getSKTLNumberList()}
        />
      )
    } else {
      setViewComponent(null)
    }
  }, [location.search])

  useEffect(() => {
    getSKTLNumberList()
  }, [filter])

  function getSKTLNumberList() {
    setLoading(true)
    sktl
      .getSKTLNumberList({ ...filter, sort: "virtualNumber,ASC" })
      .then((res) => {
        const meta = res?.data?.meta
        setVirtualMappings(res?.data?.data)
        const pageMeta = {
          total: meta.totalCount,
          current: meta.page.pageNumber + 1,
          pageSize: meta.page.pageSize,
          totalPage: meta.totalPage,
        }
        setPagination(pageMeta)
      })
      .catch((e) =>
        globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        )
      )
      .finally(() => setLoading(false))
  }

  function openViewSider(type, id) {
    if (type === "detail") {
      delete query.add
      pushQuery({ ...query, id: id }, navigate)
    } else if (type === "add") {
      delete query.id
      pushQuery({ ...query, add: true }, navigate)
    }
  }

  function onSearch(values) {
    const cleanObj = objectClean(values)
    if (cleanObj?.keywordType && cleanObj?.keywordType !== "")
      cleanObj[cleanObj?.keywordType] = cleanObj?.keywordType.includes("Number")
        ? cleanObj?.keyword?.replace(/-/g, "") || ""
        : cleanObj.keyword
    if (cleanObj.agentId === "all") delete cleanObj.agentId
    delete cleanObj.dateRange
    delete cleanObj.keyword
    delete cleanObj.keywordType
    setFilter({ ...removeEmpty(cleanObj), page: 0, size: 10 })
  }

  function closeDetail() {
    delete query.id
    delete query.add
    pushQuery(query, navigate)
  }

  function downloadExcel() {
    if (userStore?.super && (!filter.agentId || filter.agentId === "all")) {
      return globalStore.warningNoti("사업장을 선택해주세요.")
    }
    setExcelDownloading(true)
    sktl
      .getSKTLNumberExcel(filter)
      .then((res) => {
        const resData = res.data
        const today = dayjs().format("YYYYMMDDHHmm")
        const fileTitle = "SKTL번호_리스트_"
        fileDownload(resData, fileTitle + today + ".xlsx")
      })
      .catch((e) => globalStore.errorNoti("다운로드 실패"))
      .finally(() => setExcelDownloading(false))
  }

  return (
    <>
      <PageContainer viewSiderComponent={viewSiderComponent}>
        <PageHeader
          title="SKTL번호 관리"
          subTitle="Sktelink 번호 맵핑 관리"
          rightActions={
            <>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => downloadExcel()}
                loading={excelDownloading}
              >
                다운로드
              </Button>
            </>
          }
        />
        <SKTLNumberSearch onSearch={onSearch} />
        <ContentContainer wrapper>
          <SKTLNumberTable
            openViewSider={openViewSider}
            virtualMappings={virtualMappings}
            pagination={pagination}
            filter={filter}
            setFilter={setFilter}
            checkList={checkList}
            setCheckList={setCheckList}
            loading={loading}
          />
        </ContentContainer>
      </PageContainer>
    </>
  )
}
