/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import _isNil from "lodash/isNil";

import { Breakpoints, MediaQuery } from "@utils/style";
import { useRef } from "react";
import { useMobxStores } from "@app/stores/stores";
import { useMediaQuery } from "react-responsive";
import _ from "lodash";
import { useCallback } from "react";

PageContainer.propTypes = {
  className: PropTypes.string,
  viewSiderComponent: PropTypes.object,
};
PageContainer.defaultProps = {
  className: "",
  viewSiderComponent: null,
};

function PageContainer(props) {
  const { className, viewSiderComponent } = props;
  const { globalStore } = useMobxStores();
  const wrapperRef = useRef();
  const backTopRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: Breakpoints[1] });

  let wrapperProps = {
    className: "wrapper",
  };

  if (viewSiderComponent != null)
    wrapperProps.className += " " + "viewSider-active";
  if (!_isNil(className)) wrapperProps.className += " " + className;

  function scrollTop() {
    wrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }

  const pageScroll = useCallback(
    _.debounce(
      (e) => {
        const btnStyle = backTopRef?.current?.style;
        if (e.target.scrollTop === 0 && btnStyle) {
          btnStyle.background = "#F5F5F5";
          btnStyle.color = "rgba(0, 0, 0, 0.25)";
        } else if (btnStyle) {
          btnStyle.background = "#007def";
          btnStyle.color = "#FFF";
        }
      },
      30,
      []
    )
  );

  return (
    <div css={styles.container}>
      <div {...wrapperProps} ref={wrapperRef} onScroll={(e) => pageScroll(e)}>
        {props.children}
      </div>
      {viewSiderComponent && viewSiderComponent}
      {isMobile && globalStore.viewTopButton && !viewSiderComponent && (
        <div css={styles.backTop} onClick={scrollTop} ref={backTopRef}>
          TOP
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    height: `calc(100vh - 48px)`,
    overflowY: "hiddne",
    position: "relative",
    "& > .wrapper": {
      width: "100%",
      overflowY: "auto",
      "&.viewSider-active": {
        width: "60%",
        [MediaQuery[2]]: {
          width: "100%",
        },
      },
    },
  },
  backTop: {
    position: "absolute",
    bottom: 10,
    right: 10,
    height: "60px",
    width: "60px",
    zIndex: "13",
    borderRadius: "100px",
    border: "1px solid #D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    // background: "#F5F5F5",
    // color: "rgba(0, 0, 0, 0.25)",
    background: "#007def",
    color: "#FFF",
  },
};

export default observer(PageContainer);
