import { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { observer } from "mobx-react"
import _cloneDeep from "lodash/cloneDeep"

import { objectClean } from "@utils/router"
import { getQuery, pushQuery } from "@utils/router"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"

import UserStatusSearch from "@containers/service/user/list/UserStatusSearch"
import UserStatusTable from "@containers/service/user/list/UserStatusTable"
import UserStatusDetail from "@pages/service/UserStatusDetail"

export default observer(function UserStatusPage() {
  const tableRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  const query = getQuery(location)
  const [viewSiderComponent, setViewComponent] = useState(null)
  const [lastParams, setLastParams] = useState({})

  function search(params) {
    const filter = _cloneDeep(params)
    if (filter.agentId === "all") delete filter.agentId
    if (filter.existAgent === "all") delete filter.existAgent
    if (filter.enabled === "all") delete filter.enabled
    if (filter.expired === "all") delete filter.expired
    if (filter.keyword) {
      filter[filter.keywordType] = filter.keyword
    }
    delete filter.dateRange
    delete filter.keywordType
    delete filter.keyword

    objectClean(filter)
    tableRef?.current?.fetchList(filter)
  }

  function openViewSider(type, id) {
    if (type === "detail") {
      delete query.add
      pushQuery({ ...query, id: id }, navigate)
    } else if (type === "add") {
      delete query.id
      pushQuery({ ...query, add: true }, navigate)
    }
  }

  useEffect(() => {
    if (query.id) {
      setViewComponent(
        <UserStatusDetail
          key={query.id}
          id={query.id}
          fetchList={() => {
            search(lastParams)
          }}
        />
      )
    } else if (query.add) {
      setViewComponent(
        <UserStatusDetail
          key={`add`}
          id={null}
          fetchList={() => {
            search(lastParams)
          }}
        />
      )
    } else {
      setViewComponent(null)
    }
  }, [location.search])

  return (
    <>
      <PageContainer viewSiderComponent={viewSiderComponent}>
        <PageHeader
          title="사용자 현황"
          subTitle="사용자 및 가입신청을 관리합니다."
        />
        <UserStatusSearch search={search} openViewSider={openViewSider} />
        <ContentContainer wrapper>
          <UserStatusTable
            ref={tableRef}
            lastParams={lastParams}
            setLastParams={setLastParams}
            openViewSider={openViewSider}
          />
        </ContentContainer>
      </PageContainer>
    </>
  )
})
