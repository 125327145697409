import { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import dayjs from "dayjs"
import _cloneDeep from "lodash/cloneDeep"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"
import DownLoadButton from "@components/button/DownLoadButton"

import AgentStatusSearch from "@containers/service/agent/list/AgentStatusSearch"
import AgentStatusTable from "@containers/service/agent/list/AgentStatusTable"
import AgentStatusDetail from "@pages/service/AgentStatusDetail"

import { fileDownload } from "@utils/file"
import { getQuery, pushQuery, objectClean } from "@utils/router"
import { useMobxStores } from "@stores/stores"

import agentApi from "@api/agent"

export default function AgentStatusPage() {
  const tableRef = useRef()
  const { userStore, globalStore } = useMobxStores()
  const navigate = useNavigate()
  const location = useLocation()
  const query = getQuery(location)
  const [viewSiderComponent, setViewComponent] = useState(null)
  const [lastParams, setLastParams] = useState({})
  const [loading, setLoading] = useState(false)

  function search(params) {
    const filter = _cloneDeep(params)

    if (filter.serviceYN === "all") delete filter.serviceYN
    if (filter.keyword) {
      filter[filter.keywordType] = filter.keyword
    }
    delete filter.dateRange
    delete filter.keywordType
    delete filter.keyword

    objectClean(filter)
    tableRef?.current?.fetchList(filter)
  }

  function openViewSider(type, id) {
    if (type === "detail") {
      delete query.add
      pushQuery({ ...query, id: id }, navigate)
    } else if (type === "add") {
      delete query.id
      pushQuery({ ...query, add: true }, navigate)
    }
  }

  async function onDownload() {
    // 엑셀 다운로드
    setLoading(true)
    try {
      let filter = _cloneDeep(lastParams)
      delete filter.page
      delete filter.size

      const res = await agentApi.getAgentsExcel(filter)
      const resData = res.data
      const today = dayjs().format("YYYYMMDDHHmm")
      const fileTitle = "사업장_리스트_"
      fileDownload(resData, fileTitle + today + ".xlsx")
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  useEffect(() => {
    if (query.id) {
      setViewComponent(
        <AgentStatusDetail
          key={query.id}
          id={query.id}
          setViewComponent={setViewComponent}
          fetchList={() => {
            search(lastParams)
          }}
        />
      )
    } else if (query.add) {
      setViewComponent(
        <AgentStatusDetail
          key={`add`}
          id={null}
          setViewComponent={setViewComponent}
          fetchList={() => {
            search(lastParams)
          }}
        />
      )
    } else {
      setViewComponent(null)
    }
  }, [location.search])

  return (
    <>
      <PageContainer viewSiderComponent={viewSiderComponent}>
        <PageHeader
          title="사업장 현황"
          subTitle="사업장별 서비스를 설정합니다."
          rightActions={
            <>
              <DownLoadButton
                onClick={onDownload}
                loading={loading}
                text="다운로드"
              />
            </>
          }
        />
        <AgentStatusSearch search={search} openViewSider={openViewSider} />
        <ContentContainer wrapper>
          <AgentStatusTable
            ref={tableRef}
            lastParams={lastParams}
            setLastParams={setLastParams}
            openViewSider={openViewSider}
          />
        </ContentContainer>
      </PageContainer>
    </>
  )
}
