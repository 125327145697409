/** @jsxImportSource @emotion/react */
import { Outlet, useLocation } from "react-router-dom"
import Header from "@components/noauth/Header"
import Footer from "@components/noauth/Footer"
import { observer } from "mobx-react"

export default observer(function NoAuthLayout() {
  const pathName = useLocation()?.pathname
  const isLoginPage = pathName?.indexOf("login") > -1

  const styles = {
    container: {
      margin: "0 16px",
    },
    content: {
      display: "flex",
      justifyContent: "center",
      width: "90vw",
      height: isLoginPage ? "90.2vh" : "80vh",
      margin: "auto",
    },
  }

  return (
    <div css={styles.container}>
      {!isLoginPage && <Header />}
      <div css={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
})
