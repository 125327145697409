import { axiosInstance as axios } from "@app/axios"
import qs from "qs"

export default {
  // 콜로그 조회 (페이징,일반유저)
  getCallLogsByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/call-logs/v1/my?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 콜로그 조회 (페이징,슈퍼유저)
  getCallLogsBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/call-logs/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 콜로그 조회 엑셀 다운로드 (일반유저)
  getCallLogsExcelByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/call-logs/v1/my/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 콜로그 조회 엑셀 다운로드 (슈퍼유저)
  getCallLogsExcelBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/call-logs/v1/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // SKTL 콜로그 조회 (페이징,일반유저)
  getSktlCallLogsByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/ext-call-logs/v1/my?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // SKTL 콜로그 조회 (페이징,슈퍼유저)
  getSktlCallLogsBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/ext-call-logs/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // SKTL 콜로그 조회 엑셀 다운로드 (일반유저)
  getSktlCallLogsExcelByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/ext-call-logs/v1/my/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // SKTL 콜로그 조회 엑셀 다운로드 (슈퍼유저)
  getSktlCallLogsExcelBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/ext-call-logs/v1/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 인증로그 조회 (페이징,슈퍼유저)
  getAuthLogsBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/auth-logs/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 인증로그 조회 엑셀 다운로드 (슈퍼유저)
  getAuthLogsExcelBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/auth-logs/v1/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 비즈알림 조회 (페이징,슈퍼유저)
  getAutoLogsBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/auto-call-logs/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 비즈알림 조회 엑셀 다운로드 (슈퍼유저)
  getAutoLogsExcelBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/auto-call-logs/v1/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
}
