/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types"

import { Descriptions as AntdDescriptions, Spin } from "antd"

ItemView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  noPadding: PropTypes.bool,
}
ItemView.defaultProps = {
  npPadding: false,
}

function ItemView(props) {
  const { children, noPadding } = props

  let containerStyle = [styles.viewContainer]
  if (noPadding) containerStyle.push({ padding: 0 })

  return <div css={containerStyle}>{children}</div>
}

function Descriptions(props) {
  const { children, column } = props

  return (
    <AntdDescriptions
      bordered
      size="small"
      column={column || { xxl: 2, xl: 1, lg: 1, md: 1, sm: 2, xs: 1 }}
      style={styles.descriptions}
    >
      {children}
    </AntdDescriptions>
  )
}

function DescriptionsVertical(props) {
  const { children, options } = props

  return (
    <AntdDescriptions {...options} css={styles.descriptionsVertical}>
      {children}
    </AntdDescriptions>
  )
}

function Loading() {
  return (
    <div css={styles.loadingContainer}>
      <Spin />
    </div>
  )
}

ItemView.Descriptions = Descriptions
ItemView.DescriptionsVertical = DescriptionsVertical
ItemView.Loading = Loading

export default ItemView

const styles = {
  viewContainer: {
    // padding: '0 24px',
    position: "relative",
  },
  descriptions: {
    marginBottom: 16,
  },
  descriptionsVertical: {
    "& .ant-descriptions-view": {
      border: "0 !important",
    },
    "& .ant-descriptions-item-label": {
      backgroundColor: "#FFF !important",
      borderTop: "1px solid #FFF",
      borderBottom: "1px solid #FFF",
      paddingBottom: "8px !important",
      fontWeight: 700,
    },
    "& .ant-descriptions-item-content": {
      borderBottom: "1px solid #f0f0f0",
      paddingTop: "0 !important",
    },
  },
  loadingContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
}
