/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import agent from "@app/axios/agent";
import manage from "@app/axios/manage";
import DetailContainer from "@app/components/container/DetailContainer";
import PhoneNumberInput from "@app/components/input/PhoneNumberInput";
import { useMobxStores } from "@app/stores/stores";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import AgentSelect from "@app/components/select/AgentSelect";
import { observer } from "mobx-react-lite";

export default observer(function VirtualMappingRangeDetail(props) {
  const { globalStore, userStore } = useMobxStores();
  const { id, closeDetail, fetchList } = props;
  const [form] = Form.useForm();
  //사용여부
  const switchOptions = [
    { label: "사용", value: "Y" },
    { label: "미사용", value: "N" },
  ];
  //녹취여부
  const recordOptions = [
    { label: "미설정", value: "NA" },
    { label: "녹취", value: "Y" },
    { label: "미녹취", value: "N" },
  ];
  const [expiredDate, CheckExpiredDate] = useState(false);
  const [coloringOptions, setColoringOptions] = useState([]);
  const [alarmOptions, setAlarmOptions] = useState([]);
  const [viewSiderProps, setViewSiderProps] = useState({
    title: "대량 가상번호 관리",
    okTitle: "등록",
    bottomActionBar: true,
    onOk: () => {
      form.submit();
    },
    onClose: () => closeDetail(),
  });
  const [agentId, setAgentId] = useState();

  useEffect(() => {
    if (!agentId) return;
    getInformList();
    getColoringList();
  }, [agentId]);

  function onFinish() {
    const values = form.getFieldsValue();
    let params = { ...values };
    console.log("params", params);
    let checkEmptyValue = false;
    let emptyValueName = "";
    for (var propName in params) {
      if (/memo|memo2|expireDateTime/.test(propName)) continue;
      if (
        params[propName] === null ||
        params[propName] === undefined ||
        params[propName] === ""
      ) {
        checkEmptyValue = true;
        emptyValueName = propName;
      }
    }
    const returnText = {
      agentId: "사업장",
      colorRingId: "컬러링",
      informRingId: "알림멘트",
      serviceType: "사용 여부",
      recordType: "녹취 여부",
      fromVirtualNumber: "시작 050번호",
      toVirtualNumber: "끝 050번호",
    };
    if (checkEmptyValue)
      return globalStore.errorNoti(
        `${returnText[emptyValueName]}은 필수 값 입니다.`
      );

    params.expireDateTime = dayjs(values?.expireDateTime).format(
      "YYYY-MM-DD hh:mm:ss"
    );
    params.fromVirtualNumber = values.fromVirtualNumber.replace(/-/gi, "");
    params.toVirtualNumber = values.toVirtualNumber.replace(/-/gi, "");
    manage
      .putVirtualNumberRange(params)
      .then((res) => {
        globalStore.successNoti("수정 성공");
        closeDetail();
        fetchList();
      })
      .catch((e) =>
        globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        )
      );
  }

  // //컬러링 조회
  function getColoringList() {
    manage.getColoringListByAgent(agentId).then((res) => {
      const resData = res.data.data;
      setColoringOptions(
        resData.map((item) => ({
          label: `${item?.ringId}(${item?.description})`,
          value: item?.ringId,
        }))
      );
    });
  }
  //알림멘트조회
  function getInformList() {
    manage.getInforingsByAgent(agentId).then((res) => {
      const resData = res.data.data;
      setAlarmOptions(
        resData.map((item) => ({
          label: `${item?.ringId}(${item?.description})`,
          value: item?.ringId,
        }))
      );
    });
  }

  return (
    <DetailContainer viewSiderProps={viewSiderProps}>
      <div css={styles.container}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ marginBottom: "62px" }}
        >
          <Row gutter={[16, { xs: 16, sm: 24 }]}>
            {userStore?.super && (
              <Col md={12} xs={24}>
                <Form.Item label="사업장" name="agentId" required>
                  <AgentSelect
                    style={{
                      width: "220px",
                    }}
                    onChange={(e) => setAgentId(e)}
                  />
                </Form.Item>
              </Col>
            )}
            <Col lg={12} xs={24}>
              <Form.Item name="serviceType" label="사용 여부" required>
                <Radio.Group
                  options={switchOptions}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item name="fromVirtualNumber" label="시작 050번호" required>
                <PhoneNumberInput
                  style={{ width: "280px" }}
                  isVirtualNum={true}
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="toVirtualNumber" label="종료 050번호" required>
                <PhoneNumberInput
                  style={{ width: "280px" }}
                  isVirtualNum={true}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="컬러링" name="colorRingId" required>
                <Select options={coloringOptions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="알림 멘트" name="informRingId" required>
                <Select options={alarmOptions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="메모" name="memo">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="메모2" name="memo2">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <span className="custom_label">
                <span>*</span> 녹취 여부
              </span>
              <Form.Item required name="recordType" noStyle>
                <Radio.Group
                  options={recordOptions}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
            {/* <Col md={12} xs={24}>
              <span className="custom_label">
                <span>*</span> 문자 여부
              </span>
              <Form.Item required noStyle>
                <Radio.Group
                  options={recordOptions}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <span className="custom_label">
                <span>*</span>{" "}
                <Checkbox
                  checked={expiredDate}
                  onChange={(e) => {
                    CheckExpiredDate(e.target.checked);
                    if (e.target.checked)
                      form.setFieldsValue({
                        expireDateTime: dayjs().add(60, "minute"),
                      });
                    else form.setFieldsValue({ expireDateTime: null });
                  }}
                />{" "}
                자동 해지 설정
              </span>
              <Form.Item noStyle name="expireDateTime" required>
                <DatePicker
                  disabled={!expiredDate}
                  format={"YYYY-MM-DD HH:mm:ss"}
                  showTime={{ defaultValue: dayjs("YYYY-MM-DD HH:mm:ss") }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </DetailContainer>
  );
});

const styles = {
  container: {
    "& .ant-form-item": {
      marginBottom: 0,
    },
    "& .ant-divider": {
      margin: "6px 0 12px",
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 15,
    },
    "& .sub-title": {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& .ant-descriptions-row": {
      "& .ant-descriptions-item-label": {
        alignItems: "center",
      },
      "& input": {
        maxWidth: 90,
        marginRight: 7,
      },
    },
    "& .custom_label": {
      marginRight: "11px",
      "& span": { color: "red !important", marginRight: "3px" },
    },
  },
  noOperContainer: {
    "& .ant-space-horizontal": {
      marginBottom: "28px",
    },
  },
};
