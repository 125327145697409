import axios from "axios"
import jwt from "jwt-decode"
import { useMobxStores } from "@stores/stores"
import { useEffect } from "react"

import _cloneDeep from "lodash/cloneDeep"

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,

  // 기본 헤더
  // 특정 mime 요청시는 해당 요청에서 order
  headers: { "Content-Type": "application/json" },

  // Request Tmieout : milliseconds
  // 요청시간이 타임아웃보다 길어지면 요청 중지
  timeout: 1000 * 15,
})

const noAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,

  // 기본 헤더
  // 특정 mime 요청시는 해당 요청에서 order
  headers: { "Content-Type": "application/json" },

  // Request Tmieout : milliseconds
  // 요청시간이 타임아웃보다 길어지면 요청 중지
  timeout: 1000 * 15,
})

// Reuqest interceptor
// 인증 토큰 추가
// 로컬 스토리지에 있을 경우만
export const AxiosInterceptorsSetup = (navigate) => {
  const { userStore, globalStore } = useMobxStores()
  useEffect(() => {
    instance.interceptors.request.use(
      function (config) {
        let token = localStorage.getItem("access-token")
        if (token) {
          config.headers["access_token"] = token

          // 계정 만료 시 해당 페이지로 이동
          const tokenInfo = jwt(token)
          if (tokenInfo?.expiredPassword === "true") {
            navigate?.("/expired")
          }
        }
        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    instance.interceptors.response.use(
      async (response) => {
        // api 호출마다 토큰 재발급
        if (localStorage.getItem("access-token")) {
          await userStore?.refreshToken()
        }
        return response
      },
      async (error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.meta?.systemMessage === "Expired token"
        ) {
          globalStore.errorNoti(
            "세션이 만료되어 로그인 페이지로 이동합니다.",
            "세션 만료"
          )

          await userStore?.logout()
        } else {
          return Promise.reject(error)
        }
      }
    )
  }, [])
}

noAuthInstance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export { instance as axiosInstance, noAuthInstance as axiosNoAuthInstance }
