/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react";
import { PageHeader as AntdPageHeader } from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import Breadcrumb from "@components/common/Breadcrumb";

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  breadcrumb: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
};
PageHeader.defaultProps = {
  title: "제목",
  subTitle: "",
  breadcrumb: true,
};

function PageHeader(props) {
  const { title, subTitle, breadcrumb, onClose, onBack, rightActions } = props;
  let additionalProps = {};
  if (onClose !== undefined) {
    additionalProps.onBack = onClose;
    additionalProps.backIcon = <CloseOutlined />;
  } else if (onBack !== undefined) {
    additionalProps.onBack = onBack;
    additionalProps.backIcon = <ArrowLeftOutlined />;
  }
  if (breadcrumb) additionalProps.breadcrumb = <Breadcrumb />;

  return (
    <div css={styles.container}>
      <AntdPageHeader
        ghost={false}
        title={title}
        subTitle={subTitle}
        {...additionalProps}
        extra={rightActions}
      >
        {props.children}
      </AntdPageHeader>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: "#FFF",
    paddingTop: 5,
    transition: "all 0.1s ease-in-out",
    position: "relative",
  },
};

export default observer(PageHeader);
