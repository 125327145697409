/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { Button, Modal, Popconfirm, Table, Input } from "antd";
import { PlusCircleFilled, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import PhoneNumberInput from "@app/components/input/PhoneNumberInput";
import { useEffect } from "react";
import blackWhite from "@app/axios/blackWhite";
import { useMobxStores } from "@app/stores/stores";
import SaveButton from "@app/components/button/SaveButton";
import { useRef } from "react";
import { useCallback } from "react";
import _ from "lodash";
import { MediaQuery, useIsMobile } from "@app/utils/style";

export default function BlackNumberInputModal(props) {
  const {
    agentId,
    inputModal, //모달 on/off 및 모달 타입(전체 or 050번호별 or 050번호별 추가) all, number, add-number
    setInputModal,
    getAllBlackWhiteData, //상세조회
    fetchList, //리스트 조회
    virtualNumber, // 050번호별 추가로 들어올시, 추가의 경우에는 직접 입력
    listType, //블랙 or 화이트 체크
    addNumberList,
    setAddNumberList,
  } = props;
  const { globalStore } = useMobxStores();
  const [loading, setLoading] = useState(false);
  const virtualNumberRef = useRef();

  useEffect(() => {
    if (inputModal === "number" && virtualNumber && agentId) {
      blackWhite
        .getBlackWhiteListByType(agentId, virtualNumber, listType)
        .then((res) => {
          const resData = res?.data?.data;
          setAddNumberList(
            resData.map((data, index) => ({
              key: index,
              callingNumber: data?.calling,
              memo: data?.memo,
            }))
          );
        });
    }
    return () => {
      setAddNumberList([]);
      setInputModal(null);
    };
  }, []);

  function addRow() {
    const clone = [...addNumberList];
    if (clone?.length > 100)
      return globalStore.errorNoti("번호 등록은 100개까지 가능합니다.");
    const newRow = {
      key: Date.now(),
      callingNumber: "",
    };
    if (inputModal === "number" || inputModal === "add-number")
      newRow.memo = "";
    setAddNumberList([newRow, ...clone]);
  }

  function deleteRow(key) {
    const clone = [...addNumberList];
    setAddNumberList(clone.filter((item) => item.key !== key));
  }

  const setDatasource = useCallback(
    _.debounce((value, index, keyName) => {
      const clone = [...addNumberList];
      if (keyName === "callingNumber")
        clone[index][keyName] = value.replace(/-/gi, "");
      else clone[index][keyName] = value;
      setAddNumberList(clone);
    }, 500),
    [addNumberList]
  );

  function postNumberList() {
    let error = false;
    if (inputModal === "number" || inputModal === "add-number") {
      const vNumber =
        inputModal === "add-number"
          ? virtualNumberRef.current.input?.value.replace(/-/gi, "")
          : virtualNumber;
      if (!vNumber || vNumber === "")
        return globalStore.errorNoti("가상번호를 입력 해주세요");
      console.log("addNumberList", addNumberList);
      //050 번호별 등록시
      addNumberList.forEach((num) => {
        if (!num?.callingNumber || num?.callingNumber === "" || !num?.memo)
          error = true;
      });
      if (error) return globalStore.errorNoti("번호를 빠짐없이 입력해주세요.");
      if (!listType)
        return globalStore.errorNoti(
          "상세페이지에서 블랙/화이트 타입을 설정해주세요."
        );

      const reqBody = {
        // agentId: agentId,
        callingNumbers: addNumberList.map((number) => ({
          callingNumber: number?.callingNumber,
          memo: number?.memo,
        })),
        type: listType,
      };
      setLoading(true);
      blackWhite
        .addBlackWhiteListByNum(vNumber, reqBody)
        .then((res) => {
          globalStore.successNoti("발신번호를 등록 하였습니다.");
          getAllBlackWhiteData();
          fetchList();
          setInputModal(null);
        })
        .catch((e) => {
          globalStore.errorNoti(
            e?.response?.data?.meta?.userMessage || e?.message || e
          );
        })
        .finally((e) => setLoading(false));
    } else if (inputModal === "all") {
      console.log("addNumberList", addNumberList);
      // 전체번호 등록시
      addNumberList.forEach((num) => {
        if (!num?.callingNumber || num?.callingNumber === "") error = true;
      });
      if (error) return globalStore.errorNoti("번호를 빠짐없이 입력해주세요.");

      const reqBody = {
        agentId: Number(agentId),
        callingNumbers: addNumberList.map((number) => number?.callingNumber),
      };
      setLoading(true);
      blackWhite
        .addAllBlackWhiteList(reqBody)
        .then((res) => {
          globalStore.successNoti("발신번호를 등록 하였습니다.");
          getAllBlackWhiteData();
          fetchList();
          setInputModal(null);
        })
        .catch((e) => {
          globalStore.errorNoti(
            e?.response?.data?.meta?.userMessage || e?.message || e
          );
        })
        .finally((e) => setLoading(false));
    }
  }
  const columns = [
    {
      key: "callingNumber",
      dataIndex: "callingNumber",
      title: "발신 번호",
      align: "center",
      width: inputModal === "number" ? "46%" : "93%",
      render: (text, record, index) => (
        <PhoneNumberInput
          onChange={(e) =>
            setDatasource(e.target.value, index, "callingNumber")
          }
          value={text}
        />
      ),
    },
    {
      key: "memo",
      dataIndex: "memo",
      title: "메모",
      align: "center",
      width: inputModal === "number" ? "46%" : "93%",
      render: (text, record, index) => (
        <Input
          onChange={(e) => setDatasource(e.target.value, index, "memo")}
          // value={text}
          defaultValue={text}
        />
      ),
      type: ["number", "add-number"], //번호별 리스트 설정에서만 출력됨
    },
    {
      key: "delete",
      align: "center",
      width: "7%",
      dataIndex: "isBtnDisable",
      title: () => {
        return (
          <PlusCircleFilled
            style={{ color: "#007def" }}
            onClick={() => addRow()}
          />
        );
      },
      render: (text, record, index) => (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Popconfirm
              title={"정말 삭제 하시겠습니까?"}
              cancelText={"취소"}
              okText={"확인"}
              onConfirm={() => deleteRow(record?.key)}
              onCancel={() => {}}
              style={{ width: "300px !important" }}
            >
              <Button type="text" icon={<CloseOutlined />} />
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];

  return (
    <Modal
      title="발신 번호 목록"
      open={inputModal}
      onCancel={() => {
        setAddNumberList([]);
        setInputModal(null);
      }}
      width={680}
      footer={null}
      loading={loading}
      css={styles.modalContainer}
    >
      {(inputModal === "number" || inputModal === "add-number") && (
        <b style={{ color: "red" }}>
          {listType == "WHITE" ? "화이트 리스트 설정" : "블랙 리스트 설정"}
        </b>
      )}
      {inputModal === "add-number" && (
        <div>
          * 가상번호 입력 :{" "}
          <PhoneNumberInput
            isVirtualNum
            style={{ width: "350px" }}
            size="middle"
            inputRef={virtualNumberRef}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <span style={{ color: "#FF0000" }}>*</span> 발신 번호
          <span
            style={{
              color: "#FF0000",
              fontSize: "10px",
              marginLeft: "5px",
            }}
          >
            100개 설정
          </span>
        </div>
        <SaveButton text="등록" noIcon onClick={postNumberList} size="middle" />
      </div>
      <Table
        columns={columns.filter((column) => {
          if (column?.type) return column.type.indexOf(inputModal) > -1;
          else return column;
        })}
        dataSource={addNumberList}
        tableLayout="fixed"
        size="small"
        pagination={false}
        scroll={{ x: 620, y: 500 }}
        rowKey={(e) => e.key}
      />
    </Modal>
  );
}

const styles = {
  modalContainer: {
    [MediaQuery[0]]: {
      "& .ant-modal-header": {
        padding: "10px",
        "& .ant-modal-title": {
          fontSize: "13px",
        },
      },
      "& .ant-modal-body": {
        padding: "10px",
        fontSize: "13px",
      },
    },
  },
};
