/** @jsxImportSource @emotion/react */
import { useState, forwardRef, useImperativeHandle, useRef } from "react"
import { useLocation } from "react-router-dom"
import { ConfigProvider, Table, Empty, Modal } from "antd"
import dayjs from "dayjs"

import {
  commaOfNumber,
  phoneFormatter,
  virtualNumberFormatter,
} from "@utils/format"
import { checkAccessKey } from "@utils/router"
import { useMobxStores } from "@stores/stores"
import { useIsMobile } from "@utils/style"

import CalllogTableMobile from "./CalllogTableMobile"

import logApi from "@api/log"

export default forwardRef(function CalllogTable(props, ref) {
  const { lastParams } = props
  const { userStore, globalStore } = useMobxStores()
  const audioRef = useRef()
  const location = useLocation()
  const [modal, setModal] = useState({
    open: false,
    audio: null,
  })
  const [logList, setLogList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    // 페이징처리
    total: 0,
    current: 1,
    pageSize: 10,
  })

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchLogList({
        ...params,
        page: 0,
        size: pagination.pageSize,
      })
    },
  }))

  // 콜로그 리스트
  async function fetchLogList(params) {
    setLoading(true)
    try {
      const logProm = userStore?.super
        ? await logApi.getCallLogsBySuper(params)
        : await logApi.getCallLogsByUser(params)
      setLogList(
        logProm?.data?.data?.map((e) => {
          return { ...e, expand: false }
        })
      )
      const meta = logProm?.data?.meta
      const pageMeta = {
        total: meta.totalCount,
        current: meta.page.pageNumber + 1,
        pageSize: meta.page.pageSize,
        totalPage: meta.totalPage,
      }
      if (meta.page.pageNumber === 0) globalStore.setViewTopButton(false)
      setPagination(pageMeta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  // 모바일 화면 확대,축소 변경
  function setExpend(viewData, i) {
    setLogList(
      viewData.map((e, index) => {
        if (index === i) e.expand = !e.expand
        return e
      })
    )
  }

  // 녹취 모달 열기
  function openModal(record) {
    setModal({
      open: true,
      record: record,
    })
  }

  const columns = [
    {
      key: "agentName",
      dataIndex: ["agent", "name"],
      title: "사업장명",
      align: "center",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "memo1",
      dataIndex: "memo1",
      title: "상점명",
      align: "center",
      width: 200,
      isView: checkAccessKey("STORE", userStore, location),
      render(text) {
        return text || "-"
      },
    },
    {
      key: "callingNumber",
      dataIndex: "callingNumber",
      title: "발신번호",
      align: "center",
      render(text) {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "virtualNumber",
      dataIndex: "virtualNumber",
      title: "가상번호",
      align: "center",
      render(text) {
        return text ? virtualNumberFormatter(text) : "-"
      },
    },
    {
      key: "realNumber",
      dataIndex: "realNumber",
      title: "착신번호",
      align: "center",
      render(text) {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "incomeDateTime",
      dataIndex: "incomeDateTime",
      title: "호 시작 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "outAnswerDateTime",
      dataIndex: "outAnswerDateTime",
      title: "호 응답 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "switchDateTime",
      dataIndex: "switchDateTime",
      title: "호 연결 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "releaseDateTime",
      dataIndex: "releaseDateTime",
      title: "호 종료 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "duration",
      dataIndex: "duration",
      title: "통화시간(초)",
      align: "center",
      render(text) {
        return commaOfNumber(text || 0)
      },
    },
    {
      key: "memo1",
      dataIndex: "memo1",
      title: "메모",
      align: "center",
      width: 200,
    },
    {
      key: "memo2",
      dataIndex: "memo2",
      title: "메모2",
      align: "center",
      width: 200,
      isView: checkAccessKey("MEMO2", userStore, location),
    },
    {
      key: "cause",
      dataIndex: "cause",
      title: "CAUSE",
      align: "center",
    },
    {
      key: "callRecord",
      dataIndex: "callRecord",
      title: "녹취",
      align: "center",
      onCell: (record) => ({
        onClick: () => {
          if (record?.callRecord && record?.duration) openModal(record)
        },
      }),
      render(_, record) {
        return record?.callRecord && record?.duration ? (
          <a className="audio" href={"/calllog/list" + "#" + record?.duration}>
            듣기
          </a>
        ) : (
          "-"
        )
      },
    },
  ]

  return (
    <div css={styles.container}>
      <ConfigProvider
        renderEmpty={() => <Empty description="검색결과가 없습니다." />}
      >
        <p style={{ fontWeight: "bold" }}>
          콜로그 수 : {commaOfNumber(pagination?.total)}건
        </p>
        {useIsMobile() ? (
          <CalllogTableMobile
            dataSource={logList}
            openModal={openModal}
            loading={loading}
            setExpend={setExpend}
            pagination={pagination}
            onChange={(e) => {
              const filter = {
                ...lastParams,
                page: e.current - 1,
                size: e.pageSize,
              }
              fetchLogList(filter)
            }}
          />
        ) : (
          <Table
            rowKey={(record) => record.sequence}
            columns={columns.filter((column) => {
              return column.isView || column.isView === undefined
            })}
            dataSource={logList}
            pagination={pagination}
            onChange={(e) => {
              const filter = {
                ...lastParams,
                page: e.current - 1,
                size: e.pageSize,
              }
              fetchLogList(filter)
            }}
            scroll={{ x: 2100 }}
            loading={loading}
          />
        )}

        <Modal
          title="녹취 듣기"
          open={modal?.open}
          footer={false}
          width={430}
          onCancel={() => {
            audioRef.current.pause()
            setModal({
              open: false,
              record: null,
            })
          }}
        >
          <div css={styles.modalBody}>
            <div>
              <span>
                가상번호:{" "}
                {modal?.record?.virtualNumber
                  ? phoneFormatter(modal?.record?.virtualNumber)
                  : "-"}
              </span>
            </div>
            <div>
              <span>
                발신번호:{" "}
                {modal?.record?.callingNumber
                  ? phoneFormatter(modal?.record?.callingNumber)
                  : "-"}
              </span>
            </div>
            <div>
              <span>
                착신번호:{" "}
                {modal?.record?.realNumber
                  ? phoneFormatter(modal?.record?.realNumber)
                  : "-"}
              </span>
            </div>
            <audio
              src={
                process.env.REACT_APP_RECORD_URL +
                "" +
                modal?.record?.callRecord?.filePath
              }
              ref={audioRef}
              controls
              controlsList="nodownload"
            />
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  )
})

const styles = {
  container: {
    "& .audio": {
      fontSize: 14,
      color: "-webkit-link",
      textDecorationLine: "underline",
      cursor: "pointer",
    },
  },
  modalBody: {
    textAlign: "center",
    "& div": {
      textAlign: "left",
      "& span": {
        fontSize: 14,
        lineHeight: 2,
      },
    },
    "& audio": {
      marginTop: 12,
      width: "100%",
    },
  },
}
