import EmptyLayout from "@layouts/EmptyLayout"
import NoAuthLayout from "@layouts/NoAuthLayout"
import MainLayout from "@layouts/MainLayout"

import LoginPage from "@pages/auth/LoginPage"
import TermsPage from "@pages/auth/TermsPage"
import SignupPage from "@pages/auth/SignupPage"
import FindPage from "@pages/auth/FindPage"
import ExpiredPage from "@pages/auth/ExpiredPage"
import SmsAuthPage from "@pages/auth/SmsAuthPage"

import ManualPage from "@pages/etc/ManualPage"

import SKTLNumberPage from "@pages/management/SKTLNumberPage"
import ServiceStatusPage from "@pages/service/ServiceStatusPage"
import AgentStatusPage from "@pages/service/AgentStatusPage"
import UserStatusPage from "@pages/service/UserStatusPage"
import NoticeStatusPage from "@pages/service/NoticeStatusPage"
import CalllogPage from "@pages/log/CalllogPage"
import SktlCalllogPage from "@pages/log/SktlCalllogPage"
import AuthlogPage from "@pages/log/AuthlogPage"
import AutologPage from "@pages/log/AutologPage"
import ColoringManagePage from "@pages/management/ColoringManagePage"
import AlarmManageListPage from "@pages/management/AlarmManageListPage"
import VirtualMappingManagePage from "@pages/management/VirtualMappingManagePage"
import BlackWhiteListPage from "@pages/management/BlackWhiteListPage"
import RouteStatsPage from "@pages/stats/RouteStatsPage"
import AgentStatsPage from "@pages/stats/AgentStatsPage"
import SktlStatsPage from "@pages/stats/SktlStatsPage"
import AgentStatsPageByUser from "@pages/stats/AgentStatsPageByUser"

const noAuthRoutes = [
  {
    layout: NoAuthLayout,
    contents: [
      {
        key: "login",
        path: "/login",
        name: "로그인",
        component: LoginPage,
      },
      {
        key: "terms",
        path: "/terms",
        name: "이용약관",
        component: TermsPage,
      },
      {
        key: "signup",
        path: "/signup",
        name: "회원가입",
        component: SignupPage,
      },
      {
        key: "find",
        path: "/find",
        name: "아이디/비밀번호 찾기",
        component: FindPage,
      },
      {
        key: "expired",
        path: "/expired",
        name: "계정만료",
        component: ExpiredPage,
      },
      {
        key: "smsauth",
        path: "/smsauth",
        name: "sms인증",
        component: SmsAuthPage,
      },
    ],
  },
]

const authRoutes = [
  {
    layout: MainLayout,
    contents: [
      {
        key: "service-list",
        path: "/service/service",
        name: "서비스 현황",
        component: ServiceStatusPage,
      },
      {
        key: "agent-list",
        path: "/agent/list",
        name: "사업장 현황",
        component: AgentStatusPage,
      },
      {
        key: "user-list",
        path: "/user/list",
        name: "사용자 현황",
        component: UserStatusPage,
      },
      {
        key: "notice-list",
        path: "/notice/list",
        name: "공지사항 현황",
        component: NoticeStatusPage,
      },
      {
        key: "calllog-list",
        path: "/calllog/list",
        name: "콜로그 조회",
        component: CalllogPage,
      },
      {
        key: "sktlcalllog-list",
        path: "/calllog/ext_list",
        name: "SKTL 콜로그 조회",
        component: SktlCalllogPage,
      },
      {
        key: "authlog-list",
        path: "/calllog/auth_log_list",
        name: "인증 로그 조회",
        component: AuthlogPage,
      },
      {
        key: "autolog-list",
        path: "/calllog/auto_log_list",
        name: "비즈알림 로그 조회",
        component: AutologPage,
      },
      {
        key: "manage-color-list",
        path: "/manage/color_list",
        name: "컬러링 관리",
        component: ColoringManagePage,
      },
      {
        key: "manage-inform-list",
        path: "/manage/inform_list",
        name: "알림멘트 관리",
        component: AlarmManageListPage,
      },
      {
        key: "manage-vn-mapp-list",
        path: "/manage/vn_mapp_list",
        name: "가상번호 관리",
        component: VirtualMappingManagePage,
      },
      {
        key: "manage-sktel-list",
        path: "/manage/sktel_list",
        name: "SKTL 번호 관리",
        component: SKTLNumberPage,
      },
      {
        key: "manage-blacklist",
        path: "/manage/blacklist",
        name: "블랙/화이트 관리",
        component: BlackWhiteListPage,
      },
      {
        key: "stats-list",
        path: "/stats/list",
        name: "기간사별 통계",
        component: RouteStatsPage,
      },
      {
        key: "stats-agentlist",
        path: "/stats/agent_list",
        name: "사업자별 통계",
        component: AgentStatsPage,
      },
      {
        key: "stats-sktllist",
        path: "/stats/ext_list",
        name: "SKTL 통계",
        component: SktlStatsPage,
      },
      {
        key: "stats-detail-agentlist",
        path: "/stats/detail_agent_list",
        name: "사업자별 통계",
        component: AgentStatsPageByUser,
      },
    ],
  },
  {
    layout: EmptyLayout,
    contents: [
      {
        key: "manual",
        path: "/manual",
        name: "메뉴얼",
        component: ManualPage,
      },
    ],
  },
]

const defaultPath = "/service/service"

export default { noAuthRoutes, authRoutes, defaultPath }
