/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom"
import { ReactComponent as BizcallLogo } from "@app/assets/svg/BizcallLogo_sm.svg"

export default function Header() {
  const navigate = useNavigate()
  return (
    <div css={styles.container}>
      <BizcallLogo
        width={180}
        height={95}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/login")
        }}
      />
    </div>
  )
}

const styles = {
  container: {
    height: "95px",
    borderBottom: "1px solid #D9D9D9",
  },
}
