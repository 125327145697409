import { useState, useRef } from "react"
import dayjs from "dayjs"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"
import DownLoadButton from "@components/button/DownLoadButton"

import AutologSearch from "@containers/log/autolog/AutologSearch"
import AutologTable from "@containers/log/autolog/AutologTable"

import { objectClean } from "@utils/router"
import _cloneDeep from "lodash/cloneDeep"
import { useMobxStores } from "@stores/stores"
import { fileDownload } from "@utils/file"

import logApi from "@api/log"

export default function AutologPage() {
  const tableRef = useRef()
  const { userStore, globalStore } = useMobxStores()
  const [loading, setLoading] = useState(false)
  const [lastParams, setLastParams] = useState(null)

  function search(params) {
    const filter = _cloneDeep(params)

    const to = dayjs(filter.toDate?.toString())
    const from = dayjs(filter.fromDate?.toString())
    if (to && from && to.diff(from, "day", false) > 120)
      return globalStore.warningNoti("120일 이내의 기록만 조회할 수 있습니다.")
    if (filter.keyword) {
      filter[filter.keywordType] = filter.keyword
    }
    if (filter.success === "all") delete filter.success
    if (filter.agentId === "all") delete filter.agentId
    delete filter.keywordType
    delete filter.keyword
    delete filter.dateRange

    objectClean(filter)
    setLastParams(filter)
    tableRef?.current?.fetchList(filter)
  }

  async function onDownload() {
    // 엑셀 다운로드
    setLoading(true)
    try {
      let filter = _cloneDeep(lastParams)

      if (!filter.toDate || !filter.fromDate) {
        setLoading(false)
        return globalStore.warningNoti("조회날짜를 선택해주세요")
      }
      if (userStore?.super && (filter.agentId === "all" || !filter.agentId)) {
        setLoading(false)
        return globalStore.warningNoti("사업장을 선택해주세요")
      }

      const res = await logApi.getAutoLogsExcelBySuper(filter)
      const resData = res.data
      const today = dayjs().format("YYYYMMDDHHmm")
      const fileTitle = "비즈알림로그_리스트_"
      fileDownload(resData, fileTitle + today + ".xlsx")
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e.message || e
      )
    }
  }

  return (
    <>
      <PageContainer>
        <PageHeader
          title="비즈알림 조회"
          subTitle="알림 서비스 통화 이력을 조회합니다."
          rightActions={
            <>
              <DownLoadButton
                onClick={onDownload}
                loading={loading}
                text="다운로드"
              />
            </>
          }
        />
        <AutologSearch search={search} />
        <ContentContainer wrapper>
          <AutologTable ref={tableRef} lastParams={lastParams} />
        </ContentContainer>
      </PageContainer>
    </>
  )
}
