import {makeAutoObservable, runInAction} from "mobx";
import {notification} from "antd";
import authApi from "@api/auth";
import userApi from "@api/user";
import noticeApi from "@api/notice";
import {sha256, md5} from "@app/utils/encrypt";

export function createUserStore(rootStore) {
  const store = makeAutoObservable({
    accessToken: null,
    user: null,
    userRole: null,
    super: false,
    myRoutes: null,
    noti: null,
    initAuthStore() {
      const accessToken = localStorage.getItem("access-token");
      runInAction(() => {
        this.accessToken = accessToken;
        if (accessToken) {
          this.myInfo();
          this.userRoles();
        }
      });
    },
    async login(username, password, keep) {
      try {
        const params = {
          password: password,
          username: username,
        };
        const isEncryptProm = await userApi.getIsEncrypt(username);
        console.log(isEncryptProm.data);
        if (isEncryptProm.data.data) {
          // SHA256 암호화된 패스워드 사용계정 O
          const hashedPW = await sha256(password);
          if (hashedPW) {
            params.password = hashedPW;
          } else {
            rootStore.globalStore.errorNoti("비밀번호 암호화 오류. ");
          }
        } else {
          // SHA256 암호화된 패스워드 사용계정 X
          const hashedPW = await md5(password);
          if (hashedPW) {
            params.password = hashedPW;
          } else {
            rootStore.globalStore.errorNoti("비밀번호 암호화 오류. ");
          }
        }

        const authProm = await authApi.userLogin(params);
        localStorage.setItem("access-token", authProm?.data?.token);

        runInAction(() => {
          this.accessToken = authProm?.data?.token;
          this.myInfo();
          this.userRoles();
          localStorage.setItem("keep", keep || false);
          localStorage.setItem("keepID", (keep && username) || null);
        });
      } catch (e) {
        const meta = e?.response?.data?.meta;
        rootStore.globalStore.errorNoti(
          meta?.systemMessage === "Disable user" // 계정 승인 필요
            ? "관리자 승인 후 이용 가능합니다. 관리자에게 문의해주세요."
            : null,
          meta?.userMessage || e?.userMessage || e?.message || e
        );
      }
    },
    logout() {
      runInAction(() => {
        localStorage.removeItem("access-token");
        this.accessToken = null;
        this.user = null;
        this.super = false;
      });
    },
    async myInfo() {
      if (this.accessToken) {
        try {
          const infoProm = await userApi.getMyInfo();
          runInAction(() => {
            const info = infoProm.data.data;
            this.user = info;
            if (info?.userGrade?.id === 1) this.super = true;
          });
        } catch (e) {
          rootStore.globalStore.errorNoti(
            e?.response?.data?.meta?.userMessage || e?.message || e
          );
        }
      }
    },
    async userRoles() {
      try {
        const roleProm = await userApi.getUserRole({gradeId: 2});
        const roleRes = roleProm?.data?.data;
        runInAction(() => {
          this.userRole = roleRes?.map((e) => {
            e.accessWeb.fullPath =
              e.accessWeb.dir + e.accessWeb.page.replace(/\.do/gi, "");
            return e;
          });
        });
      } catch (e) {
        rootStore.globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        );
      }
    },
    //내 계정의 routes 목록들 저장
    async setMyRoutes(routes) {
      runInAction(() => {
        this.myRoutes = routes;
      });
    },
    // 토큰 재발급
    async refreshToken() {
      try {
        const params = {
          "user-token": this.accessToken,
        };

        const roleProm = await authApi.setRefreshToken(params);
        const token = roleProm?.data?.token;
        runInAction(() => {
          localStorage.setItem("access-token", token);
          this.accessToken = token;
        });
      } catch (e) {
        rootStore.globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        );
      }
    },
    // 공지사항 리스트 조회
    async getCurrentNoti(params) {
      try {
        const notiProm = await noticeApi.getNoticeCurrent(params);
        const noti = notiProm?.data?.data.filter((i) => {
          const expired = localStorage.getItem(
            params.type === "MAIN" ? "noti-ex-main" : "noti-ex-login"
          );
          if (expired && new Date().getTime() < expired) {
            // 하루동안 열지않기 값 있을 경우 비교하여 팝업창 노출/미노출
            return null;
          } else {
            const current = new Date().getTime();
            return (
              // 노출기간 만료여부 판단하여 팝업창 노출/미노출
              new Date(i.fromDate).getTime() < current &&
              new Date(i.toDate).getTime() > current
            );
          }
        });

        runInAction(() => {
          this.noti = {
            type: params?.type,
            open: noti?.length > 0,
            info: noti,
          };
        });
      } catch (e) {
        rootStore.globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        );
      }
    },
    clearNoti() {
      runInAction(() => {
        this.noti = null;
      });
    },
  });

  return store;
}
