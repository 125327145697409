import { useRef } from "react"
import dayjs from "dayjs"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"

import { objectClean } from "@utils/router"
import _cloneDeep from "lodash/cloneDeep"
import { observer } from "mobx-react"
import { useMobxStores } from "@stores/stores"
import { useState } from "react"
import AgentStatsSearchByUser from "@app/containers/stats/agent/AgentStatsSearchByUser"
import AgentStatsTableByUser from "@app/containers/stats/agent/AgentStatsTableByUser"
import { fileDownload } from "@app/utils/file"
import statisticsApi from "@api/statistics"
import { Form } from "antd"
import DownLoadButton from "@app/components/button/DownLoadButton"

export default observer(function AgentStatsPageByUser() {
  const { globalStore } = useMobxStores()
  const tableRef = useRef()
  const [viewVN, setViewVN] = useState(true) //가상번호표시 검색
  const [loading, setLoading] = useState(false)
  const [lastParams, setLastParams] = useState(null)
  const [form] = Form.useForm()

  function search(params) {
    const filter = _cloneDeep(params)
    const to = dayjs(filter.toDate?.toString())
    const from = dayjs(filter.fromDate?.toString())
    const limitRange = filter?.viewVN ? 31 : 120

    if (!filter.toDate || !filter.fromDate) {
      setLoading(false)
      return globalStore.warningNoti("조회날짜를 선택해주세요")
    }
    if (to && from && to.diff(from, "day", false) > limitRange)
      return globalStore.warningNoti(
        `${limitRange}일 이내의 기록만 조회할 수 있습니다.`
      )
    if (filter.agentId === "all") delete filter.agentId
    if (filter.anIsMobile === "all") delete filter.anIsMobile
    if (filter.rnIsMobile === "all") delete filter.rnIsMobile
    if (filter.viewVN === "all") delete filter.viewVN
    delete filter.dateRange
    delete filter.dateSingle

    filter.page = 0
    filter.size = 20

    setViewVN(filter?.viewVN)
    objectClean(filter)
    setLastParams(filter)
    tableRef?.current?.fetchList(filter)
  }

  async function onDownload() {
    // 엑셀 다운로드
    setLoading(true)
    try {
      let filter = _cloneDeep(lastParams)

      const res = await statisticsApi.getStatisticsExcelByUser(filter)
      const resData = res.data
      const today = dayjs().format("YYYYMMDDHHmm")
      const fileTitle = "사업자별_통계_일반유저"

      fileDownload(resData, fileTitle + today + ".xlsx")
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  return (
    <>
      <PageContainer>
        <PageHeader
          title="사업자별"
          subTitle="사업자 별 월별통계를 조회합니다."
          rightActions={
            <>
              <DownLoadButton
                style={{ float: "right", marginLeft: 12 }}
                text="다운로드"
                loading={loading}
                onClick={onDownload}
              />
            </>
          }
        />
        <AgentStatsSearchByUser search={search} form={form} />
        <ContentContainer wrapper>
          <AgentStatsTableByUser
            ref={tableRef}
            viewVN={viewVN}
            lastParams={lastParams}
            setLastParams={setLastParams}
          />
        </ContentContainer>
      </PageContainer>
    </>
  )
})
