/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Form, Input, Button, Row, Col } from "antd"
import _has from "lodash/has"

import { MediaQuery } from "@utils/style"
import { isPassword, isEmail } from "@utils/validation"
import { useMobxStores } from "@stores/stores"

import Timer from "@components/common/Timer"

import userApi from "@api/user"
import smsApi from "@api/sms"

export default function SignupPage() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { globalStore } = useMobxStores()
  const [rules, setRules] = useState({
    id: undefined,
    password: undefined,
    email: undefined,
    auth: undefined,
    authError: undefined,
  })
  const [time, setTime] = useState(null)

  // 아이디 중복 검사
  async function checkId(e) {
    const id = e.target.value
    if (!id) {
      return setRules({
        ...rules,
        id: null,
      })
    }
    try {
      const existProm = await userApi.getIdExists(id)
      setRules({
        ...rules,
        id: existProm?.data?.data,
      })
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  // 이메일 중복 검사
  async function checkEmail(e) {
    const email = e.target.value
    if (!email || !isEmail(email)) {
      return setRules({
        ...rules,
        email: null,
      })
    }
    try {
      const existProm = await userApi.getEmailExists(email)
      setRules({
        ...rules,
        email: existProm?.data?.data,
      })
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  // 전화번호 인증 요청
  async function getAuthByPhone() {
    try {
      const phoneNumber = form?.getFieldValue()?.phoneNumber
      const authProm = await smsApi.getNoAuthNumber(phoneNumber)
      setTime(authProm.data?.data?.expireSec)
      globalStore.successNoti("인증번호를 전송하였습니다.")
      setRules({
        ...rules,
        auth: undefined,
        authError: undefined,
      })
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  // 전화번호 인증 확인
  async function setAuthByPhone() {
    try {
      const value = form?.getFieldValue()
      const params = {
        authNumber: value.authNumber,
        phoneNumber: value.phoneNumber,
      }
      console.log(params)
      await smsApi.setAuthNumber(params)
      globalStore.successNoti("인증번호 확인이 완료되었습니다.")
      setRules({
        ...rules,
        auth: false,
        authError: false,
      })
      setTime(null)
    } catch (e) {
      setRules({
        ...rules,
        authError: true,
      })
    }
  }

  // 회원가입 요청
  async function signup() {
    try {
      await userApi.createUser(form.getFieldValue())
      globalStore.successNoti(
        "가입요청이 완료되었습니다. 관리자 승인 후 이용 가능합니다."
      )
      navigate("/login")
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  return (
    <div css={styles.container}>
      <Form form={form} scrollToFirstError size="large">
        <Form.Item
          name="id"
          required={true}
          {...(_has(rules, "id") && {
            hasFeedback: true,
            help:
              rules?.id === undefined
                ? ""
                : rules?.id === null
                ? "아이디를 입력해주세요"
                : !rules?.id
                ? "사용가능한 아이디입니다."
                : "사용 불가능한 아이디입니다.",
            validateStatus:
              rules?.id === undefined
                ? null
                : rules?.id || rules?.id === null
                ? "error"
                : "success",
          })}
          label="아이디"
        >
          <Input
            placeholder="ID는 한글을 제외한 문자열만 가능합니다."
            onBlur={checkId}
            onPressEnter={checkId}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "비밀번호를 입력해주세요",
            },
            {
              validator(_, value) {
                if (!value || isPassword(value)) {
                  setRules({
                    ...rules,
                    password: false,
                  })
                  return Promise.resolve()
                } else {
                  setRules({
                    ...rules,
                    password: true,
                  })
                  return Promise.reject(
                    new Error("비밀번호를 다시 확인해주세요.")
                  )
                }
              },
            },
          ]}
          label="비밀번호"
        >
          <Input.Password placeholder="비밀번호를 입력해주세요." />
        </Form.Item>
        <Form.Item
          name="passwordCheck"
          rules={[
            {
              required: true,
              message: "비밀번호를 입력해주세요",
            },
            {
              validator(_, value) {
                if (!value || value === form.getFieldValue()?.password) {
                  setRules({
                    ...rules,
                    password: false,
                  })
                  return Promise.resolve()
                } else {
                  setRules({
                    ...rules,
                    password: true,
                  })
                  return Promise.reject(
                    new Error("비밀번호를 다시 확인해주세요.")
                  )
                }
              },
            },
          ]}
          label="비밀번호 확인"
        >
          <Input.Password placeholder="비밀번호를 다시 입력해주세요." />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "사용자 이름을 입력해주세요",
            },
          ]}
          label="이름"
        >
          <Input placeholder="사용자 이름을 입력해주세요." />
        </Form.Item>
        <Form.Item
          name="email"
          required={true}
          {...(_has(rules, "email") && {
            hasFeedback: true,
            help:
              rules?.email === undefined
                ? ""
                : rules?.email === null
                ? "이메일을 확인해주세요"
                : !rules?.email
                ? "사용가능한 이메일입니다."
                : "사용 불가능한 이메일입니다.",
            validateStatus:
              rules?.email === undefined
                ? null
                : rules?.email || rules?.email === null
                ? "error"
                : "success",
          })}
          label="이메일"
        >
          <Input
            placeholder="이메일을 입력해주세요.(이후 본인확인 시 사용 됩니다)"
            onBlur={checkEmail}
            onPressEnter={checkEmail}
          />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "휴대 번호를 입력해주세요",
            },
          ]}
          label="휴대전화"
        >
          <Input
            placeholder="휴대 번호를 입력해주세요."
            addonAfter={
              <>
                {(time && (
                  <Timer
                    mm={time / 60}
                    ss={time % 60}
                    onFinish={() => {
                      setTime(null)
                      setRules({
                        ...rules,
                        auth: undefined,
                      })
                    }}
                  />
                )) || (
                  <Button type="text" onClick={getAuthByPhone}>
                    인증번호
                  </Button>
                )}
              </>
            }
          />
        </Form.Item>
        <Form.Item
          name="authNumber"
          rules={[
            {
              required: true,
              message: "발송 된 인증번호를 입력해주세요",
            },
          ]}
          {...(_has(rules, "authError") && {
            hasFeedback: true,
            help:
              rules?.authError === undefined
                ? ""
                : !rules?.authError
                ? "인증번호 확인이 완료되었습니다."
                : "인증번호를 다시 확인해주세요.",
            validateStatus:
              rules?.authError === undefined
                ? null
                : rules?.authError || rules?.authError === null
                ? "error"
                : "success",
          })}
          label="인증확인"
        >
          <Input
            placeholder="발송 된 인증번호를 입력해주세요."
            addonAfter={
              <Button disabled={!time} type="text" onClick={setAuthByPhone}>
                확인
              </Button>
            }
          />
        </Form.Item>
        <Row className="btn-row">
          <Col span={12}>
            <Button block onClick={() => navigate("/login")}>
              취소
            </Button>
          </Col>
          <Col span={12}>
            <Button
              disabled={
                !(
                  rules?.id === false &&
                  rules?.password === false &&
                  rules?.email === false &&
                  rules?.auth == false
                )
              }
              block
              type="primary"
              onClick={signup}
            >
              가입
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    height: "75vh",
    "& .ant-form-item-explain": {
      fontSize: 12,
    },
    "& .ant-input-group-addon .ant-btn": {
      maxHeight: "38px",
    },
    [MediaQuery[1]]: {
      height: "80vh",
      "& .ant-form-item-label": {
        height: 30,
        "& label": {
          height: 30,
        },
      },
      "& .ant-form-item": {
        marginBottom: 5,
      },
      "& .btn-row": {
        marginTop: 20,
      },
    },
  },
}
