import { axiosInstance as axios } from "@app/axios";
import qs from "qs";

export default {
  //SKTL 번호 목록 조회
  getSKTLNumberList: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/sk/virtual-numbers/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    };
    return axios(requestOptions);
  },
  //SKTL 번호 상세
  getSKTLNumberDetail: (virtualNumber) => {
    const requestOptions = {
      method: "GET",
      url: `/api/sk/virtual-numbers/v1/${virtualNumber}/complete`,
    };
    return axios(requestOptions);
  },
  //SKTL 번호 수정
  putSKTLNumber: (params) => {
    const requestOptions = {
      method: "PUT",
      url: `/api/sk/virtual-numbers/v1`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(params),
    };
    return axios(requestOptions);
  },

  //SKTL 번호 엑셀 다운로드
  getSKTLNumberExcel: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/sk/virtual-numbers/v1/excel-export?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 5 * 60 * 1000,
      responseType: "blob",
    };
    return axios(requestOptions);
  },
};
