/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom"
import { Form, Checkbox, Button, Row, Col } from "antd"

import { MediaQuery, useIsMobile } from "@utils/style"
import { useMobxStores } from "@stores/stores"

const initCheck = {
  use: false,
  privacy: false,
}

export default function TermsPage() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { globalStore } = useMobxStores()

  // 이용약관 모두 동의
  function onAllChange(e) {
    form.setFieldsValue({
      use: e.target.checked,
      privacy: e.target.checked,
    })
  }

  function nextStep() {
    const unCheck = Object.values(form.getFieldValue())?.filter((v) => {
      return v === false
    })

    if (unCheck.length > 0) {
      return globalStore.warningNoti("이용약관 동의 후 가입 가능합니다.")
    }
    navigate("/signup")
  }

  return (
    <div css={styles.container}>
      <Form form={form} initialValues={initCheck}>
        <Form.Item valuePropName="checked">
          <Checkbox onChange={onAllChange}>
            050 비즈콜 이용약관, 개인정보 취급방침에 모두 동의 합니다.
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Form.Item name="use" className="mb-8" valuePropName="checked">
            <Checkbox>050 비즈콜 이용약관 동의(필수)</Checkbox>
          </Form.Item>
          <div css={styles.textArea}>
            <p>- 050비즈콜 이용약관 -</p>
            <p>제1조(목적)</p>
            <p>
              이 약관은 델피콤 주식회사(이하 "회사")가 제공하는 비즈콜
              서비스(이하”서비스”)와 관련 하여 회사와 이용자의 권리와 의무
              책임사항, 이용조건 및 기타 필요한 사항을 규정하는데 목적이
              있습니다.
            </p>
            <p>제2조(용어의 정의)</p>
            <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
            <p>
              서비스 이용자 : 회사가 제공하는 서비스를 이용하는 업체 및
              이용자(이하”이용자”)를 말합니다.
            </p>
            <p>
              알림멘트 : 전화통화 연결시 수신자에게 통화의 유입경로를 알려주는
              멘트
            </p>
            <p>컬러링 : 전화통화 연결시 발신자가 청취하는 각종 음원 및 멘트</p>
            <p>콜로그 : 서비스 이용에 따른 통화 기록</p>
            <p>
              본 약관에서 정의되지 않은 용어는 관련 법령이 정하는 바에 따르며,
              그 외에는 일반적인 상거래관행에 의합니다.
            </p>
            <p>제3조(약관의 효력 및 변경)</p>
            <p>
              이 약관은 회사의 홈페이지에 게시하거나 이용자에게 기타의 방법으로
              공지 함으로서 효력이 발생합니다.
            </p>
            <p>
              회사는 약관의 규제 등에 관한 법률,전자상거래 관련법, 전자 서명법,
              정보통신망 이용촉진 등에 관한 법률, 소비자보호법 등 관련법을
              위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
            </p>
            <p>
              회사는 약관을 개정할 경우 변경된 약관을 최소 7일전에 현행약관과
              같이 공지합니다.
            </p>
            <p>
              이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고
              이용계약을 해지 할수 있습니다. 단, 이용계약시 체결된 계약
              위반사항이 아닌 경우 이용을 중단할 수 없습니다.
            </p>
            <p>
              이용자는 약관의 변경에 대해 주의 의무를 다해야 하며, 약관의 부지로
              인한 이용자의 피해는 회사가 책임지지 않습니다.
            </p>
            <p>제4조(서비스 이용계약의 성립)</p>
            <p>
              서비스 이용계약은 회사와 이용자(업체)간의 서면 계약으로
              성립됩니다.
            </p>
            <p>
              이용자는 약관이 변경될 경우 서비스 계약 사항에 준하여 이를 거부할
              권리가 있습니다.
            </p>
            <p>제5조(서비스 신청 및 승낙)</p>
            <p>
              서비스 이용신청은 “회사”에서 정한 절차에 따라 이용자가 신청하는
              방식으로 진행합니다.
            </p>
            <p>회사는 이용신청한 이용자에 대해 서비스 이용을 승낙합니다.</p>
            <p>
              제6조(서비스의 내용)회사가 제공하는 서비스는 기간 통신사의
              부가서비스용 번호를 이용하여 배달 및 기타 업종 사업자를 위해
              고객과 상점(수신자)간의 전화 통화시에 발신자에게는 컬러링을,
              수신자에는
            </p>
            <p>
              알림멘트를 제공해주고, 사업자에게는 콜로그를 제공해 주는 서비스
              입니다.
            </p>
            <p>제7조(서비스 이용요금)</p>
            <p>
              본 서비스는 유료서비스로 제공되며, 업체 또는 이용자는 서비스의
              상업적인 사용을 위해
            </p>
            <p>
              번호 회선당 컬러링 기본 사용료가 부과되며, 회선 수량에 따라
              사용료가 증감될 수 있습니다.
            </p>
            <p>
              제8조(서비스의 이용 및 중지)① 서비스는 이용자의 신청 후 사용하실
              수 있고 연중무휴 1일 24시간을 원칙으로 합니다.
            </p>
            <p>
              ② 회사는 다음 각 호에 해당하는 경우에는 서비스 제공을 중단 할수
              있습니다.- 전기통신사업법에 규정된 기간통신사업자가
              전기통신서비스를 중지하였을 경우
            </p>
            <p>- 기타 불가항력적 사유가 있는 경우</p>
            <p>
              - 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등
              정상적인 서비스 제공에
            </p>
            <p>지장이 있는 경우</p>
            <p>제9조(통화 기록 내용)</p>
            <p>
              회사는 이용자 고객의 통화 내용을 감시하거나 도청하지 않습니다.
              다만, 회사는 통화량 및
            </p>
            <p>
              사용 분수 등 통계 자료로 활용하기 위한 자료로 사용할 수 있습니다.
            </p>
            <p>제10조(통화량의 산정)</p>
            <p>
              ① 서비스 이용 시간은 전화 연결시 발신 측과 착신 측이 통신할 수
              있는 상태가 된 시점부
            </p>
            <p>
              터 양측의 어느 일방이 통신 이용의 종료 신호를 인식한 때로 합니다.②
              서비스의 이용 중에 회선장애나 그 밖에 계약자에게 책임이 없는
              사유로 서비스를 일시
            </p>
            <p>
              이용할 수 없었던 시간은 산입하지 않습니다.③ 2항의 규정에도
              불구하고 상대 측의 단말기에 자동응답장치, 착신전화, PC, FAX, 등이
            </p>
            <p>설치되어 응답이 있었던 때에는 통화가 개시된 것으로 봅니다.</p>
            <p>
              제11조(이용 고객 보호) ① 회사는 파산 또는 그에 상응하는 조건
              하에서 이용 고객의 피해를 보장하기 위한 보증
            </p>
            <p>보험에 가입해 있습니다.</p>
            <p>
              ② 회사는 이용 고객으로부터 제기되는 의견이나 불만이 정당하다고
              인정될 경우에는 즉시
            </p>
            <p>
              처리해야 합니다. 다만, 즉시 처리가 곤란한 경우에는 이용 고객에게
              그 사유와 처리
            </p>
            <p>일정을 통보해야 합니다</p>
            <p>
              제12조(이용 고객지원센터 운영) ① 회사는 서비스 이용의 불편 및 민원
              사항의 처리를 위하여 이용 고객 지원 센터를 설치
            </p>
            <p>운영합니다.</p>
            <p>
              ② 이용 고객 지원 센터는 이용 고객의 제반 불편 사항을 접수, 기록,
              관리하여 일정한
            </p>
            <p>
              기간 내에 발생한 문제 사항에 대하여 그 처리 결과를 통지합니다.
            </p>
            <p>
              제13조(회사의 의무)① 회사는 이용자 및 이용자 고객의 계약정보를
              본인의 승낙 없이 제3자에게 누설 및
            </p>
            <p>
              배포하지 않습니다. 단, 관계법령에 의한 수사상의 목적으로
              관계기관으로부터 요구받은
            </p>
            <p>
              경우나 정보통신윤리위원회의 요청이 있는 경우 등 법률의 규정에 따른
              적법한 절차에
            </p>
            <p>의한 경우에는 그러하지 않습니다.</p>
            <p>
              ② 회사는 정보통신망 이용촉진 및 정보보호에 관한 법률,
              통신비밀보호법, 전기통신사업법
            </p>
            <p>등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.</p>
            <p>
              제14조(이용자의 의무)① 회사의 서비스를 이용하여 얻은 정보를 회사의
              사전 승낙 없이 복제 또는 유통시키거나
            </p>
            <p>불법적으로 이용하는 행위</p>
            <p>
              ② 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에
              공지한 주의사항, 회사가
            </p>
            <p>
              통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는
              행위를 하여서는 아니 됩니다.
            </p>
            <p>
              ③ 이용자는 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를
              이용하여 불법적인
            </p>
            <p>
              상업행위를 할 수 없으며 이를 위반하여 발생한 영업 활동의 결과 및
              손실, 관계기관에
            </p>
            <p>
              의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않으며,
              이용자는 이와 같은
            </p>
            <p>행위와 관련하여 회사에 대하여 손해배상 의무를 집니다.</p>
            <p>
              ④ 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에
              공지한 주의사항, 회사가
            </p>
            <p>
              통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는
              행위를 하여서는
            </p>
            <p>아니 됩니다.</p>
            <p>제15조(이용자 개인정보보호)</p>
            <p>
              회사는 관련법령이 정하는 바에 따라서 이용자 고객의 개인정보를
              보호하기 위하여 노력합니다. 이용자의 개인정보보호에 관해서는
              관련법령 및 회사가 정하는 "개인정보보호정책"에 정한 바에 의합니다.
            </p>
            <p>
              제16조(개인정보의 위탁)회사는 수집된 개인정보의 취급 및 관리 등의
              업무(이하 "업무")를 스스로 수행함을 원칙으로 하나, 필요한 경우
              업무의 일부 또는 전부를 회사가 선정한 회사에 위탁할 수 있습니다.
            </p>
            <p>
              제17조(계약해지 및 이용제한)① 이용자가 서비스 이용계약을
              해지하고자 할 경우에는 계약조항에 근거하여 서비스 이용
            </p>
            <p>해지 신청을 해야 합니다.</p>
            <p>
              ② 회사는 이용자가 제15조에 규정한 이용자의 의무를 이행하지 않을
              경우, 사전 통지
            </p>
            <p>
              없이 즉시 이용계약을 해지하거나 또는 서비스 이용을 중지할 수
              있습니다.③ 이용자의 이용제한 조치에 따른 이의가 있을 경우 정당성이
              인정되면 서비스를 재게합니다.
            </p>
            <p>
              제18조(양도 금지)이용자는 서비스의 이용권한, 기타 이용 계약상
              지위를 타인에게 양도, 증여할 수 없으며 이에 따른 책임은 이용자에게
              있습니다.
            </p>
            <p>
              제19조(손해배상)① 이용자가 본 약관의 규정을 위반함으로 인하여
              회사에 손해가 발생하게 되는 경우, 이
            </p>
            <p>
              약관을 위반한 이용자는 회사에 발생하는 모든 손해를 배상하여야
              합니다.
            </p>
            <p>
              ③ 회사가 제공하는 서비스 이용 중 회사의 책임으로 손해가 발생할
              경우 이용자는 서비스
            </p>
            <p>의 월 이용료 한도 내에서 손해배상을 청구할 수 있습니다.</p>
            <p>
              제20조(면책사항)① 회사는 이용자의 귀책사유 및 천재지변 또는 이에
              준하는 불가항력으로 인하여 서비스를
            </p>
            <p>
              제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.②
              회사는 이용자 상호간 또는 이용자와 제3자 상호간에 서비스를 매개로
              발생한 분쟁에
            </p>
            <p>
              대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도
              없습니다.
            </p>
            <p>
              제21조(관할법원)① 서비스 이용과 관련하여 회사와 이용자 사이에
              분쟁이 발생한 경우, 회사와 이용자는
            </p>
            <p>분쟁의 해결을 위해 성실히 협의합니다.</p>
            <p>
              ② 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는
              민사소송법상의
            </p>
            <p>관할법원에 소를 제기할 수 있습니다.</p>
            <p>부칙(시행일) 이 약관은 2013년 2월 1 일부터 시행합니다.</p>
          </div>
        </Form.Item>
        <Form.Item>
          <Form.Item name="privacy" className="mb-8" valuePropName="checked">
            <Checkbox>050 비즈콜 개인정보 취급 방침 동의(필수)</Checkbox>
          </Form.Item>
          <div css={styles.textArea}>
            <p>
              <span>- 비즈콜 개인정보 처리방침 -</span>
              <br />
              <br />
              델피콤 (은)는 개인정보 보호법 제30조에 따라 정보주체(고객)의
              개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
              있도록 하기 위하여 다음과 같이 개인정보 처리지침을
              수립·공개합니다.
              <br />
              <br />
              <span>1. 개인정보의 처리목적</span>
              <br />
              델피콤 은(는) 다음의 목적을 위하여 개인정보를 처리하고 있으며,
              다음의 목적 이외의 용도로는 이용하지 않습니다.
              <br />
              - 고객에 대한 서비스 제공에 따른 전화번호
              <br />
              <br />
              <span>2. 개인정보의 처리 및 보유기간 </span>
              <br />
              ① 델피콤은(는) 정보주체로부터 개인정보를 수집할 때 동의받은
              개인정보 보유·이용기간 또는 법령에 따른 개인정보 보유·이용기간
              내에서 개인정보를 처리·보유합니다. <br />
              ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다. <br />
              - 고객 가입 및 관리 : 서비스 이용계약 또는 해지 시까지, 다만
              채권·채무관계 잔존시에는 해당 채권·채무관계 정산시까지 <br />
              - 전자상거래에서의 계약·청약철회, 대금결제, 재화 등 공급기록 : 5년
              <br />
              <br />
              <span>3.개인정보의 제3자 제공 </span>
              <br />
              델피콤은(는) 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보
              보호법 제17조에 해당하는 경우 외에는 개인정보를 제3자에게 제공하지
              않습니다. <br />
              <br />
              <span>4.개인정보처리의 위탁 </span>
              <br />
              ① 델피콤은(는) 원칙적으로 이용자의 동의없이 해당 개인정보의 처리를
              타인에게 위탁하지 않습니다. <br />
              <br />
              <span>5.정보주체와 법정대리인의 권리·의무 및 행사방법 </span>
              <br />
              정보주체는 델피콤에 대해 언제든지 다음 각 호의 개인정보 보호 관련
              권리를 행사할 수 있습니다. <br />
              1. 개인정보 열람요구
              <br />
              2. 개인정보에 오류 등이 있을 경우 정정 요구 <br />
              3. 삭제요구 <br />
              4. 처리정지 요구 <br />
              <br />
              <span>6.처리하는 개인정보 항목 </span>
              <br />
              델피콤은(는) 다음의 개인정보 항목을 처리하고 있습니다. <br />
              - 전화번호 <br />
              <br />
              <span>7.개인정보의 파기 </span>
              <br />
              ① 델피콤은(는) 개인정보 보유기간의 경과, 처리목적 달성 등
              개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
              파기합니다. <br />
              ② 델피콤은(는) 다음의 방법으로 개인정보를 파기합니다. <br />
              - 전자적 파일 : 파일 삭제 및 디스크 등 저장매체 포맷
              <br />
              - 수기(手記) 문서 : 분쇄하거나 소각 <br />
              <br />
              <span>8.개인정보의 안전성 확보조치 </span>
              <br />
              델피콤은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를
              취하고 있습니다. <br />
              - 관리적 조치 : 직원·종업원 등에 대한 정기적 교육 <br />
              - 기술적 조치 : 개인정보처리시스템(또는 개인정보가 저장된
              컴퓨터)의 비밀번호 설정 등 접근권한 관리, 백신소프트웨어 등
              보안프로그램 설치,
              <br />
              - 물리적 조치 : 개인정보가 저장·보관된 장소의 출입통제 등 <br />
              <br />
              <span>
                9.개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항
              </span>
              <br />
              ① 델피콤은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해
              이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
              <br />
              ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의
              컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
              컴퓨터내의 하드디스크에 저장되기도 합니다
              <br />. 가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹
              사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을
              파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
              <br />
              나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷
              옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
              있습니다.
              <br />
              다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할
              수 있습니다.
              <br />
              <br />​<span>10.개인정보 보호책임자 </span>
              <br />
              델피콤은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고,
              개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기
              위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <br />
              ▶ 개인정보 보호책임자 <br />
              성명 : 김동은 직책 : 이사 <br />
              연락처 : 02-2624-3350
              <br />
              <br />
              또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는
              아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
              <br />
              <br />
              개인정보침해 신고센터
              <br />
              (국번없이)118
              <br />
              https://privacy.kisa.or.kr
              <br />
              <br />
              대검찰청 사이버수사과
              <br />
              (국번없이)1301
              <br />
              cid@spo.go.kr
              <br />
              <br />
              경찰청 사이버안전국
              <br />
              (국번없이)182
              <br />
              https://cyberbureau.police.go.kr
            </p>
          </div>
        </Form.Item>
        <Row>
          <Col span={12}>
            <Button block onClick={() => navigate("/login")} size="large">
              취소
            </Button>
          </Col>
          <Col span={12}>
            <Button block type="primary" onClick={nextStep} size="large">
              다음
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    height: "76vh",
    "& .ant-form-item": {
      marginBottom: 12,
      [MediaQuery[1]]: {
        marginBottom: 24,
      },
    },
    [MediaQuery[1]]: {
      height: "80vh",
    },
    "& .mb-8": {
      marginBottom: 8,
    },
  },
  textArea: {
    border: "0.5px solid rgba(0, 0, 0, 0.25)",
    maxWidth: 460,
    height: 180,
    overflowY: "auto",
  },
}
