import { Breadcrumb as AntdBreadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";

import menus from "@app/menus";

function Breadcrumb(props) {
  const location = useLocation();
  const { pathname } = location;
  let breadcrumbItems = [];
  menus.forEach((menu, i) => {
    if (menu.path === pathname && menu.breadcrumbs) {
      breadcrumbItems = menu.breadcrumbs.map((breadcrumb, j) => (
        <AntdBreadcrumb.Item key={`breadcrumb-${j}`}>
          <Link to={menu.path}>{breadcrumb}</Link>
        </AntdBreadcrumb.Item>
      ));
    }
    if (menu.subMenus) {
      menu.subMenus.forEach((sub, i) => {
        if (sub.path === pathname && sub.breadcrumbs) {
          breadcrumbItems = sub.breadcrumbs.map((breadcrumb, j) => (
            <AntdBreadcrumb.Item key={`breadcrumb-${j}`}>
              <Link to={sub.path}>{breadcrumb}</Link>
            </AntdBreadcrumb.Item>
          ));
        }
      });
    }
  });
  return <AntdBreadcrumb>{breadcrumbItems}</AntdBreadcrumb>;
}
export default Breadcrumb;
