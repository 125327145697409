/* eslint-disable import/no-anonymous-default-export */
import {axiosInstance as axios} from "@app/axios";
import qs from "qs";

export default {
  // 내정보 조회
  getMyInfo: () => {
    const requestOptions = {
      method: "GET",
      url: `api/admins/v1/mine`,
    };
    return axios(requestOptions);
  },
  // 비밀번호 sha256사용 여부 조회
  getIsEncrypt: (id) => {
    const requestOptions = {
      method: "GET",
      url: `api/admins/v1/pw/encrypt/id/${id}`,
    };
    return axios(requestOptions);
  },
  // 내정보 수정
  updateMyInfo: (data) => {
    const requestOptions = {
      method: "PUT",
      url: `api/admins/v1/mine`,
      data: JSON.stringify(data),
    };
    return axios(requestOptions);
  },
  // 사용자 권한 조회
  getUserRole: (params) => {
    const requestOptions = {
      method: "GET",
      url: `api/user-role/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    };
    return axios(requestOptions);
  },
  // 아이디 찾기 (이메일 전송)
  findUserId: (params) => {
    const requestOptions = {
      method: "PUT",
      url: `api/admins/v1/find-id/name/${params.userName}/phone/${params.phoneNumber}`,
    };
    return axios(requestOptions);
  },
  // 비밀번호 찾기 (이메일 전송)
  findUserPassWord: (params) => {
    const requestOptions = {
      method: "PUT",
      url: `api/admins/v1/find-pw/name/${params.userName}/id/${params.id}/phone/${params.phoneNumber}`,
    };
    return axios(requestOptions);
  },
  // 아이디 중복 체크
  getIdExists: (id) => {
    const requestOptions = {
      method: "GET",
      url: `api/admins/v1/id/${id}/exists`,
    };
    return axios(requestOptions);
  },
  // 이메일 중복 체크
  getEmailExists: (email) => {
    const requestOptions = {
      method: "GET",
      url: `api/admins/v1/email/${email}/exists`,
    };
    return axios(requestOptions);
  },
  // 가입요청
  createUser: (data) => {
    const requestOptions = {
      method: "POST",
      url: "api/admins/v1/join",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(data),
    };
    return axios(requestOptions);
  },
  // 사용자 조회(슈퍼유저)
  getAdmins: (params) => {
    const requestOptions = {
      method: "GET",
      url: `api/admins/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    };
    return axios(requestOptions);
  },
  // 사용자 조회(단일)
  getAdminById: (adminId) => {
    const requestOptions = {
      method: "GET",
      url: `api/admins/v1/${adminId}/complete`,
    };
    return axios(requestOptions);
  },
  // 사용자 정보 변경(슈퍼유저)
  putAdmin: (userId, data) => {
    const requestOptions = {
      method: "PUT",
      url: `api/admins/v1/${userId}`,
      data: data,
    };
    return axios(requestOptions);
  },
  // 사용자 생성(슈퍼유저)
  createAdmin: (data) => {
    const requestOptions = {
      method: "POST",
      url: `api/admins/v1/create`,
      data: data,
    };
    return axios(requestOptions);
  },
  // 비밀번호 만료일 연장(6개월)
  extendPassword: () => {
    const requestOptions = {
      method: "PUT",
      url: `api/admins/v1/expire-date/extend`,
    };
    return axios(requestOptions);
  },
  // 비밀번호 수정(인증 X)
  updatePasswordNoAuth: (params) => {
    const requestOptions = {
      method: "PUT",
      url: `api/admins/v1/update/password/no-auth?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    };
    return axios(requestOptions);
  },
};
