import { useState, useEffect } from "react"

import { useMobxStores } from "@stores/stores"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"

import ServiceStatusSearch from "@containers/service/service/ServiceStatusSearch"
import ServiceSettingTable from "@containers/service/service/ServiceSettingTable"
import ServiceStatusTable from "@containers/service/service/ServiceStatusTable"
import { observer } from "mobx-react"

import agentApi from "@api/agent"

export default observer(function ServiceStatusPage() {
  const { userStore, globalStore } = useMobxStores()
  const [agent, setAgent] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (
      !userStore?.super &&
      userStore?.user?.agent &&
      userStore?.user?.agent?.id !== 1
    ) {
      setAgent({
        ...userStore?.user?.agent,
      })
      setLoading(false)
    }
  }, [userStore?.user])

  // 사업장 리스트
  async function fetchAgent(agentId) {
    setLoading(true)
    try {
      const agentProm = await agentApi.getAgentById(agentId)
      setAgent(agentProm?.data?.data)
      setLoading(false)
    } catch (e) {
      setLoading(true)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  return (
    <>
      <PageContainer>
        <PageHeader
          title="서비스 현황"
          subTitle="사업장별 서비스를 설정합니다."
        />
        {userStore?.super && <ServiceStatusSearch onSearch={fetchAgent} />}
        {!loading && (
          <ContentContainer wrapper>
            {/* 사업장 현황 상세와 동일하기 때문에 생략
            {userStore?.super && <ServiceSettingTable agent={agent} />} 
            */}
            {<ServiceStatusTable agent={agent} />}
          </ContentContainer>
        )}
      </PageContainer>
    </>
  )
})
