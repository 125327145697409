import React from "react"
import { makeAutoObservable, runInAction } from "mobx"

import { createUserStore } from "./userStore"
import { createGlobalStore } from "./globalStore"

function createRootStore() {
  const store = makeAutoObservable({
    // obesrvables
    userStore: null,
    globalStore: null,
    // actions / functions
    setNodeStores(nodeStores) {
      runInAction(() => {
        for (const storeName in nodeStores) {
          this[storeName] = nodeStores[storeName]
        }
      })
    },
  })
  return store
}

const rootStore = createRootStore()

rootStore.setNodeStores({
  userStore: createUserStore(rootStore),
  globalStore: createGlobalStore(rootStore),
})

const StoreContext = React.createContext()

export function StoreProvider(props) {
  return (
    <StoreContext.Provider value={rootStore}>
      {props.children}
    </StoreContext.Provider>
  )
}

export function useMobxStores() {
  return React.useContext(StoreContext)
}
