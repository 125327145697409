/** @jsxImportSource @emotion/react */
import { useEffect } from "react"
import {
  Descriptions,
  Form,
  Input,
  Row,
  Col,
  Radio,
  Button,
  Popconfirm,
} from "antd"
import dayjs from "dayjs"
import _cloneDeep from "lodash/cloneDeep"
import { useNavigate, useLocation } from "react-router-dom"
import { observer } from "mobx-react"

import DetailContainer from "@components/container/DetailContainer"
import DatePicker from "@components/picker/DatePicker"

import { useMobxStores } from "@stores/stores"
import { getQuery, pushQuery } from "@utils/router"
import noticeApi from "@api/notice"

const initialValues = {
  title: null,
  content: null,
  type: "LOGIN",
  visible: true,
  toDate: null,
  toDateRange: null,
  fromDate: null,
  fromDateRange: null,
}

export default observer(function NoticeStatusDetail(props) {
  const { id, fetchList } = props
  const [form] = Form.useForm()
  const { globalStore } = useMobxStores()
  const navigate = useNavigate()
  const location = useLocation()
  const query = getQuery(location)

  function close() {
    delete query.id
    delete query.add
    pushQuery(query, navigate)
  }

  async function fetchNotice() {
    // 사용자 조회
    try {
      const noticeProm = await noticeApi.getNoticeById(id)
      const noticeRes = noticeProm?.data?.data
      if (noticeRes.toDate) {
        noticeRes.toDateRange = dayjs(noticeRes.toDate)
      }
      if (noticeRes.fromDate) {
        noticeRes.fromDateRange = dayjs(noticeRes.fromDate)
      }
      form.setFieldsValue(noticeRes)
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  async function updateNoti() {
    // 사용자 정보 수정
    try {
      let data = form.getFieldValue()
      if (!!!data.title) return globalStore.warningNoti("제목을 입력해주세요.")
      if (!!!data.content)
        return globalStore.warningNoti("내용을 입력해주세요.")
      if (!!!data.type) return globalStore.warningNoti("타입을 선택해주세요.")
      if (data.visible === null)
        return globalStore.warningNoti("공지 여부를 선택해주세요.")
      if (!!!data.fromDate)
        return globalStore.warningNoti("공지 시작일을 선택해주세요.")
      if (!!!data.toDate)
        return globalStore.warningNoti("공지 종료일을 선택해주세요.")

      delete data.fromDateRange
      delete data.toDateRange
      if (id) {
        await noticeApi.setNotice(id, data)
      } else {
        await noticeApi.createNotice(data)
        close()
      }
      globalStore.successNoti(id ? "수정되었습니다." : "등록되었습니다.")
      fetchList()
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  async function deleteNoti() {
    try {
      await noticeApi.deleteNotice(id)
      close()
      globalStore.successNoti("삭제되었습니다.")
      fetchList()
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  useEffect(() => {
    if (id) {
      fetchNotice()
    }
  }, [id])

  return (
    <>
      <DetailContainer
        viewSiderProps={{
          title: id ? "공지사항 수정" : "공지사항 등록",
          onClose: () => {},
        }}
      >
        <div css={styles.container}>
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <Row gutter={[16, { xs: 16, sm: 24 }]}>
              {id && (
                <Col xs={24} sm={12}>
                  <Form.Item name="id" label="아이디" required>
                    <Input disabled={id} />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={12}>
                <Form.Item name="title" label="제목" required>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="content" label="내용" required>
                  <Input.TextArea
                    autoSize={false}
                    rows={10}
                    style={{
                      resize: "none",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="공지 시작일" name="fromDateRange" required>
                  <DatePicker
                    fullWidth={true}
                    showTime
                    allowClear={false}
                    onChange={(e) => {
                      form.setFieldsValue({
                        ...form.getFieldValue(),
                        fromDate: e ? e?.format("YYYY-MM-DD HH:mm:ss") : null,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="공지 종료일" name="toDateRange" required>
                  <DatePicker
                    fullWidth={true}
                    showTime
                    allowClear={false}
                    onChange={(e) => {
                      form.setFieldsValue({
                        ...form.getFieldValue(),
                        toDate: e ? e?.format("YYYY-MM-DD HH:mm:ss") : null,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Descriptions>
                  <Descriptions.Item label="타입">
                    <Form.Item name="type">
                      <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={[
                          { label: "로그인", value: "LOGIN" },
                          { label: "메인", value: "MAIN" },
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col xs={24} sm={12}>
                <Descriptions>
                  <Descriptions.Item label="공지 여부">
                    <Form.Item name="visible">
                      <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={[
                          { label: "활성", value: true },
                          { label: "비활성", value: false },
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              {id && (
                <Col xs={24}>
                  <Descriptions>
                    <Descriptions.Item label="등록일">
                      <Form.Item name="created">
                        <Input readOnly />
                      </Form.Item>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              )}
              <Col xs={24} align="right">
                {id && (
                  <Popconfirm
                    title="삭제하시겠습니까?"
                    onConfirm={deleteNoti}
                    placement="topLeft"
                  >
                    <Button style={{ marginRight: 12 }}>삭제</Button>
                  </Popconfirm>
                )}

                <Popconfirm
                  title={id ? `수정하시겠습니까?` : "등록하시겠습니까?"}
                  onConfirm={updateNoti}
                  placement="topLeft"
                >
                  <Button type="primary">{id ? "수정" : "등록"}</Button>
                </Popconfirm>
              </Col>
            </Row>
          </Form>
        </div>
      </DetailContainer>
    </>
  )
})

const styles = {
  container: {
    "& .ant-form-item": {
      marginBottom: 0,
    },
    "& .ant-divider": {
      margin: "6px 0 12px",
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 15,
    },
    "& .sub-title": {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& .ant-descriptions-row": {
      "& .ant-descriptions-item": {
        paddingBottom: 0,
      },
      "& .ant-descriptions-item-label": {
        alignItems: "center",
      },
      "& input": {
        maxWidth: 150,
        marginRight: 7,
      },
    },
  },
}
