import { makeAutoObservable } from "mobx";

import { notification } from "antd";

const initialAlertDialog = {
  title: "",
  body: "",
  confirmText: "확인",
  onConfirm: () => {},
};
const initialConfirmDialog = {
  title: "",
  body: "",
  cancelText: "취소",
  confirmText: "확인",
  onCancle: () => {},
  onConfirm: () => {},
};

const initialViewTopButton = false;

const initialGlobalNotification = [];

export function createGlobalStore(rootStore) {
  const store = makeAutoObservable({
    // obesrvables
    alertDialog: initialAlertDialog,
    confirmDialog: initialConfirmDialog,
    globalNotification: initialGlobalNotification, // 상단 Notification
    viewTopButton: initialViewTopButton,
    // functions

    alert({ body }) {
      alert(body);
    },
    confirm({ body }) {
      alert(body);
    },
    infoNoti(description, messageTitle) {
      notification.info({
        message: messageTitle || "알림",
        description,
      });
    },
    warningNoti(description, messageTitle) {
      notification.warning({
        message: messageTitle || "알림",
        description,
      });
    },
    successNoti(description, messageTitle) {
      notification.success({
        message: messageTitle || "알림",
        description,
      });
    },
    errorNoti(description, messageTitle) {
      notification.error({
        message: messageTitle || "에러 발생",
        description,
      });
    },
    setViewTopButton(boolean) {
      this.viewTopButton = boolean;
    },
  });

  return store;
}
