import manage from "@app/axios/manage"
import UploadButton from "@app/components/button/UploadButton"
import ContentContainer from "@app/components/container/ContentContainer"
import PageContainer from "@app/components/container/PageContainer"
import AgentSelect from "@app/components/select/AgentSelect"
import VirtualMappingSearch from "@app/containers/management/vitual/list/VirtualMappingSearch"
import VirtualManageTable from "@app/containers/management/vitual/list/VirtualMappingTable"
import { useMobxStores } from "@app/stores/stores"
import {
  getAccessKeys,
  getQuery,
  pushQuery,
  removeEmpty,
} from "@app/utils/router"
import PageHeader from "@components/container/HeaderContainer"
import { Button, Space } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom/dist"
import VirtualMappingManageDetail from "./VirtualMappingManageDetail"
import VirtualMappingRangeDetail from "./VirtualMappingRangeDetail"
import { DownloadOutlined } from "@ant-design/icons"
import { fileDownload } from "@app/utils/file"

export default function VirtualMappingManagePage() {
  const { globalStore, userStore } = useMobxStores()
  const [viewSiderComponent, setViewComponent] = useState(null)
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
  })
  const [virtualMappings, setVirtualMappings] = useState([])
  const [pagination, setPagination] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const query = getQuery(location)
  const [checkList, setCheckList] = useState([])
  const [loading, setLoading] = useState(false)
  const accessKeys = getAccessKeys(userStore, location)

  useEffect(() => {
    if (query.id) {
      setViewComponent(
        <VirtualMappingManageDetail
          key={query.id}
          id={query.id}
          closeDetail={closeDetail}
          fetchList={() => getVirtualMappingList()}
          accessKeys={accessKeys}
        />
      )
    } else if (query.range) {
      //대량 가상번호 관리
      setViewComponent(
        <VirtualMappingRangeDetail
          key={`range`}
          closeDetail={closeDetail}
          fetchList={() => getVirtualMappingList()}
        />
      )
    } else {
      setViewComponent(null)
    }
  }, [location.search])

  useEffect(() => {
    getVirtualMappingList()
  }, [filter])

  function getVirtualMappingList() {
    setLoading(true)
    manage
      .getVirtualMappingList({ ...filter, sort: "virtualNumber,ASC" })
      .then((res) => {
        const meta = res?.data?.meta
        setVirtualMappings(res?.data?.data)
        const pageMeta = {
          total: meta.totalCount,
          current: meta.page.pageNumber + 1,
          pageSize: meta.page.pageSize,
          totalPage: meta.totalPage,
        }
        setPagination(pageMeta)
      })
      .catch((e) =>
        globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        )
      )
      .finally(() => setLoading(false))
  }

  function openViewSider(type, id) {
    if (type === "detail") {
      delete query.add
      pushQuery({ ...query, id: id }, navigate)
    } else if (type === "range") {
      delete query.id
      pushQuery({ ...query, range: true }, navigate)
    }
  }

  function closeDetail() {
    delete query.id
    delete query.range
    pushQuery(query, navigate)
  }

  const [excelDownloading, setExcelDownloading] = useState(false)
  const [excelUploading, setExcelUploading] = useState(false)
  const [excelFormDownloading, setExcelFormDownloading] = useState(false)

  function downloadExcel() {
    if (userStore?.super && !filter?.agentId)
      return globalStore.errorNoti("사업장 선택 후 다운로드 해 주세요.")
    setExcelDownloading(true)
    manage
      .getVirtualMappingExcel(filter)
      .then((res) => {
        const resData = res.data
        const today = dayjs().format("YYYYMMDDHHmm")
        const fileTitle = "050번호_리스트_"
        fileDownload(resData, fileTitle + today + ".xlsx")
      })
      .catch((e) =>
        globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        )
      )
      .finally(() => setExcelDownloading(false))
  }

  function downloadExcelForm() {
    setExcelFormDownloading(true)
    manage
      .getVirtualMappingFormExcel()
      .then((res) => {
        const resData = res.data
        const today = dayjs().format("YYYYMMDDHHmm")
        const fileTitle = "050번호_리스트_맵핑폼"
        fileDownload(resData, fileTitle + today + ".xlsx")
      })
      .catch((e) =>
        globalStore.errorNoti(
          e?.response?.data?.meta?.userMessage || e?.message || e
        )
      )
      .finally(() => setExcelFormDownloading(false))
  }

  function uploadExcel({ onSuccess, onError, file }) {
    // 엑셀 업로드
    setExcelUploading(true)
    manage
      .uploadVirtualMappingExcel(file)
      .then((res) => {
        onSuccess()
        globalStore.successNoti(
          <>
            <div>매핑된 착신 번호: {res?.data?.data?.commitCount}</div>
            <div>매핑되지 않은 착신번호: {res?.data?.data?.invalidCount}</div>
            <div>
              허용되지 않는 착신번호 수: {res?.data?.data?.notAllowCount}
            </div>
            <div>가상번호 수: {res?.data?.data?.vitualNumberCount}</div>
          </>
        )
        getVirtualMappingList()
      })
      .catch((e) => {
        onError({ e })
      })
      .finally(() => {
        setExcelUploading(false)
      })
  }

  function onSearch(values) {
    const cleanObj = { ...values }
    if (values?.agentId === "all") delete cleanObj.agentId
    if (values?.dateRange?.[0])
      cleanObj.fromDate = dayjs(values?.dateRange[0]).format("YYYYMMDD")
    if (values?.dateRange?.[1])
      cleanObj.toDate = dayjs(values?.dateRange[1]).format("YYYYMMDD")
    if (cleanObj?.keywordType && cleanObj?.keywordType !== "")
      cleanObj[cleanObj?.keywordType] = cleanObj?.keywordType.includes("Number")
        ? cleanObj?.keyword?.replace(/-/gi, "") || ""
        : cleanObj.keyword
    delete cleanObj.dateRange
    delete cleanObj.keyword
    delete cleanObj.keywordType
    setFilter({ ...removeEmpty(cleanObj), page: 0, size: 10 })
  }

  return (
    <>
      <PageContainer viewSiderComponent={viewSiderComponent}>
        <PageHeader
          title="050번호 관리"
          subTitle="050번호 정보를 관리합니다"
          rightActions={
            <Space style={{ flexFlow: "wrap" }}>
              {userStore?.super === false && (
                <>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => downloadExcelForm()}
                    loading={excelFormDownloading}
                  >
                    맵핑 폼 다운로드
                  </Button>
                  <UploadButton
                    text="일괄맵핑 업로드"
                    customRequest={uploadExcel}
                    loading={excelUploading}
                  />
                </>
              )}
              <Button
                icon={<DownloadOutlined />}
                onClick={() => downloadExcel()}
                loading={excelDownloading}
              >
                다운로드
              </Button>
            </Space>
          }
        />
        <VirtualMappingSearch
          onSearch={onSearch}
          openViewSider={openViewSider}
          setViewComponent={setViewComponent}
          query={query}
          closeDetail={closeDetail}
          getVirtualMappingList={getVirtualMappingList}
          accessKeys={accessKeys}
          filter={filter}
        />
        <ContentContainer wrapper>
          <VirtualManageTable
            openViewSider={openViewSider}
            virtualMappings={virtualMappings}
            pagination={pagination}
            filter={filter}
            setFilter={setFilter}
            checkList={checkList}
            setCheckList={setCheckList}
            loading={loading}
          />
        </ContentContainer>
      </PageContainer>
    </>
  )
}
