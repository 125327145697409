import { useState, useRef } from "react"
import dayjs from "dayjs"
import _cloneDeep from "lodash/cloneDeep"
import { observer } from "mobx-react"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"
import DownLoadButton from "@components/button/DownLoadButton"

import RouteStatsSearch from "@containers/stats/route/RouteStatsSearch"
import RouteStatsTable from "@containers/stats/route/RouteStatsTable"

import { objectClean } from "@utils/router"
import { fileDownload } from "@utils/file"
import { useMobxStores } from "@stores/stores"

import statisticsApi from "@api/statistics"

export default observer(function RouteStatsPage() {
  const tableRef = useRef()
  const { userStore, globalStore } = useMobxStores()
  const [lastParams, setLastParams] = useState(null)
  const [loading, setLoading] = useState(false)

  function search(params) {
    const filter = _cloneDeep(params)

    if (!filter.toDate || !filter.fromDate) {
      return globalStore.warningNoti("조회날짜를 선택해주세요")
    }
    if (filter.anIsMobile === "all") delete filter.anIsMobile
    if (filter.rnIsMobile === "all") delete filter.rnIsMobile
    if (filter.byVnScope === "all") delete filter.byVnScope
    if (filter.inbound === "None") {
      delete filter.inbound
    } else {
      filter.inbound = filter.inbound.split(",")
    }
    if (filter.outbound === "None") {
      delete filter.outbound
    } else {
      filter.outbound = filter.outbound.split(",")
    }
    delete filter.dateRange

    objectClean(filter)
    setLastParams(filter)
    tableRef?.current?.fetchList(filter)
  }

  async function onDownload() {
    // 엑셀 다운로드
    setLoading(true)
    try {
      let filter = _cloneDeep(lastParams)

      if (!filter.toDate || !filter.fromDate) {
        setLoading(false)
        return globalStore.warningNoti("조회날짜를 선택해주세요")
      }

      const res = await statisticsApi.getStatisticsExcelBySuper(filter)
      const resData = res.data
      const today = dayjs().format("YYYYMMDDHHmm")
      const fileTitle = "기간사별_통계_"
      fileDownload(resData, fileTitle + today + ".xlsx")
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  return (
    <>
      <PageContainer>
        <PageHeader
          title="기간사별"
          subTitle="기간사 별 월별통계를 조회합니다."
          rightActions={
            <>
              <DownLoadButton
                onClick={onDownload}
                loading={loading}
                text="다운로드"
              />
            </>
          }
        />
        <RouteStatsSearch search={search} />
        <ContentContainer wrapper>
          <RouteStatsTable ref={tableRef} />
        </ContentContainer>
      </PageContainer>
    </>
  )
})
