/** @jsxImportSource @emotion/react */
import { Outlet } from "react-router-dom"
import { observer } from "mobx-react"

export default observer(function EmptyLayout() {
  return (
    <>
      <Outlet />
    </>
  )
})
