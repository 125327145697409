import { useState, useEffect } from "react"
import { Form, Radio, Select, Space, Row, Col } from "antd"
import dayjs from "dayjs"
import _cloneDeep from "lodash/cloneDeep"
import { observer } from "mobx-react"

import SearchForm from "@components/form/SearchForm"
import DatePicker from "@components/picker/DatePicker"
import AgentSelect from "@components/select/AgentSelect"

const initValue = {
  byDateType: "DATE",
  fromDate: dayjs().subtract(1, "month").format("YYYYMMDD"),
  toDate: dayjs().format("YYYYMMDD"),
  dateRange: [dayjs().subtract(1, "month"), dayjs()],
  dateSingle: dayjs(),
  anIsMobile: "all",
  rnIsMobile: "all",
  agentId: "all",
}

const rnanOption = [
  { label: "전체", value: "all" },
  { label: "유선", value: true },
  { label: "무선", value: false },
]

const dateOption = [
  { label: "일자별", value: "DATE" },
  { label: "월별", value: "MONTH" },
  { label: "시간별", value: "TIME" },
]

export default observer(function AgentStatsSearch(props) {
  const { search } = props
  const [form] = Form.useForm()
  const [selectDate, setSelectDate] = useState("DATE")

  function onSearch() {
    let params = form?.getFieldValue()
    search(params)
  }

  function setDateType(e) {
    // 날짜 타입 변경, 이에 맞게 날짜도 변경
    if (e !== "DATE") {
      const date = form.getFieldValue()?.dateSingle
      form.setFieldsValue({
        ...form.getFieldValue(),
        fromDate:
          e === "MONTH"
            ? parseInt(date?.format("YYYYMM01"))
            : parseInt(date?.format("YYYYMMDD")),
        toDate:
          e === "MONTH"
            ? parseInt(date?.format("YYYYMM") + date?.daysInMonth())
            : parseInt(date?.format("YYYYMMDD")),
      })
    } else {
      const date = form.getFieldValue()?.dateRange
      form.setFieldsValue({
        ...form.getFieldValue(),
        fromDate: parseInt(date?.[0]?.format("YYYYMMDD")),
        toDate: parseInt(date?.[1]?.format("YYYYMMDD")),
      })
    }
    setSelectDate(e)
  }

  useEffect(() => {
    setTimeout(() => {
      onSearch()
    }, 1000)
  }, [])

  return (
    <>
      <SearchForm onFinish={onSearch} footerButtons={true}>
        <Form form={form} initialValues={initValue}>
          <Space>
            <Form.Item name="byDateType">
              <Select
                style={{ width: 120 }}
                options={dateOption}
                onChange={setDateType}
                value={selectDate}
              />
            </Form.Item>

            {selectDate === "DATE" ? (
              <Form.Item name="dateRange">
                <DatePicker.RangePicker
                  onChange={(e) => {
                    form.setFieldsValue({
                      ...form.getFieldValue(),
                      fromDate: e?.[0]
                        ? parseInt(e[0].format("YYYYMMDD"))
                        : null,
                      toDate: e?.[1] ? parseInt(e[1].format("YYYYMMDD")) : null,
                    })
                  }}
                  placeholder={["시작일", "종료일"]}
                  format="YYYY/MM/DD"
                />
              </Form.Item>
            ) : selectDate === "MONTH" ? (
              <Form.Item name="dateSingle">
                <DatePicker.MonthPicker
                  onChange={(e) => {
                    form.setFieldsValue({
                      ...form.getFieldValue(),
                      fromDate: e ? parseInt(e?.format("YYYYMM01")) : null,
                      toDate: e
                        ? parseInt(e?.format("YYYYMM") + e?.daysInMonth())
                        : null,
                    })
                  }}
                  format="YYYY/MM"
                />
              </Form.Item>
            ) : (
              <Form.Item name="dateSingle">
                <DatePicker
                  onChange={(e) => {
                    form.setFieldsValue({
                      ...form.getFieldValue(),
                      fromDate: e ? parseInt(e?.format("YYYYMMDD")) : null,
                      toDate: e ? parseInt(e?.format("YYYYMMDD")) : null,
                    })
                  }}
                  format="YYYY/MM/DD"
                />
              </Form.Item>
            )}
          </Space>
          <Row>
            <Col xs={24}>
              <Form.Item label="사업장" name="agentId">
                <AgentSelect
                  allSearch
                  partSearch
                  allowClear
                  style={{ width: 200 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Space size={10}>
                <Form.Item name="anIsMobile" label="발신">
                  <Radio.Group options={rnanOption} />
                </Form.Item>
                <Form.Item name="rnIsMobile" label="착신">
                  <Radio.Group options={rnanOption} />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </SearchForm>
    </>
  )
})
