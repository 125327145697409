/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import jwt from "jwt-decode"
import _ from "lodash"
import { Form, Input, Checkbox, Button, Tabs } from "antd"

import { ReactComponent as BizcallLogo } from "@app/assets/svg/BizcallLogo_lg.svg"
import Notification from "@components/common/Notification"

import { useMobxStores } from "@stores/stores"

const initialValues = {
  username: null,
  password: null,
  keep: null,
}

export default function LoginPage() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { userStore, globalStore } = useMobxStores()

  const pwRef = useRef()

  const [loading, setLoading] = useState(false)
  const [loginDisabled, setLoginDisabled] = useState(true)
  const [keep, setKeep] = useState(true)
  const [noti, setNoti] = useState(false)

  function onFormFieldsChange(changeFields, allFields) {
    const requiredFields = ["username", "password"]

    let allValidated = true
    allFields.forEach((field) => {
      if (requiredFields.indexOf(field.name[0]) > -1) {
        if (_.isEmpty(field.value) || field.errors.length > 0) {
          allValidated = false
          return
        }
      }
    })
    setLoginDisabled(!allValidated)
  }

  function onTabsChange(key) {
    if (key === "SIGNUP") {
      navigate("/terms")
    }
  }

  async function login() {
    setLoading(true)
    try {
      const user = form.getFieldValue()
      await userStore.login(user.username, user.password, user.keep)
      const token = jwt(userStore?.accessToken)

      setLoading(false)
      if (token?.isRequiredAuth === "true") {
        navigate?.("/smsauth")
      } else {
        navigate("/")

        setTimeout(() => {
          let isSKTL = false
          // SKTL 사용자는 SKTL 콜로그 조회 페이지로 이동 (서비스 현황을 사용하지 않기 때문)
          userStore?.myRoutes?.forEach((my) => {
            if (
              my?.accessWeb?.fullPath?.indexOf("ext_list") > -1 ||
              my?.accessWeb?.fullPath?.indexOf("sktel") > -1
            ) {
              isSKTL = true
            }
          })
          if (isSKTL) navigate("/calllog/ext_list")
        }, 600)
      }
    } catch (e) {
      setLoading(false)
    }
  }

  async function getCurrentNoti() {
    // 공지사항 조회 및 노출
    await userStore.getCurrentNoti({
      visible: true,
      type: "LOGIN",
    })
    if (userStore?.noti.open) setNoti(true)
  }

  useEffect(() => {
    /**
     * 아이디 저장 여부
     * 아이디 저장 체크 후 로그인 한 내역이 있을 경우 해당 ID default로 설정
     **/
    const keep = localStorage.getItem("keep")?.toString()
    const keepID = localStorage.getItem("keepID")?.toString()
    if (keep === "true" && keepID) {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        username: keepID,
      })
    }
    const keepVal = keep || true
    setKeep(keepVal === "false" ? false : keepVal)
    form?.setFieldsValue({
      ...form?.getFieldsValue(),
      keep: keepVal === "false" ? false : keepVal,
    })

    getCurrentNoti()
  }, [])

  const items = [
    {
      label: "로그인",
      key: "LOGIN",
      children: (
        <>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "아이디를 입력해주세요",
              },
            ]}
          >
            <Input
              placeholder="아이디를 입력해주세요"
              size="large"
              onPressEnter={() => {
                pwRef.current.focus()
              }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "비밀번호를 입력해주세요",
              },
            ]}
          >
            <Input.Password
              ref={pwRef}
              placeholder="비밀번호를 입력해주세요"
              size="large"
              onPressEnter={() => {
                if (!loginDisabled) {
                  login()
                }
              }}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="keep" valuePropName="checked">
              <Checkbox
                checked={keep}
                onChange={(e) => {
                  setKeep(e.target.checked)
                }}
              >
                아이디 저장
              </Checkbox>
            </Form.Item>
            <a className="forgot" onClick={() => navigate("/find")}>
              아이디 & 비밀번호 찾기
            </a>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={loginDisabled}
              size="large"
              type="primary"
              block
              onClick={login}
              loading={loading}
            >
              로그인
            </Button>
          </Form.Item>
        </>
      ),
    },
    {
      label: "회원가입",
      key: "SIGNUP",
    },
  ]

  return (
    <div css={styles.container}>
      <Form
        form={form}
        initialValues={initialValues}
        onFieldsChange={onFormFieldsChange}
        scrollToFirstError
      >
        <BizcallLogo width={320} height={145} className="logo" />
        <Tabs defaultActiveKey="LOGIN" onChange={onTabsChange} items={items} />
      </Form>
      <Notification open={noti} setOpen={setNoti} />
    </div>
  )
}

const styles = {
  container: {
    "& .logo": {
      height: "30vh",
    },
    "& .forgot": {
      position: "absolute",
      right: "0",
      top: "4px",
    },
  },
}
