/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { Outlet } from "react-router-dom"
import { observer } from "mobx-react"
import { Layout } from "antd"

import { Breakpoints } from "@utils/style"
import { useMobxStores } from "@stores/stores"

import TopNavigation from "@components/common/TopNavigation"
import SideNavigation from "@components/common/SideNavigation"
import GlobalProgress from "@components/common/GlobalProgress"
import Notification from "@components/common/Notification"

const { Content } = Layout

export default observer(function MainLayout() {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints[1] })
  const [collapsed, setCollapsed] = useState(isMobile ? true : false)
  const [collapsedWidth, setCollapsedWidth] = useState(54)
  const [noti, setNoti] = useState(false)
  const { userStore } = useMobxStores()

  const contentMarginLeft = collapsed
    ? collapsedWidth
    : collapsedWidth === 0
    ? 0
    : 200

  async function getCurrentNoti() {
    await userStore.getCurrentNoti({
      visible: true,
      type: "MAIN",
    })
    if (userStore?.noti.open) setNoti(true)
  }

  useEffect(() => {
    getCurrentNoti()
  }, [])

  return (
    <>
      <div
        css={[
          styles.contentContainer,
          {
            paddingLeft: contentMarginLeft,
          },
        ]}
      >
        <Content>
          {userStore?.user ? <Outlet></Outlet> : <GlobalProgress />}
        </Content>
      </div>
      <SideNavigation
        collapsed={collapsed}
        setCollapsed={(collapsed) => {
          setCollapsed(collapsed)
        }}
        collapsedWidth={collapsedWidth}
        setCollapsedWidth={(collapsedWidth) => {
          setCollapsedWidth(collapsedWidth)
        }}
      />
      <TopNavigation
        collapsed={collapsed}
        setCollapsed={(collapsed) => {
          setCollapsed(collapsed)
        }}
        collapsedWidth={collapsedWidth}
        containerStyle={styles.headerContainer}
      />
      <Notification open={noti} setOpen={setNoti} />
    </>
  )
})

const styles = {
  contentContainer: {
    paddingTop: 48,
    transition: "all 0.1s ease-in-out",
    backgroundColor: "#f7f7f7",
    height: "100vh",
  },
  headerContainer: {
    paddingTop: 140,
  },
  footerContainer: {
    marginTop: 172,
    paddingBottom: 40,
  },
}
