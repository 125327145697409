import { useEffect } from "react"
import { Form, Input, Radio, Button, Select, Space } from "antd"

import AddButton from "@components/button/AddButton"
import SearchForm from "@components/form/SearchForm"
import DatePicker from "@components/picker/DatePicker"

const initValue = {
  fromDate: null,
  toDate: null,
  serviceYN: true,
  keywordType: "agentName",
  keyword: null,
  sort: "registDate,desc",
}

export default function AgentStatusSearch(props) {
  const { search, openViewSider } = props
  const [form] = Form.useForm()

  function onSearch() {
    const params = form.getFieldValue()
    search(params)
  }

  useEffect(() => {
    onSearch()
  }, [])

  return (
    <>
      <SearchForm
        onFinish={onSearch}
        footerButtons={
          <AddButton
            onClick={() => openViewSider("add", null)}
            text="사업장 등록"
          />
        }
      >
        <Form form={form} initialValues={initValue}>
          <Form.Item label="조회 날짜">
            <DatePicker.RangePicker
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  fromDate: e?.[0] ? parseInt(e[0].format("YYYYMMDD")) : null,
                  toDate: e?.[1] ? parseInt(e[1].format("YYYYMMDD")) : null,
                })
              }}
              placeholder={["시작일", "종료일"]}
              format="YYYY/MM/DD"
            />
          </Form.Item>
          <Form.Item name="serviceYN" label="서비스 여부">
            <Radio.Group
              options={[
                { label: "전체", value: "all" },
                { label: "서비스 중", value: true },
                { label: "서비스 종료", value: false },
              ]}
            ></Radio.Group>
          </Form.Item>
          <Space>
            <Form.Item name="keywordType">
              <Select
                options={[
                  { label: "사업장명", value: "agentName" },
                  { label: "대표번호", value: "phoneNumber" },
                ]}
              />
            </Form.Item>
            <Form.Item name="keyword">
              <Input />
            </Form.Item>
          </Space>
        </Form>
      </SearchForm>
    </>
  )
}
