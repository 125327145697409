/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react"
import manage from "@app/axios/manage"
import { useMobxStores } from "@app/stores/stores"
import { Modal, Table } from "antd"
import { useState } from "react"
import { useEffect } from "react"
import { MediaQuery } from "@app/utils/style"

export default function VirtualNumberHistoryModal(props) {
  const { virtualNumber, setVirtualNumber } = props
  const [filter, setFilter] = useState({ page: 0, size: 10 })
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState()
  const { globalStore } = useMobxStores()

  useEffect(() => {
    if (virtualNumber) {
      const params = { ...filter, sort: "registDatetime,ASC" }
      setLoading(true)
      manage
        .getVirtualNumHistory(virtualNumber, params)
        .then((res) => {
          setDataSource(res?.data?.data)
          const meta = res?.data?.meta
          const pageMeta = {
            total: meta.totalCount,
            current: meta.page.pageNumber + 1,
            pageSize: meta.page.pageSize,
            totalPage: meta.totalPage,
          }
          setPagination(pageMeta)
        })
        .catch((e) =>
          globalStore.errorNoti(
            e?.response?.data?.meta?.userMessage || e?.message || e
          )
        )
        .finally((e) => setLoading(false))
    }
  }, [virtualNumber, filter])

  const columns = [
    {
      key: "realNumber",
      dataIndex: "realNumber",
      title: "착신번호",
      align: "center",
      width: "10%",
      render: (text, record) => text || "-",
    },
    {
      key: "colorRing.description",
      dataIndex: ["colorRing", "description"],
      title: "컬러링",
      align: "center",
      width: "25%",
      render: (text, record) => text || "-",
      ellipsis: true,
    },
    {
      key: "informRing.description",
      dataIndex: ["informRing", "description"],
      title: "알림멘트",
      align: "center",
      width: "25%",
      render: (text, record) => text || "-",
      ellipsis: true,
    },
    {
      key: "memo",
      dataIndex: "memo",
      title: "메모",
      align: "center",
      width: "15%",
      render: (text, record) => text || "-",
      ellipsis: true,
    },
    {
      key: "registDatetime",
      dataIndex: "registDatetime",
      title: "등록일",
      align: "center",
      width: "15%",
      render: (text, record) => text || "-",
    },
    {
      key: "action",
      dataIndex: "action",
      title: "이력",
      align: "center",
      width: "10%",
      render: (text, record) => {
        const returnText = {
          update: "수정",
          "vn cancel delete": "해제",
          "update vn cancel": "해제",
          EXPIRE_UPDATE: "자동해제",
          a_update: "수정",
        }
        return returnText[text] || "-"
      },
    },
  ]
  return (
    <Modal
      title={
        <>
          맵핑이력 : <span style={{ fontWeight: "bold" }}>{virtualNumber}</span>
        </>
      }
      open={virtualNumber}
      onCancel={() => {
        setVirtualNumber(null)
        setDataSource([])
        setFilter({ page: 0, size: 10 })
      }}
      width={1500}
      footer={false}
      bodyStyle={{ padding: "16px" }}
      css={styles.modalWrap}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={pagination}
        onChange={(e) => {
          const page = {
            ...filter,
            page: e.current - 1,
            size: e.pageSize,
          }
          setFilter(page)
        }}
        rowKey={(e) => e?.registDatetime}
        scroll={{ x: 950, y: 550 }}
        size={"small"}
      />
    </Modal>
  )
}

const styles = {
  modalWrap: {
    "& .ant-table-cell": {
      padding: "16px 5px",
    },
    [MediaQuery[0]]: {
      "& .ant-modal-header": {
        padding: "10px",
        "& .ant-modal-title": {
          fontSize: "13px",
        },
      },
      "& .ant-modal-body": {
        padding: "10px",
        fontSize: "13px",
      },
    },
  },
}
