import blackWhite from "@app/axios/blackWhite"
import ContentContainer from "@app/components/container/ContentContainer"
import PageContainer from "@app/components/container/PageContainer"
import BlackWhiteListSearch from "@app/containers/management/blackWhite/BlackWhiteListSearch"
import BlackWhiteListTable from "@app/containers/management/blackWhite/BlackWhiteListTable"
import { useMobxStores } from "@app/stores/stores"
import {
  getAccessKeys,
  getQuery,
  objectClean,
  pushQuery,
} from "@app/utils/router"
import PageHeader from "@components/container/HeaderContainer"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom/dist"
import BlackWhiteListDetail from "./BlackWhiteListDetail"

export default function BlackWhiteListPage() {
  const { globalStore, userStore } = useMobxStores()
  const [viewSiderComponent, setViewComponent] = useState(null)
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
  })
  const [virtualMappings, setVirtualMappings] = useState([])
  const [pagination, setPagination] = useState()
  const location = useLocation()
  const navigate = useNavigate()
  const query = getQuery(location)
  const [checkList, setCheckList] = useState([])
  const [loading, setLoading] = useState(false)
  const accessKeys = getAccessKeys(userStore, location)

  useEffect(() => {
    if (query.agentId) {
      setViewComponent(
        <BlackWhiteListDetail
          key={query.agentId}
          id={query.agentId}
          closeDetail={closeDetail}
          fetchList={() => getBlackWhiteList()}
          accessKeys={accessKeys}
          listType={query.listType}
          status={query?.status}
        />
      )
    } else if (query.add) {
      //대량 가상번호 관리
      setViewComponent(
        <BlackWhiteListDetail
          key={`add`}
          closeDetail={closeDetail}
          fetchList={() => getBlackWhiteList()}
        />
      )
    } else {
      setViewComponent(null)
    }
  }, [location.search])

  useEffect(() => {
    getBlackWhiteList()
  }, [filter])

  function getBlackWhiteList() {
    if (!filter) return
    setLoading(true)
    if (userStore?.super) {
      blackWhite
        .getBlackWhiteList({ ...filter, sort: "id,ASC" })
        .then((res) => {
          const meta = res?.data?.meta
          setVirtualMappings(res?.data?.data)
          const pageMeta = {
            total: meta.totalCount,
            current: meta.page.pageNumber + 1,
            pageSize: meta.page.pageSize,
            totalPage: meta.totalPage,
          }
          setPagination(pageMeta)
        })
        .catch((e) =>
          globalStore.errorNoti(
            e?.response?.data?.meta?.userMessage || e?.message || e
          )
        )
        .finally(() => setLoading(false))
    } else if (userStore?.user) {
      delete filter?.page
      delete filter?.size
      blackWhite
        .getBlackWhiteListNormal({ agentId: userStore?.user?.agent?.id })
        .then((res) => {
          setVirtualMappings([res?.data?.data])
          const pageMeta = {
            total: 1,
            current: 1,
            pageSize: 10,
            totalPage: 1,
          }
          setPagination(pageMeta)
        })
        .catch((e) =>
          globalStore.errorNoti(
            e?.response?.data?.meta?.userMessage || e?.message || e
          )
        )
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }

  function openViewSider(type, id, listType, status) {
    if (type === "detail") {
      delete query.add
      pushQuery(
        { ...query, agentId: id, listType: listType, status: status },
        navigate
      )
    } else if (type === "add") {
      delete query.id
      pushQuery({ ...query, add: true }, navigate)
    }
  }

  function onSearch(values) {
    const cleanObj = objectClean(values)
    cleanObj.page = 0
    cleanObj.size = 10
    if (cleanObj.agentId === "all") delete cleanObj.agentId
    if (cleanObj.blackWhite === "all") delete cleanObj.blackWhite
    if (userStore?.user && !userStore?.super) {
      cleanObj.agentId = userStore?.user?.agent?.id
    }
    setFilter(cleanObj)
  }

  function closeDetail() {
    delete query.agentId
    delete query.listType
    delete query.status
    pushQuery(query, navigate)
  }

  return (
    <>
      <PageContainer viewSiderComponent={viewSiderComponent}>
        <PageHeader
          title="블랙/화이트 관리"
          subTitle="착신번호에 대한 블랙/화이트 리스트를 관리 합니다."
        />
        <BlackWhiteListSearch
          onSearch={onSearch}
          openViewSider={openViewSider}
          setViewComponent={setViewComponent}
          query={query}
          closeDetail={closeDetail}
          fetchList={getBlackWhiteList}
          accessKeys={accessKeys}
        />
        <ContentContainer wrapper>
          <BlackWhiteListTable
            openViewSider={openViewSider}
            virtualMappings={virtualMappings}
            pagination={pagination}
            filter={filter}
            setFilter={setFilter}
            checkList={checkList}
            setCheckList={setCheckList}
            loading={loading}
            userStore={userStore}
          />
        </ContentContainer>
      </PageContainer>
    </>
  )
}
