import { useEffect } from "react"
import { Form, Input, Select, Space } from "antd"
import dayjs from "dayjs"
import _cloneDeep from "lodash/cloneDeep"
import { observer } from "mobx-react"

import SearchForm from "@components/form/SearchForm"
import DatePicker from "@components/picker/DatePicker"
import AgentSelect from "@components/select/AgentSelect"

const initValue = {
  fromDate: dayjs().subtract(1, "day").format("YYYYMMDD"),
  toDate: dayjs().format("YYYYMMDD"),
  dateRange: [dayjs().subtract(1, "day"), dayjs()],
  agentId: "all",
  keywordType: "vn",
  keyword: null,
  sort: "incomeDateTime,desc",
}

export default observer(function AuthLogSearch(props) {
  const { search } = props
  const [form] = Form.useForm()

  function onSearch() {
    let params = form?.getFieldValue()
    search(params)
  }

  useEffect(() => {
    onSearch()
  }, [])

  return (
    <>
      <SearchForm onFinish={onSearch} footerButtons={true}>
        <Form form={form} initialValues={initValue}>
          <Form.Item label="조회 날짜" name="dateRange">
            <DatePicker.RangePicker
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  fromDate: e?.[0] ? parseInt(e[0].format("YYYYMMDD")) : null,
                  toDate: e?.[1] ? parseInt(e[1].format("YYYYMMDD")) : null,
                })
              }}
              placeholder={["시작일", "종료일"]}
              format="YYYY/MM/DD"
            />
          </Form.Item>
          <Form.Item name="agentId" label="사업장">
            <AgentSelect allSearch allowClear style={{ width: 200 }} />
          </Form.Item>
          <Form.Item className="mb-0">
            <Space>
              <Form.Item name="keywordType">
                <Select
                  options={[
                    { label: "가상번호", value: "vn" },
                    { label: "착신번호", value: "rn" },
                    { label: "발신번호", value: "an" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="keyword">
                <Input />
              </Form.Item>
            </Space>
          </Form.Item>
        </Form>
      </SearchForm>
    </>
  )
})
