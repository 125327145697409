import { useState, useEffect } from "react"
import { Select } from "antd"
import { observer } from "mobx-react"

import agentApi from "@api/agent"

export default observer(function AgentSelect(props) {
  const { allSearch, partSearch, value, style, allowClear, params } = props
  const [agents, setAgents] = useState([])
  const [selectValue, setSelectValue] = useState()

  async function getAgents() {
    try {
      const agentProm = await agentApi.getAgents(params)
      const agentRes = agentProm?.data?.data?.map((e) => {
        return {
          label: e.name,
          value: e.id,
        }
      })
      if (partSearch) {
        agentRes?.unshift({
          label: "사업자별",
          value: "byAgent",
        })
      }
      if (allSearch) {
        agentRes?.unshift({
          label: "전체",
          value: "all",
        })
      }
      // onChange(value || (allSearch && "all") || null)
      setAgents(agentRes)
    } catch (e) {
      console.log(e?.response?.data?.meta?.userMessage || e)
    }
  }

  function onChange(value) {
    setSelectValue(value)
    const { onChange } = props
    if (onChange) onChange(value)
  }

  useEffect(() => {
    getAgents()
  }, [])

  useEffect(() => {
    if (value) setSelectValue(value)
  }, [value])

  return (
    <Select
      style={style}
      showSearch
      dropdownMatchSelectWidth={false}
      filterOption={(input, option) => {
        return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }}
      value={selectValue}
      options={agents}
      onChange={onChange}
      allowClear={allowClear}
    />
  )
})
