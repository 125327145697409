import { useState, forwardRef, useImperativeHandle } from "react"
import { ConfigProvider, Empty } from "antd"
import dayjs from "dayjs"

import ResponsiveTable from "@components/table/ResponsiveTable"

import { commaOfNumber, phoneFormatter } from "@utils/format"
import { useMobxStores } from "@stores/stores"

import agentApi from "@api/agent"

export default forwardRef(function AgentStatusTable(props, ref) {
  const { lastParams, setLastParams, openViewSider } = props
  const { globalStore } = useMobxStores()
  const [agentList, setAgentList] = useState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    // 페이징처리
    total: 0,
    current: 1,
    pageSize: 10,
  })

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchAgentList({
        ...params,
        page: params?.page || 0,
        size: params?.size || pagination.pageSize,
      })
    },
  }))

  // 사업장 리스트
  async function fetchAgentList(params) {
    setLoading(true)
    try {
      const agentProm = await agentApi.getAgentsContains(params)
      setAgentList(agentProm?.data?.data)
      const meta = agentProm?.data?.meta
      const pageMeta = {
        total: meta.totalCount,
        current: meta.page.pageNumber + 1,
        pageSize: meta.page.pageSize,
        totalPage: meta.totalPage,
      }
      setPagination(pageMeta)
      setLoading(false)
      setLastParams({
        ...params,
        page: meta.page.pageNumber || 0,
        size: meta.page.pageSize || 10,
      })
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "사업장명",
      align: "center",
      className: "selectable",
      onCell: (record) => ({
        onClick: () => openViewSider("detail", record.id),
      }),
    },
    {
      key: "memo",
      dataIndex: "memo",
      title: "메모",
      align: "center",
      width: 400,
      render(text) {
        return text || "-"
      },
    },
    {
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      title: "대표번호",
      align: "center",
      render(text) {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "virtualNumberCount",
      dataIndex: ["agentServiceStatus", "totalVirtualNumberCount"],
      title: "전체 가상번호수",
      align: "center",
      render(text) {
        return commaOfNumber(text || 0) + "건"
      },
    },
    {
      key: "virtualNumberCount",
      dataIndex: ["agentServiceStatus", "usingVirtualNumberCount"],
      title: "할당 가상번호수",
      align: "center",
      render(text) {
        return commaOfNumber(text || 0) + "건"
      },
    },
    {
      key: "colorRingCount",
      dataIndex: ["agentServiceStatus", "colorRingCount"],
      title: "컬러링수",
      align: "center",
      render(text) {
        return commaOfNumber(text || 0) + "건"
      },
    },
    {
      key: "informRingCount",
      dataIndex: ["agentServiceStatus", "informRingCount"],
      title: "알림멘트수",
      align: "center",
      render(text) {
        return commaOfNumber(text || 0) + "건"
      },
    },
    {
      key: "recordingCount",
      dataIndex: ["agentServiceStatus", "recordingCount"],
      title: "레코딩수",
      align: "center",
      render(text) {
        return commaOfNumber(text || 0) + "건"
      },
    },
    {
      key: "serviceYN",
      title: "서비스여부",
      align: "center",
      render(_, record) {
        const service = record?.service
        return service?.serviceYN === true || service?.serviceYN === false
          ? !service?.serviceYN
            ? "비활성화"
            : "활성화"
          : "-"
      },
    },
    {
      key: "acYN",
      title: "알림서비스여부",
      align: "center",
      render(_, record) {
        const service = record?.service
        return service?.acYN === true || service?.acYN === false
          ? !service?.acYN
            ? "비활성화"
            : service?.informRingCount
            ? commaOfNumber(service?.informRingCount || 0) + "건"
            : "활성화"
          : "-"
      },
    },
    {
      key: "mappingLimit",
      dataIndex: ["service", "mappingLimit"],
      title: "맵핑제한수",
      align: "center",
      render(text) {
        return commaOfNumber(text || 0) + "건"
      },
    },
    {
      key: "blackAndWhite",
      dataIndex: ["service", "blackAndWhite"],
      title: "BnW",
      align: "center",
      render(text) {
        return text ? text : "-"
      },
    },
    {
      key: "registDate",
      dataIndex: "registDate",
      title: "등록일",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD") : "-"
      },
    },
  ]
  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="검색결과가 없습니다." />}
    >
      <p style={{ fontWeight: "bold" }}>
        사업장 수 : {commaOfNumber(pagination?.total)}개
      </p>
      <ResponsiveTable
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={agentList}
        pagination={pagination}
        onChange={(e) => {
          const filter = {
            ...lastParams,
            page: e.current - 1,
            size: e.pageSize,
          }
          fetchAgentList(filter)
        }}
        scroll={{ x: 1730 }}
        loading={loading}
      />
    </ConfigProvider>
  )
})
