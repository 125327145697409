/** @jsxImportSource @emotion/react */
import { useEffect, useState, forwardRef, useImperativeHandle } from "react"
import { ConfigProvider, Table, Empty, Space } from "antd"
import dayjs from "dayjs"

import { commaOfNumber } from "@utils/format"
import { useMobxStores } from "@stores/stores"
import { useIsMobile } from "@utils/style"

import statisticsApi from "@api/statistics"

export default forwardRef(function SktlStatsSearch(props, ref) {
  const { byMinute } = props
  const { userStore, globalStore } = useMobxStores()
  const isMobile = useIsMobile()

  const [stats, setStats] = useState([])
  const [total, setTotal] = useState()
  const [loading, setLoading] = useState(false)

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchStatsList({
        ...params,
      })
    },
  }))

  useEffect(() => {
    if (stats) fetchStatsTotal(stats)
  }, [byMinute])

  // SKTL 통계 리스트
  async function fetchStatsList(params) {
    setLoading(true)
    try {
      const statsProm = userStore?.super
        ? await statisticsApi.getStatisticsSktlBySuper(params)
        : await statisticsApi.getStatisticsSktlByUser(params)
      const statsRes = statsProm?.data?.data?.map((stat, i) => {
        return {
          ...stat,
          key: i,
        }
      })
      fetchStatsTotal(statsRes)
      setStats(statsRes)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  // 통계 합계 조회
  function fetchStatsTotal(statsRes) {
    let totalCallingDuration = 0
    let totalCalledDuration = 0
    let totalCallCount = 0
    statsRes?.forEach(({ callingDuration, calledDuration, callCount }) => {
      totalCallingDuration += callingDuration
      totalCalledDuration += calledDuration
      totalCallCount += callCount
    })
    setTotal({
      callingDuration: byMinute
        ? Math.floor(totalCallingDuration / 60)
        : totalCallingDuration,
      calledDuration: byMinute
        ? Math.floor(totalCalledDuration / 60)
        : totalCalledDuration,
      callCount: totalCallCount,
    })
  }

  const columns = [
    {
      key: "agentName",
      dataIndex: "agentName",
      title: "사업자",
      align: "center",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "unitDate",
      dataIndex: "unitDate",
      title: "일",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY년 MM월 DD일") : "-"
      },
    },
    {
      key: "callingDuration",
      dataIndex: "callingDuration",
      title: byMinute ? "통화시간(분)" : "통화시간(초)",
      align: "center",
      sorter: (a, b) => a.callingDuration - b.callingDuration,
      render(text) {
        return text ? commaOfNumber(Math.floor(byMinute ? text / 60 : text)) : 0
      },
    },
    {
      key: "calledDuration",
      dataIndex: "calledDuration",
      title: byMinute ? "착신 통화시간(분)" : "착신 통화시간(초)",
      align: "center",
      sorter: (a, b) => a.calledDuration - b.calledDuration,
      render(text) {
        return text ? commaOfNumber(Math.floor(byMinute ? text / 60 : text)) : 0
      },
    },
    {
      key: "callCount",
      dataIndex: "callCount",
      title: "건수",
      align: "center",
      sorter: (a, b) => a.callCount - b.callCount,
      render(text) {
        return commaOfNumber(text || 0)
      },
    },
  ]

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="검색결과가 없습니다." />}
    >
      {isMobile && (
        <Space style={{ fontWeight: "bold" }}>
          <span>
            통화시간{byMinute ? "(분)" : "(초)"} :
            {commaOfNumber(total?.callingDuration)}
          </span>
          <span> 건수 :{commaOfNumber(total?.callCount)}</span>
        </Space>
      )}
      <Table
        rowKey={(record) => record.key}
        columns={columns.filter((column) => {
          return column.isView || column.isView === undefined
        })}
        dataSource={stats}
        pagination={false}
        loading={loading}
        scroll={{ x: 800, y: 10000000 }}
        summary={() => {
          return (
            <Table.Summary fixed="top">
              {!isMobile && stats?.length > 0 && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} />
                  <Table.Summary.Cell index={1}>합계</Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    {commaOfNumber(total.callingDuration)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    {commaOfNumber(total.calledDuration)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    {commaOfNumber(total.callCount)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </Table.Summary>
          )
        }}
      />
    </ConfigProvider>
  )
})
