import { useState, forwardRef, useImperativeHandle } from "react"
import { ConfigProvider, Empty, Table } from "antd"
import dayjs from "dayjs"

import {
  commaOfNumber,
  phoneFormatter,
  virtualNumberFormatter,
} from "@utils/format"
import { useMobxStores } from "@stores/stores"
import { useIsMobile } from "@utils/style"

import SktlCalllogTableMobile from "./SktlCalllogTableMobile"

import logApi from "@api/log"

export default forwardRef(function AuthlogTable(props, ref) {
  const { lastParams } = props
  const { userStore, globalStore } = useMobxStores()
  const [logList, setLogList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    // 페이징처리
    total: 0,
    current: 1,
    pageSize: 10,
  })

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchLogList({
        ...params,
        page: 0,
        size: pagination.pageSize,
      })
    },
  }))

  // sktl 콜로그 리스트
  async function fetchLogList(params) {
    setLoading(true)
    try {
      const logProm = userStore?.super
        ? await logApi.getSktlCallLogsBySuper(params)
        : await logApi.getSktlCallLogsByUser(params)
      setLogList(logProm?.data?.data)
      const meta = logProm?.data?.meta
      const pageMeta = {
        total: meta.totalCount,
        current: meta.page.pageNumber + 1,
        pageSize: meta.page.pageSize,
        totalPage: meta.totalPage,
      }
      if (meta.page.pageNumber === 0) globalStore.setViewTopButton(false)
      setPagination(pageMeta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  // 모바일 화면 확대,축소 변경
  function setExpend(viewData, i) {
    setLogList(
      viewData.map((e, index) => {
        if (index === i) e.expand = !e.expand
        return e
      })
    )
  }

  const columns = [
    {
      key: "agentName",
      dataIndex: ["agent", "name"],
      title: "업체명",
      align: "center",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "fromId",
      dataIndex: "fromId",
      title: "발신번호",
      align: "center",
      render(text) {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "dnis",
      dataIndex: "dnis",
      title: "가상번호",
      align: "center",
      render(text) {
        return text ? virtualNumberFormatter(text) : "-"
      },
    },
    {
      key: "toId",
      dataIndex: "toId",
      title: "착신번호",
      align: "center",
      render(text) {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "startTime",
      dataIndex: "startTime",
      title: "호 시작 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "serviceStartTime",
      dataIndex: "serviceStartTime",
      title: "호 연결 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "endTime",
      dataIndex: "endTime",
      title: "호 종료 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "serviceDuration",
      dataIndex: "serviceDuration",
      title: "통화시간(초)",
      align: "center",
      render(text) {
        return commaOfNumber(text || 0)
      },
    },
    {
      key: "cause",
      dataIndex: "cause",
      title: "CAUSE",
      align: "center",
    },
  ]

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="검색결과가 없습니다." />}
    >
      <p style={{ fontWeight: "bold" }}>
        콜로그 수 : {commaOfNumber(pagination?.total)}건
      </p>
      {useIsMobile() ? (
        <SktlCalllogTableMobile
          dataSource={logList}
          loading={loading}
          pagination={pagination}
          onChange={(e) => {
            const filter = {
              ...lastParams,
              page: e.current - 1,
              size: e.pageSize,
            }
            fetchLogList(filter)
          }}
        />
      ) : (
        <Table
          rowKey={(record) => record.sequence}
          columns={columns}
          dataSource={logList}
          pagination={pagination}
          onChange={(e) => {
            const filter = {
              ...lastParams,
              page: e.current - 1,
              size: e.pageSize,
            }
            fetchLogList(filter)
          }}
          loading={loading}
        />
      )}
    </ConfigProvider>
  )
})
