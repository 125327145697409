/** @jsxImportSource @emotion/react */
import React from "react"
import PropTypes from "prop-types"

import { Space } from "antd"
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs"
import generatePicker from "antd/lib/date-picker/generatePicker"
import "antd/lib/date-picker/style/index"

const AntdDatePicker = generatePicker(dayjsGenerateConfig)

DatePicker.propTypes = {
  formLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
}

DatePicker.defaultProps = {
  fullWidth: false,
}

function DatePicker(props) {
  const { formLabel, fullWidth } = props

  let style = {}
  if (fullWidth) style = { width: "100%" }

  const picker = (
    <>
      {formLabel && <span>{formLabel}</span>}
      <AntdDatePicker style={style} {...props} />
    </>
  )

  if (fullWidth) return picker

  return <Space size={16}>{picker}</Space>
}

function RangePicker(props) {
  const { fullWidth } = props

  let style = {}
  if (fullWidth) style = { width: "100%" }

  return <AntdDatePicker.RangePicker style={style} {...props} />
}

DatePicker.RangePicker = RangePicker

function TimePicker(props) {
  const { fullWidth } = props

  let style = {}
  if (fullWidth) style = { width: "100%" }

  return <AntdDatePicker.TimePicker style={style} {...props} />
}

DatePicker.TimePicker = TimePicker

function MonthPicker(props) {
  const { fullWidth } = props

  let style = {}
  if (fullWidth) style = { width: "100%" }

  return <AntdDatePicker.MonthPicker style={style} {...props} />
}

DatePicker.MonthPicker = MonthPicker

export default DatePicker
