/** @jsxImportSource @emotion/react */
import { useState, forwardRef, useImperativeHandle } from "react"
import { ConfigProvider, Table, Empty, Space } from "antd"
import dayjs from "dayjs"

import { commaOfNumber } from "@utils/format"
import { useMobxStores } from "@stores/stores"
import { useIsMobile } from "@utils/style"

import statisticsApi from "@api/statistics"

export default forwardRef(function AgentStatsTable(props, ref) {
  const { globalStore, userStore } = useMobxStores()
  const isMobile = useIsMobile()

  const [stats, setStats] = useState([])
  const [total, setTotal] = useState()
  const [loading, setLoading] = useState(false)
  const [isAll, setIsAll] = useState(false)

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchStatsList({
        ...params,
      })
    },
  }))

  // 사업장별 통계 리스트
  async function fetchStatsList(params) {
    setLoading(true)
    try {
      const statsProm =
        params?.byDateType === "MONTH"
          ? await statisticsApi.getStatisticsAgentMonthBySuper(params)
          : params?.byDateType === "DATE"
          ? await statisticsApi.getStatisticsAgentDayBySuper(params)
          : await statisticsApi.getStatisticsAgentHourBySuper(params)
      const stats = statsProm?.data?.data?.map((e, i) => {
        e.key = i
        return e
      })
      setIsAll(params.agentId || params.byAgent ? false : true)
      fetchStatsTotal(stats)
      setStats(stats)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  // 통계 합계 조회
  function fetchStatsTotal(statsRes) {
    let totalDuration = 0
    let totalOutDuration = 0
    let totalCallCount = 0
    statsRes.forEach(({ duration, outDuration, callCount }) => {
      totalDuration += duration
      totalOutDuration += outDuration
      totalCallCount += callCount
    })
    setTotal({
      duration: Math.floor(totalDuration / 60),
      outDuration: Math.floor(totalOutDuration / 60),
      callCount: totalCallCount,
    })
  }

  const columns = [
    {
      key: "agentName",
      dataIndex: "agentName",
      title: "사업자",
      align: "center",
      isView: isAll ? false : true,
      render(text) {
        return text || (isAll ? "전체" : "")
      },
    },
    {
      key: "unitDate",
      dataIndex: "unitDate",
      title: "일",
      align: "center",
      isView: true,
      render(text) {
        return text
          ? dayjs(text).format(
              text.length === 6 ? "YYYY년 MM월" : "YYYY년 MM월 DD일"
            )
          : "-"
      },
    },
    {
      key: "duration",
      dataIndex: "duration",
      title: "통화시간(분)",
      align: "center",
      isView: true,
      sorter: (a, b) => a.duration - b.duration,
      render(text) {
        return text ? commaOfNumber(Math.floor(text / 60)) : 0
      },
    },
    {
      key: "outDuration",
      dataIndex: "outDuration",
      title: "착신 통화시간(분)",
      align: "center",
      isView: true,
      sorter: (a, b) => a.outDuration - b.outDuration,
      render(text) {
        return text ? commaOfNumber(Math.floor(text / 60)) : 0
      },
    },
    {
      key: "callCount",
      dataIndex: "callCount",
      title: "건수",
      align: "center",
      isView: true,
      sorter: (a, b) => a.callCount - b.callCount,
      render(text) {
        return commaOfNumber(text || 0)
      },
    },
  ]

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="검색결과가 없습니다." />}
    >
      {isMobile && (
        <Space style={{ fontWeight: "bold" }}>
          <span>통화시간(분) : {commaOfNumber(total?.duration)}</span>
          <span> 건수 : {commaOfNumber(total?.callCount)}</span>
        </Space>
      )}
      <Table
        rowKey={(record) => record.key}
        columns={columns.filter((column) => {
          return column.isView || column.isView === undefined
        })}
        dataSource={stats}
        pagination={false}
        loading={loading}
        scroll={{ x: 800, y: 10000000 }}
        summary={() => {
          return (
            <Table.Summary fixed="top">
              {!isMobile && stats?.length > 0 && (
                <Table.Summary.Row>
                  {!isAll && <Table.Summary.Cell index={0} />}
                  <Table.Summary.Cell index={!isAll ? 1 : 0}>
                    합계
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={!isAll ? 2 : 1}>
                    {commaOfNumber(total.duration)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={!isAll ? 3 : 2}>
                    {commaOfNumber(total.outDuration)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={!isAll ? 4 : 3}>
                    {commaOfNumber(total.callCount)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </Table.Summary>
          )
        }}
      />
    </ConfigProvider>
  )
})
