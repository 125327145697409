/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Form, Button, Row, Col, Card, Popconfirm } from "antd"
import { observer } from "mobx-react"

import { useMobxStores } from "@stores/stores"
import { MediaQuery } from "@utils/style"
import { regExpPhoneNumber } from "@utils/validation"

import PhoneNumberInput from "@components/input/PhoneNumberInput"
import OnlyNumberInput from "@components/input/OnlyNumberInput"
import Timer from "@components/common/Timer"

import userApi from "@api/user"
import smspi from "@api/sms"

const initialValues = {
  phoneNumber: null,
  authNumber: null,
}

export default observer(function SmsAuthPage() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { userStore, globalStore } = useMobxStores()
  const [time, setTime] = useState(null)

  // 전화번호 인증 요청
  async function getAuthByPhone() {
    try {
      const phoneNumber = form?.getFieldValue()?.phoneNumber?.replace(/\-/g, "")
      if (!phoneNumber || !regExpPhoneNumber.test(phoneNumber)) {
        return globalStore.warningNoti("핸드폰 번호를 확인해주세요.")
      }

      const authProm = await smspi.getAuthNumber(phoneNumber)
      setTime(authProm.data?.data?.expireSec)
      globalStore.successNoti("인증번호를 전송하였습니다.")
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  // 전화번호 인증 확인
  async function setAuthByPhone() {
    try {
      const value = form?.getFieldValue()
      const params = {
        authNumber: value.authNumber,
        phoneNumber: value.phoneNumber?.replace(/\-/g, ""),
      }
      await smspi.setAuthNumber(params)
      setTime(null)
      navigate(userStore?.myRoutes?.[0]?.accessWeb?.fullPath || "/")
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  async function logout() {
    // 로그아웃
    await userStore?.logout()
    navigate("/login")
  }

  return (
    <div css={styles.container}>
      <Form form={form} initialValues={initialValues}>
        <Card>
          <Col className="title">SMS 인증</Col>
          <p>SMS 인증이 필요합니다. SMS 인증을 진행해 주세요.</p>
          <Form.Item
            name="phoneNumber"
            label="핸드폰 번호"
            rules={[
              {
                required: true,
                message: "핸드폰 번호를 입력해주세요",
              },
            ]}
          >
            <PhoneNumberInput
              placeholder="핸드폰 번호를 입력해주세요."
              addonAfter={
                <>
                  {(time && (
                    <Timer
                      mm={time / 60}
                      ss={time % 60}
                      onFinish={() => {
                        setTime(null)
                      }}
                    />
                  )) || (
                    <Button type="text" onClick={getAuthByPhone}>
                      전송
                    </Button>
                  )}
                </>
              }
            />
          </Form.Item>
          <Form.Item
            name="authNumber"
            rules={[
              {
                required: true,
                message: "발송 된 인증번호를 입력해주세요",
              },
            ]}
            label="인증번호 확인"
          >
            <OnlyNumberInput
              placeholder="인증코드 6자리"
              addonAfter={
                <Button disabled={!time} type="text" onClick={setAuthByPhone}>
                  확인
                </Button>
              }
            />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Popconfirm
                title={`로그아웃 하시겠습니까?`}
                onConfirm={logout}
                placement="topLeft"
              >
                <Button block type="primary" size="large">
                  로그아웃
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  )
})
const styles = {
  container: {
    height: "60vh",
    [MediaQuery[1]]: {
      height: "70vh",
    },
    display: "flex",
    alignItems: "center",
    "& .ant-card": {
      minWidth: "20vw",
      [MediaQuery[1]]: {
        minWidth: "50vw",
        "& .ant-card-body": {
          padding: 12,
          "& .ant-form-item": {
            marginBottom: 12,
          },
        },
      },
      "& p": {
        fontSize: "13px",
        color: "#7C7C7C",
        marginBottom: 24,
      },
      "& .title": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "22px",
        padding: "10px 0",
        borderBottom: "1px solid #D9D9D9;",
        marginBottom: 14,
      },
      "& .ant-input-group-addon": {
        "& button": {
          height: 30,
          marginTop: 0,
        },
      },
      "& button": {
        marginTop: 16,
      },
    },
  },
}
