/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { Card, Badge, Divider } from "antd"
import dayjs from "dayjs"

import {
  commaOfNumber,
  phoneFormatter,
  virtualNumberFormatter,
} from "@utils/format"
import { useMobxStores } from "@stores/stores"

export default function SktlCalllogTableMobile({
  dataSource,
  pagination,
  loading,
  onChange,
}) {
  const { globalStore } = useMobxStores()

  const [cardScroll, inView] = useInView()
  const [viewData, setViewData] = useState(dataSource)
  const [isScroll, setIsScroll] = useState(false)

  /* 스크롤바 내릴 경우, 이벤트발생 */
  useEffect(() => {
    if (inView && !loading && pagination.current < pagination.totalPage) {
      setIsScroll(true)
      onChange({
        current: pagination.current + 1,
        pageSize: pagination.pageSize,
      })
      globalStore.setViewTopButton(true)
    }
  }, [inView])

  useEffect(() => {
    if (pagination.current === 1 || !isScroll) {
      setViewData(dataSource)
    } else {
      const clone = [...viewData]
      setViewData([...clone, ...dataSource])
      setIsScroll(false)
    }
  }, [dataSource])

  return (
    <>
      {viewData?.map((e, i) => {
        return (
          <Card
            bordered={false}
            loading={loading}
            css={styles.wrapper}
            key={e.sequence}
            ref={i == viewData?.length - 1 ? cardScroll : null}
          >
            <Card.Grid css={[styles.span1, styles.th]}>업체명</Card.Grid>
            <Card.Grid css={styles.span4}>{e?.agent?.name || "-"}</Card.Grid>

            <Divider />

            <div className="th-row">
              <Card.Grid css={[styles.span2, styles.th]}>발신번호</Card.Grid>
              <Divider type="vertical" />
              <Card.Grid css={[styles.span2, styles.th]}>착신번호</Card.Grid>
              <Divider type="vertical" />
              <Card.Grid css={[styles.span2, styles.th]}>가상번호</Card.Grid>
            </div>
            <Card.Grid css={[styles.center, styles.span2]}>
              {e.fromId ? phoneFormatter(e.fromId) : "-"}
            </Card.Grid>
            <Card.Grid css={[styles.center, styles.span2]}>
              {e.toId ? phoneFormatter(e.toId) : "-"}
            </Card.Grid>
            <Card.Grid css={[styles.center, styles.span2]}>
              {e.dnis ? virtualNumberFormatter(e.dnis) : "-"}
            </Card.Grid>

            <Divider />

            <div className="th-row">
              <Card.Grid css={[styles.span3, styles.th]}>
                호 시작 시간
              </Card.Grid>
              <Divider type="vertical" />
              <Card.Grid css={[styles.span3, styles.th]}>
                호 연결 시간
              </Card.Grid>
            </div>
            <Card.Grid css={[styles.center, styles.span3]}>
              {e.startTime
                ? dayjs(e.startTime).format("YYYY-MM-DD HH:mm:ss")
                : "-"}
            </Card.Grid>
            <Card.Grid css={[styles.center, styles.span3]}>
              {e.serviceStartTime
                ? dayjs(e.serviceStartTime).format("YYYY-MM-DD HH:mm:ss")
                : "-"}
            </Card.Grid>

            <div className="th-row">
              <Card.Grid css={[styles.span3, styles.th]}>
                호 종료 시간
              </Card.Grid>
              <Divider type="vertical" />
              <Card.Grid css={[styles.span3, styles.th]}>통화시간</Card.Grid>
            </div>
            <Card.Grid css={[styles.center, styles.span3]}>
              {e.endTime ? dayjs(e.endTime).format("YYYY-MM-DD HH:mm:ss") : "-"}
            </Card.Grid>
            <Card.Grid css={[styles.center, styles.span3]}>
              {e.serviceDuration ? commaOfNumber(e.serviceDuration) : "-"}
            </Card.Grid>

            <Divider />
            <Card.Grid css={[styles.span1, styles.th]}>CAUSE</Card.Grid>
            <Card.Grid css={styles.span4}>{e?.cause || "-"}</Card.Grid>
          </Card>
        )
      })}
    </>
  )
}

const styles = {
  wrapper: {
    border: "1px solid #D9D9D9",
    borderRadius: 5,
    fontSize: 11,
    marginBottom: 24,
    "& .ant-card-body": {
      padding: 0,
      "& .th-row": {
        background: "#F0F0F0",
      },
    },
    "& .ant-card-grid": {
      padding: "2px 6px",
      boxShadow: "none",
      display: "inline-block",
    },
    "& .ant-divider": {
      margin: 0,
    },
    "& .ant-divider-vertical": {
      borderLeft: "1px solid #D9D9D9",
    },
  },
  span1: { width: "25%" },
  span2: { width: "33.09%" },
  span3: { width: "49.8%" },
  span4: { width: "75%" },
  span5: { width: "100%" },
  center: { textAlign: "center" },
  th: { textAlign: "center", background: "#F0F0F0" },
}
