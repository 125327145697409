/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { Button, Popconfirm, Select, Table, DatePicker } from "antd";
import { PlusCircleFilled, CloseOutlined } from "@ant-design/icons";
import { setTimePickerFormat } from "@app/utils/format";
import dayjs from "dayjs";

export default function VnMappWeekendTable(props) {
  const { items, setItems, colorings } = props;
  const weekOptions = [
    { label: "월요일", value: "MON" },
    { label: "화요일", value: "TUE" },
    { label: "수요일", value: "WED" },
    { label: "목요일", value: "THU" },
    { label: "금요일", value: "FRI" },
    { label: "토요일", value: "SAT" },
    { label: "일요일", value: "SUN" },
  ];
  function addRow() {
    const clone = [...items];
    const newRow = {
      key: Date.now(),
      week: "",
      startTime: "0000",
      endTime: "0000",
      colorRingId: "",
    };
    setItems([...clone, newRow]);
  }

  function deleteRow(key) {
    const clone = [...items];
    setItems(clone.filter((item) => item.key !== key));
  }

  //테이블 수정
  function changeTable(e, record, index, keyName) {
    console.log("ele", e);
    const clone = [...items];
    console.log("clone", clone);
    if (keyName === "startTime" || keyName === "endTime") {
      clone[index][keyName] = dayjs(e).format("HHmm");
    } else clone[index][keyName] = e;
    setItems(clone);
  }

  const columns = [
    {
      key: "week",
      dataIndex: "week",
      title: "주일",
      align: "center",
      width: "20%",
      render: (text, record, index) => (
        <Select
          onChange={(e) => changeTable(e, record, index, "week")}
          options={weekOptions}
          defaultValue={text}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      key: "startTime",
      dataIndex: "startTime",
      title: "시작 시간",
      align: "center",
      width: "15%",
      render: (text, record, index) => (
        <DatePicker.TimePicker
          defaultValue={setTimePickerFormat(text)}
          format={"HH:mm"}
          onChange={(e) => changeTable(e, record, index, "startTime")}
        />
      ),
    },
    {
      key: "endTime",
      dataIndex: "endTime",
      title: "종료 시간",
      align: "center",
      width: "15%",
      render: (text, record, index) => (
        <DatePicker.TimePicker
          defaultValue={setTimePickerFormat(text)}
          format={"HH:mm"}
          onChange={(e) => changeTable(e, record, index, "endTime")}
        />
      ),
    },
    {
      key: "colorRingId",
      dataIndex: "colorRingId",
      title: "컬러링",
      align: "center",
      width: "40%",
      render: (text, record, index) => (
        <Select
          style={{ width: "100%" }}
          options={colorings}
          defaultValue={text}
          onChange={(e) => changeTable(e, record, index, "colorRingId")}
        />
      ),
    },
    {
      key: "isBtnDisable",
      align: "center",
      width: "10%",
      dataIndex: "isBtnDisable",
      title: () => {
        return (
          <PlusCircleFilled style={{ color: "#007def" }} onClick={addRow} />
        );
      },
      render: (text, record, index) => (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Popconfirm
              title={"삭제 하시겠습니까?"}
              cancelText={"취소"}
              okText={"확인"}
              onConfirm={() => deleteRow(record?.key)}
              onCancel={() => {}}
            >
              <Button type="text" icon={<CloseOutlined />} />
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <p css={styles.custom_label}>
        <span>*</span>
        <label style={{ fontWeight: "bold" }}>주일 컬러링</label>
        <span style={{ fontSize: "12px" }}>
          (설정 된 요일과 시간대에 해당 컬러링이 발신자에게 재생됩니다.)
        </span>
      </p>
      <Table
        tableLayout="fixed"
        columns={columns}
        dataSource={items}
        size="small"
        pagination={false}
        scroll={{ x: 590, y: 300 }}
      />
    </>
  );
}

const styles = {
  custom_label: {
    marginRight: "11px",
    "& span": { color: "red !important", marginRight: "3px" },
  },
};
