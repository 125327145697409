import { axiosInstance as axios } from "@app/axios";
import qs from "qs";

export default {
  //블랙/화이트 목록 조회 (슈퍼 계정)
  getBlackWhiteList: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/black-white/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    };
    return axios(requestOptions);
  },
  //블랙,화이트 목록 일반유저 조회
  getBlackWhiteListNormal: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/black-white/v1/complete?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    };
    return axios(requestOptions);
  },
  //블랙,화이트 리스트 전체설정 조회
  getBlackWhiteListAll: (agentId) => {
    const requestOptions = {
      method: "GET",
      url: `/api/black-white/v1/${agentId}/all`,
    };
    return axios(requestOptions);
  },
  //블랙 화이트 전체리스트 추가
  addAllBlackWhiteList: (params) => {
    const requestOptions = {
      method: "POST",
      url: `/api/black-white/v1/all`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(params),
    };
    return axios(requestOptions);
  },
  //블랙 화이트 전체리스트 삭제
  deleteBlackWhiteData: (agentId, callingNumber) => {
    const requestOptions = {
      method: "DELETE",
      url: `/api/black-white/v1/${agentId}/${callingNumber}/all`,
    };
    return axios(requestOptions);
  },
  //블랙 화이트 설정 수정
  changeBlackWhite: (params) => {
    const requestOptions = {
      method: "PUT",
      url: `/api/black-white/v1`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(params),
    };
    return axios(requestOptions);
  },
  //050 번호별 설정 블랙/화이트 리스트
  getBlackWhiteListByNum: (agentId) => {
    const requestOptions = {
      method: "GET",
      url: `/api/black-white/v1/${agentId}/calling`,
    };
    return axios(requestOptions);
  },

  //블랙 화이트 050번호별리스트 추가
  addBlackWhiteListByNum: (virsualNumber, params) => {
    const requestOptions = {
      method: "POST",
      url: `/api/black-white/v1/${virsualNumber}/calling`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(params),
    };
    return axios(requestOptions);
  },
  //블랙 화이트 050번호별리스트 삭제
  deleteBlackWhiteDataByNum: (virsualNumber) => {
    const requestOptions = {
      method: "DELETE",
      url: `/api/black-white/v1/${virsualNumber}/calling`,
    };
    return axios(requestOptions);
  },
  //050 번호 별 상세 리스트(화이트, 블랙)
  getBlackWhiteListByType: (agentId, virtualNumber, type) => {
    const requestOptions = {
      method: "GET",
      url: `/api/black-white/v1/${agentId}/${virtualNumber}/calling/${type?.toLowerCase()}`,
    };
    return axios(requestOptions);
  },
};
