/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import PropTypes from "prop-types";

import { Spin } from "antd";
import { Styles } from "@app/utils/style";

GlobalProgress.propTypes = {
  propsStyle: PropTypes.object,
  isBackColor: PropTypes.bool,
};
GlobalProgress.defaultProps = {
  propsStyle: {},
  isBackColor: false,
};

function GlobalProgress(props) {
  const { isBackColor } = props;
  return (
    <div
      css={styles.loadingContainer}
      style={{ backgroundColor: isBackColor ? "rgba(0, 0, 0, 0.5)" : null }}
    >
      <Spin />
    </div>
  );
}
export default GlobalProgress;

const styles = {
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "fixed",
    top: "0",
    left: "0",
    // backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: "100",
    ...Styles.rowCenterCenter,
  },
};
