/** @jsxImportSource @emotion/react */
import { MediaQuery } from "@utils/style"
import { Button } from "antd"
import ContentContainer from "../container/ContentContainer"
import { SearchOutlined } from "@ant-design/icons"

SearchForm.defaultProps = {
  footerButtons: null,
  footerStartButton: null,
  style: {},
  buttonStyle: { marginRight: "10px" },
}

export default function SearchForm(props) {
  const { footerButtons, buttonStyle, onFinish, footerButtonStyle } = props
  return (
    <ContentContainer>
      <div css={styles.wrapper}>
        {props.children}
        {footerButtons && (
          <div
            css={footerButtonStyle ? footerButtonStyle : styles.footerButtons}
          >
            {
              <Button
                type="primary"
                icon={<SearchOutlined />}
                style={buttonStyle}
                onClick={() => onFinish()}
              >
                검색
              </Button>
            }
            {footerButtons}
          </div>
        )}
      </div>
    </ContentContainer>
  )
}

const styles = {
  wrapper: {
    position: "relative",
    padding: "14px 24px 0px 24px",
    [MediaQuery[1]]: {
      padding: "8px 16px 48px 16px",
    },
    "& .ant-form-item": {
      marginBottom: 16,
    },
    minHeight: "60px",
  },
  footerButtons: {
    display: "flex",
    position: "absolute !important",
    right: 24,
    bottom: 14,
    [MediaQuery[1]]: {
      left: 16,
      bottom: 8,
    },
  },
}
