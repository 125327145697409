import {
  axiosInstance as axios,
  axiosNoAuthInstance as axiosNoAuth,
} from "@app/axios"
import qs from "qs"

export default {
  // 공지사항 리스트 조회(페이징)
  getNotices: (params) => {
    const requestOptions = {
      method: "GET",
      url: `api/notice/v1/contains?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 공지사항 단일 조회
  getNoticeById: (id) => {
    const requestOptions = {
      method: "GET",
      url: `api/notice/v1/${id}`,
    }
    return axios(requestOptions)
  },
  // 공지사항 등록
  createNotice: (data) => {
    const requestOptions = {
      method: "POST",
      url: "api/notice/v1",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(data),
    }
    return axios(requestOptions)
  },
  // 공지사항 수정
  setNotice: (id, data) => {
    const requestOptions = {
      method: "PUT",
      url: `api/notice/v1/${id}`,
      data: JSON.stringify(data),
    }
    return axios(requestOptions)
  },
  // 공지사항 삭제
  deleteNotice: (id) => {
    const requestOptions = {
      method: "DELETE",
      url: `api/notice/v1/${id}`,
    }
    return axios(requestOptions)
  },
  // 현재 노출 되어야할 공지사항 리스트 조회
  getNoticeCurrent: (params) => {
    const requestOptions = {
      method: "GET",
      url: `api/notice/v1/current?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axiosNoAuth(requestOptions)
  },
}
