import { useMediaQuery } from "react-responsive";

export const Breakpoints = [576, 768, 992, 1200];
export const MediaQuery = Breakpoints.map(
  (breakpoint) => `@media (max-width: ${breakpoint - 1}px)`
);

export function useIsMobile() {
  return useMediaQuery({ maxWidth: Breakpoints[1] });
}
export const Styles = {
  disabledText: {
    color: "#999",
  },
  columnCenterCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  rowCenterCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  rowVerticalCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
};
