import {axiosNoAuthInstance as axios} from "@app/axios";
import qs from "qs";

export default {
  // 로그인
  userLogin: (params) => {
    const requestOptions = {
      method: "POST",
      url: `/api/auth/v1/admins?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    };
    return axios(requestOptions);
  },
  // 토큰 재발급
  setRefreshToken: (params) => {
    const requestOptions = {
      method: "POST",
      url: `api/auth/v1/refresh?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    };
    return axios(requestOptions);
  },
};
