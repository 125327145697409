/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { observer } from "mobx-react"
import { Layout, Button, Modal } from "antd"
import { MenuOutlined } from "@ant-design/icons"

import { useMobxStores } from "@stores/stores"
import { useIsMobile } from "@utils/style"

import MyInfo from "@components/common/MyInfo"

const { Header } = Layout

function TopNavigation(props) {
  const { userStore } = useMobxStores()

  const [myInfoModal, setMyInfoModal] = useState()

  const paddingLeft = props.collapsed
    ? props.collapsedWidth
    : props.collapsedWidth === 0
    ? 0
    : 200

  async function logout() {
    await userStore?.logout()
  }

  return (
    <>
      <Header
        css={[
          styles.container,
          {
            height: 48,
            paddingLeft: paddingLeft + 16,
          },
        ]}
      >
        <Button
          type="text"
          shape="circle"
          icon={<MenuOutlined style={{ color: "#fff" }} />}
          onClick={() => {
            props.setCollapsed(!props.collapsed)
          }}
        />
        <div style={styles.rightContainer}>
          <div style={styles.adminUserContainer}>
            <span style={styles.adminUserText}>{userStore?.user?.name}님</span>
          </div>
          <div style={styles.adminUserContainer}>
            <Button
              type="text"
              size="small"
              onClick={() => setMyInfoModal(true)}
              style={styles.adminUserText}
            >
              내정보변경
            </Button>
            {!useIsMobile() && (
              <Button
                type="text"
                size="small"
                onClick={() => {
                  window.open("/manual")
                }}
                style={styles.adminUserText}
              >
                매뉴얼
              </Button>
            )}
          </div>
          <Button ghost shape="round" size="small" onClick={logout}>
            로그아웃
          </Button>
        </div>
      </Header>
      <Modal
        css={styles.modal}
        open={myInfoModal}
        title="내정보 변경"
        onCancel={() => {
          setMyInfoModal(false)
        }}
        footer={false}
      >
        <MyInfo setMyInfoModal={setMyInfoModal} />
      </Modal>
    </>
  )
}

const styles = {
  container: {
    paddingRight: 16,
    position: "fixed",
    width: "100vw",
    top: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#1A2D43",
    transition: "all 0.1s ease-in-out",
    zIndex: 10,
  },
  rightContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  adminUserContainer: {
    marginRight: 12,
    height: 48,
    lineHeight: "48px",
  },
  adminUserText: {
    color: "#fff",
  },
  modal: {
    "& .ant-modal-close": {
      left: 0,
    },
    "& .ant-modal-title": {
      marginLeft: 30,
      fontWeight: "bold",
    },
  },
}

export default observer(TopNavigation)
