/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { Button, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

function UploadButton({
  type = "default",
  text,
  loading = false,
  style,
  customRequest = () => {},
  showUploadList = false,
}) {
  return (
    <Upload showUploadList={showUploadList} customRequest={customRequest}>
      <Button
        type={type}
        icon={<CloudUploadOutlined />}
        loading={loading}
        style={style}
      >
        {text}
      </Button>
    </Upload>
  );
}

export default UploadButton;
