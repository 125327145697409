import { useState, useEffect } from "react"
import { Select } from "antd"
import { observer } from "mobx-react"

import { useMobxStores } from "@stores/stores"

import manage from "@app/axios/manage"
import _ from "lodash"

InformRingsSelect.defaultProps = {
  agentId: null,
  disabled: false,
}

function InformRingsSelect(props) {
  //슈퍼어드민의 경우에는 agentId 필수로 받아야함, 전체의 경우에는 데이터가 너무많음
  const { allSearch, value, style, allowClear, agentId, disabled } = props
  const [rings, setRings] = useState([])
  const [selectValue, setSelectValue] = useState()
  const { userStore, globalStore } = useMobxStores()
  async function getRings() {
    let options = []
    try {
      if (userStore?.super) {
        const prom = await manage.getInforingsByAgent(agentId)
        const result = prom?.data?.data.map((item) => ({
          label: `${item?.ringId}(${item?.description})`,
          value: item?.ringId,
        }))
        options = result
      } else if (userStore?.user) {
        const prom = await manage.getInforingsAllList()
        const result = prom?.data?.data.map((item) => ({
          label: `${item?.ringId}(${item?.description})`,
          value: item?.ringId,
        }))
        options = result
      }
      if (allSearch) {
        options.unshift({
          label: "전체",
          value: "",
        })
      }
      setRings(options)
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  function onChange(value) {
    setSelectValue(value)
    const { onChange } = props
    if (onChange) onChange(value)
  }

  useEffect(() => {
    if (
      (userStore?.super && agentId) ||
      (userStore?.user && userStore?.super === false)
    )
      getRings()
  }, [userStore?.user, agentId])

  useEffect(() => {
    if (value) setSelectValue(value)
  }, [value])

  return (
    <Select
      style={style}
      showSearch
      dropdownMatchSelectWidth={true}
      filterOption={(input, option) => {
        return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }}
      value={selectValue}
      options={rings}
      onChange={onChange}
      allowClear={allowClear}
      disabled={disabled}
    />
  )
}

export default observer(InformRingsSelect)
