/** @jsxImportSource @emotion/react */
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"

function AddButton(props) {
  const { onClick, text, type } = props
  return (
    <Button
      type={type || "primary"}
      {...props}
      icon={<PlusOutlined />}
      onClick={onClick}
    >
      {text || "등록"}
    </Button>
  )
}

export default AddButton
