const menus = [
  {
    key: "/service",
    name: "관리",
    subMenus: [
      {
        key: "service-list",
        name: "서비스 현황",
        path: "/service/service",
        disabled: false,
        breadcrumbs: ["관리", "서비스 현황"],
      },
      {
        key: "agent-list",
        name: "사업장 현황",
        path: "/agent/list",
        disabled: false,
        breadcrumbs: ["관리", "사업장 현황"],
      },
      {
        key: "user-list",
        name: "사용자 현황",
        path: "/user/list",
        disabled: false,
        breadcrumbs: ["관리", "사용자 현황"],
      },
      {
        key: "notice-list",
        name: "공지사항 현황",
        path: "/notice/list",
        disabled: false,
        breadcrumbs: ["관리", "공지사항 현황"],
      },
    ],
  },
  {
    key: "/calllog",
    name: "조회",
    subMenus: [
      {
        key: "calllog-list",
        name: "콜로그 조회",
        path: "/calllog/list",
        disabled: false,
        breadcrumbs: ["조회", "콜로그 조회"],
      },
      {
        key: "sktlcalllog-list",
        name: "SKTL 콜로그 조회",
        path: "/calllog/ext_list",
        disabled: false,
        breadcrumbs: ["조회", "SKTL 콜로그 조회"],
      },
      {
        key: "authlog-list",
        name: "인증 로그 조회",
        path: "/calllog/auth_log_list",
        disabled: false,
        breadcrumbs: ["조회", "인증 로그 조회"],
      },
      {
        key: "autolog-list",
        name: "비즈알림 로그 조회",
        path: "/calllog/auto_log_list",
        disabled: false,
        breadcrumbs: ["조회", "비즈알림 로그 조회"],
      },
    ],
  },
  {
    key: "/manage",
    name: "운영",
    subMenus: [
      {
        key: "manage-color-list",
        name: "컬러링 관리",
        path: "/manage/color_list",
        disabled: false,
        breadcrumbs: ["운영", "컬러링 관리"],
      },
      {
        key: "manage-inform-list",
        name: "알림멘트 관리",
        path: "/manage/inform_list",
        disabled: false,
        breadcrumbs: ["운영", "알림멘트 관리"],
      },
      {
        key: "manage-vn-mapp-list",
        name: "050번호 관리",
        path: "/manage/vn_mapp_list",
        disabled: false,
        breadcrumbs: ["운영", "050번호 관리"],
      },
      {
        key: "manage-sktel-list",
        name: "SKTL 번호 관리",
        path: "/manage/sktel_list",
        disabled: false,
        breadcrumbs: ["운영", "SKTL 번호 관리"],
      },
      {
        key: "manage-blacklist",
        name: "블랙/화이트 관리",
        path: "/manage/blacklist",
        disabled: false,
        breadcrumbs: ["운영", "블랙/화이트 관리"],
      },
    ],
  },
  {
    key: "/stats",
    name: "통계",
    subMenus: [
      {
        key: "stats-list",
        name: "기간사별",
        path: "/stats/list",
        disabled: false,
        breadcrumbs: ["통계", "기간사별"],
      },
      {
        key: "stats-agentlist",
        name: "사업자별",
        path: "/stats/agent_list",
        disabled: false,
        breadcrumbs: ["통계", "사업자별"],
      },
      {
        key: "stats-detail-agentlist",
        name: "사업자별",
        path: "/stats/detail_agent_list",
        disabled: false,
        breadcrumbs: ["통계", "사업자별"],
      },
      {
        key: "stats-sktllist",
        name: "SKTL",
        path: "/stats/ext_list",
        disabled: false,
        breadcrumbs: ["통계", "SKTL"],
      },
    ],
  },
]
export default menus
