/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom"
import { ConfigProvider, Descriptions, Row, Col, Divider } from "antd"

import ItemView from "@components/view/ItemView"

import { MediaQuery } from "@utils/style"
import { checkAccessKey } from "@utils/router"
import { useMobxStores } from "@stores/stores"
import { commaOfNumber } from "@utils/format"

export default function ServiceStatusTable(props) {
  const { agent } = props
  const location = useLocation()
  const { userStore } = useMobxStores()

  return (
    <ConfigProvider>
      <div css={styles.container}>
        <Row gutter={16}>
          <Col xs={24}>
            <div>
              <span className="title">현재 현황</span>
              <Divider />
            </div>
            <ItemView>
              <ItemView.Descriptions column={1}>
                <Descriptions.Item label="컬러링 수">
                  {commaOfNumber(
                    agent?.agentServiceStatus?.colorRingCount || 0
                  )}
                  개
                </Descriptions.Item>
                {(checkAccessKey("VIEWRECORD", userStore, location) ||
                  checkAccessKey("USINGRECORD", userStore, location)) && (
                  <Descriptions.Item label="레코딩 수">
                    {commaOfNumber(
                      agent?.agentServiceStatus?.recordingCount || 0
                    )}
                    개
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="알림멘트 수">
                  {commaOfNumber(
                    agent?.agentServiceStatus?.informRingCount || 0
                  )}
                  개
                </Descriptions.Item>
                <Descriptions.Item label="전체 가상번호 수">
                  {commaOfNumber(
                    agent?.agentServiceStatus?.totalVirtualNumberCount || 0
                  )}
                  개
                </Descriptions.Item>
                <Descriptions.Item label="할당 가상번호 수">
                  {commaOfNumber(
                    agent?.agentServiceStatus?.usingVirtualNumberCount || 0
                  )}
                  개
                </Descriptions.Item>
              </ItemView.Descriptions>
            </ItemView>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  )
}

const styles = {
  container: {
    "& .ant-divider": {
      margin: "6px 0 12px",
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 15,
    },
    "& .ant-descriptions-item-label": {
      width: 250,
      [MediaQuery[2]]: {
        width: 200,
      },
    },
  },
}
