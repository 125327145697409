/** @jsxImportSource @emotion/react */
import { observer } from "mobx-react"
import { Layout, Menu } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import menus from "@app/menus"
import { ReactComponent as BizcallLogo } from "@app/assets/svg/BizcallLogo_sm.svg"
import { hasRoles } from "@utils/router"

import { useMobxStores } from "@stores/stores"
import { useIsMobile } from "@utils/style"

export default observer(function SideNavigation(props) {
  const { collapsed, setCollapsed, collapsedWidth, setCollapsedWidth } = props
  const { userStore } = useMobxStores()
  let location = useLocation()
  let navigate = useNavigate()

  return (
    <>
      <Layout.Sider
        theme="light"
        trigger={null}
        css={styles.container}
        collapsible
        collapsed={collapsed}
        collapsedWidth={collapsedWidth}
        defaultCollapsed={useIsMobile() ? false : true}
        breakpoint="md"
        onBreakpoint={(broken) => {
          broken ? setCollapsedWidth(0) : setCollapsedWidth(0.01)
        }}
      >
        <div
          css={[styles.logoContainer, { cursor: "pointer" }]}
          onClick={() =>
            navigate(userStore?.myRoutes?.[0]?.accessWeb?.fullPath || "/")
          }
        >
          <div
            className="logo"
            css={{
              opacity: collapsed ? 0 : 1,
            }}
          >
            <BizcallLogo width={150} height={60} />
          </div>
        </div>
        <Menu
          mode="inline"
          css={styles.menu}
          selectedKeys={[location.pathname]}
        >
          {menus
            ?.filter((menu, i) => {
              return hasRoles(menu, userStore)
            })
            .map((menu, i) => {
              if (menu.subMenus) {
                return (
                  <Menu.SubMenu key={menu.key + i} title={menu.name}>
                    {menu?.subMenus
                      .filter((menu, i) => {
                        return hasRoles(menu, userStore)
                      })
                      .map((sub, j) => {
                        return (
                          <Menu.Item
                            key={sub.path ? sub.path : sub.key}
                            disabled={sub.disabled}
                            onClick={() => {
                              navigate(sub.path)
                              collapsedWidth === 0 && setCollapsed(!collapsed)
                            }}
                          >
                            {sub.name}
                          </Menu.Item>
                        )
                      })}
                  </Menu.SubMenu>
                )
              } else {
                return (
                  <Menu.Item
                    key={menu.path ? menu.path : menu.key}
                    disabled={menu.disabled}
                    onClick={() => {
                      navigate(menu.path)
                      collapsedWidth === 0 && setCollapsed(!collapsed)
                    }}
                  >
                    {menu.name}
                  </Menu.Item>
                )
              }
            })}
        </Menu>
      </Layout.Sider>
      {!collapsed && collapsedWidth === 0 && (
        <div
          css={styles.overlay}
          onClick={() => {
            setCollapsed(!collapsed)
          }}
        />
      )}
    </>
  )
})

const styles = {
  container: {
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 12,
  },
  overlay: {
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 11,
  },
  menu: {
    "& .ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title":
      {
        backgroundColor: "#EBF5FE",
      },
  },
  logoContainer: {
    fontSize: 24,
    position: "relative",
    height: 88,
    "& .logo": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.1s ease-in-out",
    },
  },
  adminTitleText: {
    color: "#999",
    marginLeft: 6,
  },
}
