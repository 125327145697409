/** @jsxImportSource @emotion/react */
import { useEffect } from "react"
import {
  ConfigProvider,
  Descriptions,
  Form,
  Radio,
  Col,
  Row,
  Divider,
  Space,
  Button,
  Popconfirm,
} from "antd"

import ItemView from "@components/view/ItemView"
import OnlyNumberInput from "@components/input/OnlyNumberInput"

import { objectClean } from "@app/utils/router"
import { useMobxStores } from "@stores/stores"
import agentApi from "@api/agent"

const initialValues = {
  serviceYN: null,
  colorRingCount: null,
  informRingCount: null,
  recordCount: null,
  blackAndWhite: "NONE",
  acYN: null,
}

export default function ServiceSettingTable(props) {
  const { agent } = props
  const [form] = Form.useForm()
  const { globalStore } = useMobxStores()

  async function updateAgent() {
    // 사업장 수정
    try {
      const data = {
        ...agent,
        service: {
          ...form.getFieldValue(),
        },
      }

      if (
        data?.service?.colorRingCount === null ||
        data?.service?.colorRingCount === ""
      )
        return globalStore.warningNoti("컬러링 수를 입력해주세요.")
      if (
        data?.service?.recordCount === null ||
        data?.service?.recordCount === ""
      )
        return globalStore.warningNoti("레코딩 수를 입력해주세요.")
      if (
        data?.service?.informRingCount === null ||
        data?.service?.informRingCount === ""
      )
        return globalStore.warningNoti("알림멘트 수를 입력해주세요.")
      if (data?.service?.serviceYN === null)
        return globalStore.warningNoti("서비스 여부를 선택해주세요.")
      if (data?.service?.acYN === null)
        return globalStore.warningNoti("알림서비스를 선택해주세요.")

      if (
        data?.service?.blackAndWhite === "NONE" ||
        !data?.service?.blackAndWhite
      ) {
        delete data.service.blackAndWhite
      }

      await agentApi.updateAgent(agent?.id, data)
      globalStore.successNoti("사업장이 수정되었습니다.")
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  useEffect(() => {
    if (agent) {
      form.setFieldsValue({
        ...agent.service,
      })
    }
  }, [agent])

  return (
    <ConfigProvider>
      <div css={styles.container}>
        <Row gutter={16}>
          <Col xs={24}>
            <div>
              <span className="title">Setting</span>
              <Popconfirm
                title={`수정 하시겠습니까?`}
                onConfirm={updateAgent}
                placement="topLeft"
              >
                <Button
                  type="primary"
                  style={{ float: "right", marginBottom: 4 }}
                >
                  수정
                </Button>
              </Popconfirm>
            </div>
            <Divider />
            <Form form={form} initialValues={initialValues}>
              <ItemView>
                <ItemView.Descriptions column={2}>
                  <Descriptions.Item label="컬러링 수">
                    <Space>
                      <Form.Item name="colorRingCount">
                        <OnlyNumberInput />
                      </Form.Item>
                      개
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="레코딩 수">
                    <Space>
                      <Form.Item name="recordCount">
                        <OnlyNumberInput />
                      </Form.Item>
                      개
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="알림멘트 수">
                    <Space>
                      <Form.Item name="informRingCount">
                        <OnlyNumberInput />
                      </Form.Item>
                      개
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="서비스 여부">
                    <Form.Item name="serviceYN">
                      <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={[
                          { label: "활성", value: true },
                          { label: "비활성", value: false },
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="BlackWhite">
                    <Form.Item name="blackAndWhite">
                      <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={[
                          { label: "None", value: "NONE" },
                          { label: "Black", value: "BLACK" },
                          { label: "White", value: "WHITE" },
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label="알림서비스 ">
                    <Form.Item name="acYN">
                      <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={[
                          { label: "활성", value: true },
                          { label: "비활성", value: false },
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                </ItemView.Descriptions>
              </ItemView>
            </Form>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  )
}

const styles = {
  container: {
    marginBottom: 12,
    "& .ant-divider": {
      margin: "6px 0 12px",
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 15,
    },
  },
}
