import ResponsiveTable from "@app/components/table/ResponsiveTable"
import { useMobxStores } from "@app/stores/stores"
import {
  commaOfNumber,
  phoneFormatter,
  virtualNumberFormatter,
} from "@app/utils/format"
import { Breakpoints, useIsMobile } from "@app/utils/style"
import { checkAccessKeyNoPath } from "@app/utils/router"
import { Button } from "antd"
import { useState } from "react"
import { useMediaQuery } from "react-responsive"
import VirtualNumberHistoryModal from "../modal/VirtualNumberHistoryModal"
import { observer } from "mobx-react"

export default observer(function VirtualManageTable(props) {
  const {
    openViewSider,
    virtualMappings,
    pagination,
    filter,
    setFilter,
    loading,
  } = props
  const [virtualNumber, setVirtualNumber] = useState(null)
  const isMobile = useMediaQuery({ maxWidth: Breakpoints[1] })
  const { userStore } = useMobxStores()

  const columns = [
    {
      key: "agent.name",
      dataIndex: ["agent", "name"],
      title: "업체명",
      align: "center",
      width: 160,
      render: (text) => {
        return text || "-"
      },
    },
    {
      key: "virtualNumber",
      dataIndex: "virtualNumber",
      title: "가상번호",
      align: "center",
      width: 150,
      className: "selectable",
      render: (text) => virtualNumberFormatter(text),
      onCell: (record) => ({
        onClick: () => openViewSider("detail", record?.virtualNumber),
      }),
      cardViewOnClick: (record) =>
        openViewSider("detail", record?.virtualNumber),
      mobileViewStyle: { fontWeight: "bold", color: "#FCB813" },
    },
    {
      key: "switchYn",
      dataIndex: "switchYn",
      title: "사용 여부",
      align: "center",
      width: 100,
      render: (text) => {
        return text ? "사용" : "미사용"
      },
    },
    {
      key: "realNumber",
      dataIndex: "realNumber",
      title: "착신번호",
      align: "center",
      width: 130,
      render: (text) => {
        if (text === "-") return "-"
        else return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "colorRing",
      dataIndex: ["colorRing", "description"],
      title: "컬러링",
      align: "center",
      render: (text) => {
        return text || "-"
      },
    },
    {
      key: "informRing",
      dataIndex: ["informRing", "description"],
      title: "알림멘트",
      align: "center",
      render: (text) => {
        return text?.replace(/<[^>]*>?/g, "") || "-"
      },
    },
    {
      key: "registDatetime",
      dataIndex: "registDatetime",
      title: "등록일",
      align: "center",
      width: 160,
      render: (text) => {
        return text || "-"
      },
    },
    {
      key: "record",
      dataIndex: "record",
      title: "녹취 여부",
      align: "center",
      width: 100,
      render: (text) => (text ? "녹취" : "미녹취"),
      expanded: true,
      isView: checkAccessKeyNoPath("VIEWRECORD", userStore),
    },
    {
      key: "expireDateTime",
      dataIndex: "expireDateTime",
      title: "자동 해지",
      align: "center",
      width: 160,
      render: (text) => {
        return text || "-"
      },
      expanded: true,
    },
    {
      key: "memo",
      dataIndex: "memo",
      title: "메모",
      align: "center",
      width: 200,
      render: (text) => {
        return text || "-"
      },
      expanded: true,
    },
    {
      key: "memo2",
      dataIndex: "memo2",
      title: "메모2",
      align: "center",
      width: 200,
      render: (text) => {
        return text || "-"
      },
      expanded: true,
      isView: checkAccessKeyNoPath("MEMO2", userStore),
    },
    {
      key: "externalColorRing",
      dataIndex: "externalColorRing",
      title: "미운영컬러링",
      align: "center",
      width: 130,
      render: (text) => (text ? "설정" : "미설정"),
      expanded: true,
      isView: checkAccessKeyNoPath("EXTERNALCOLORRING", userStore),
    },
    {
      key: "externalFlexColorRings",
      dataIndex: "externalFlexColorRings",
      title: "주일 컬러링",
      align: "center",
      width: 130,
      render: (text) => (text?.length > 0 ? "설정" : "미설정"),
      expanded: true,
      isView: checkAccessKeyNoPath("EXTERNALCOLORRING", userStore),
    },
    {
      key: "history",
      dataIndex: "virtualNumber",
      title: "관리",
      align: "center",
      width: 100,
      fixed: useIsMobile() ? false : "right",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            onClick={() => setVirtualNumber(record?.virtualNumber)}
            size="small"
            style={isMobile ? { fontSize: "11px", height: "18px" } : {}}
          >
            이력보기
          </Button>
        )
      },
    },
  ]
  return (
    <>
      <p style={{ fontWeight: "bold" }}>
        050번호 수 : {commaOfNumber(pagination?.total || 0)}개
      </p>
      <ResponsiveTable
        columns={columns.filter((col) => {
          if (isMobile && !userStore.super) return col.key !== "agent.name"
          else return col.isView || col.isView === undefined
        })}
        dataSource={virtualMappings}
        pagination={pagination}
        rowKey={(e) => e.virtualNumber}
        onChange={(e) => {
          const page = {
            ...filter,
            page: e.current - 1,
            size: e.pageSize,
          }
          setFilter(page)
        }}
        scroll={{ x: 2500 }}
        loading={loading}
        doubleDataRow={["virtualNumber", "realNumber"]}
        useExpand
      />
      {virtualNumber && (
        <VirtualNumberHistoryModal
          virtualNumber={virtualNumber}
          setVirtualNumber={setVirtualNumber}
        />
      )}
    </>
  )
})
