import { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { observer } from "mobx-react"
import _cloneDeep from "lodash/cloneDeep"

import { objectClean } from "@utils/router"
import { getQuery, pushQuery } from "@utils/router"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"

import NoticeStatusSearch from "@containers/service/notice/NoticeStatusSearch"
import NoticeStatusTable from "@containers/service/notice/NoticeStatusTable"
import NoticeStatusDetail from "@pages/service/NoticeStatusDetail"

export default observer(function NoticeStatusPage() {
  const tableRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  const query = getQuery(location)
  const [viewSiderComponent, setViewComponent] = useState(null)
  const [lastParams, setLastParams] = useState({})

  function search(params) {
    const filter = _cloneDeep(params)
    if (filter.visible === "all") delete filter.visible
    if (filter.type === "all") delete filter.type
    if (filter.keyword) {
      filter[filter.keywordType] = filter.keyword
    }
    delete filter.dateRange
    delete filter.keywordType
    delete filter.keyword

    objectClean(filter)
    tableRef?.current?.fetchList(filter)
  }

  function openViewSider(type, id) {
    if (type === "detail") {
      delete query.add
      pushQuery({ ...query, id: id }, navigate)
    } else if (type === "add") {
      delete query.id
      pushQuery({ ...query, add: true }, navigate)
    }
  }

  useEffect(() => {
    if (query.id) {
      setViewComponent(
        <NoticeStatusDetail
          key={query.id}
          id={query.id}
          fetchList={() => {
            search(lastParams)
          }}
        />
      )
    } else if (query.add) {
      setViewComponent(
        <NoticeStatusDetail
          key={`add`}
          id={null}
          fetchList={() => {
            search(lastParams)
          }}
        />
      )
    } else {
      setViewComponent(null)
    }
  }, [location.search])

  return (
    <>
      <PageContainer viewSiderComponent={viewSiderComponent}>
        <PageHeader title="공지사항 현황" subTitle="공자사항을 관리합니다." />
        <NoticeStatusSearch search={search} openViewSider={openViewSider} />
        <ContentContainer wrapper>
          <NoticeStatusTable
            ref={tableRef}
            lastParams={lastParams}
            setLastParams={setLastParams}
            openViewSider={openViewSider}
          />
        </ContentContainer>
      </PageContainer>
    </>
  )
})
