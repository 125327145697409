import CryptoJS from "crypto-js";

const debug = false;
export async function sha256(txt) {
  try {
    const msgBuffer = new TextEncoder().encode(txt);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    debug && console.log("use sha256 => ", hashHex);
    return hashHex;
  } catch (error) {
    debug && console.error("Hashing failed:", error);
    return null; // 암호화 실패 시 null 반환
  }
}
export async function md5(txt) {
  try {
    const hash = CryptoJS.MD5(txt);
    const hashHex = hash.toString(CryptoJS.enc.Hex);
    debug && console.log("use MD5 => ", hashHex);
    return hashHex;
  } catch (error) {
    debug && console.error("Hashing failed:", error);
    return null;
  }
}
