/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useState } from "react";
import PropTypes from "prop-types";

import { Popconfirm, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";

SaveButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  loading: PropTypes.bool,
  popconfirmText: PropTypes.string,
  popconfirm: PropTypes.bool,
  disabled: PropTypes.bool,
};

SaveButton.defaultProps = {
  text: "저장",
  size: "large",
  loading: false,
  popconfirmText: "정말 진행하시겠어요?",
  popconfirm: true,
  disabled: false,
};

function SaveButton(props) {
  const {
    text,
    size,
    loading,
    popconfirm,
    popconfirmText,
    style,
    disabled,
    onClick,
    noIcon,
  } = props;
  const [visible, setVisible] = useState(false);

  let buttonProps = {
    size,
    icon: !noIcon ? <SaveOutlined /> : null,
    onClick,
    disabled,
    loading,
  };
  if (style) buttonProps.style = style;

  if (!popconfirm) {
    return (
      <Button type="primary" {...buttonProps}>
        {text}
      </Button>
    );
  }

  buttonProps.onClick = showPopconfirm;

  return (
    <Popconfirm
      title={popconfirmText}
      visible={visible}
      onConfirm={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading }}
      overlayStyle={{ maxWidth: 300, whiteSpace: "pre-line" }}
    >
      <Button type="primary" {...buttonProps}>
        {text}
      </Button>
    </Popconfirm>
  );

  function showPopconfirm() {
    setVisible(true);
  }

  function handleOk() {
    const { onClick } = props;
    setVisible(false);
    if (onClick) onClick();
  }

  function handleCancel() {
    setVisible(false);
  }
}

export default SaveButton;
