import { Table } from "antd";

// ExternalColorRingScheduleTable
export default function ExternalColorRingScheduleTable(props) {
  const { items } = props;
  const weekend = {
    0: "일",
    1: "월",
    2: "화",
    3: "수",
    4: "목",
    5: "금",
    6: "토",
  };
  const columns = [
    {
      key: "numbers",
      dataIndex: "numbers",
      title: "주차",
      align: "center",
    },
    {
      key: "virtualNumber",
      dataIndex: "virtualNumber",
      title: "050 번호",
      align: "center",
    },
    {
      key: "week",
      dataIndex: "week",
      title: "요일",
      align: "center",
      render: (text, record, index) => weekend[text],
    },
  ];
  return (
    <>
      <span className="custom_label">
        <span>*</span>
        <label>미 운영 컬러링 주차</label>
      </span>
      <Table
        columns={columns}
        dataSource={items}
        size={"small"}
        tableLayout="fixed"
        pagination={false}
        scroll={{ y: 300 }}
        style={{ marginBottom: "20px" }}
        rowKey={(e, index) => index}
      />
    </>
  );
}
