import { useState, useEffect } from "react"
import { Form, Radio, Button, Select, Space, Row, Col } from "antd"
import dayjs from "dayjs"
import _cloneDeep from "lodash/cloneDeep"
import { observer } from "mobx-react"

import SearchForm from "@components/form/SearchForm"
import DatePicker from "@components/picker/DatePicker"
import AgentSelect from "@components/select/AgentSelect"

import { useMobxStores } from "@stores/stores"

const initValue = {
  fromDate: dayjs().subtract(1, "month").format("YYYYMMDD"),
  toDate: dayjs().format("YYYYMMDD"),
  dateRange: [dayjs().subtract(1, "month"), dayjs()],
  anIsMobile: "all",
  rnIsMobile: "all",
  agentId: "all",
  byMinute: true,
}

const rnanOption = [
  { label: "전체", value: "all" },
  { label: "유선", value: true },
  { label: "무선", value: false },
]

const timeOption = [
  { label: "분 단위", value: true },
  { label: "초 단위", value: false },
]

export default observer(function SktlStatsSearch(props) {
  const { search, setByMinute } = props
  const [form] = Form.useForm()
  const { userStore } = useMobxStores()

  function onSearch() {
    let params = form?.getFieldValue()
    search(params)
  }

  useEffect(() => {
    setTimeout(() => {
      onSearch()
    }, 1000)
  }, [])

  return (
    <>
      <SearchForm onFinish={onSearch} footerButtons={true}>
        <Form form={form} initialValues={initValue}>
          <Form.Item label="조회 날짜" name="dateRange">
            <DatePicker.RangePicker
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  fromDate: e?.[0] ? parseInt(e[0].format("YYYYMMDD")) : null,
                  toDate: e?.[1] ? parseInt(e[1].format("YYYYMMDD")) : null,
                })
              }}
              placeholder={["시작일", "종료일"]}
              format="YYYY/MM/DD"
              allowClear={false}
            />
          </Form.Item>
          {userStore?.super && (
            <Form.Item name="agentId" label="사업장">
              <AgentSelect
                allSearch
                params={{ externalYN: true }}
                style={{ width: 200 }}
                allowClear
              />
            </Form.Item>
          )}
          <Row>
            <Col xs={24}>
              <Space size={10}>
                <Form.Item name="anIsMobile" label="발신">
                  <Radio.Group options={rnanOption} />
                </Form.Item>
                <Form.Item name="rnIsMobile" label="착신">
                  <Radio.Group options={rnanOption} />
                </Form.Item>
                <Form.Item name="byMinute" label="시간단위">
                  <Radio.Group
                    options={timeOption}
                    onChange={(e) => {
                      setByMinute(e.target.value)
                    }}
                  />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </SearchForm>
    </>
  )
})
