/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Descriptions,
  Form,
  Input,
  Row,
  Col,
  Divider,
  Radio,
  Button,
  Popconfirm,
} from "antd"

import DetailContainer from "@components/container/DetailContainer"
import ItemView from "@components/view/ItemView"
import OnlyNumberInput from "@components/input/OnlyNumberInput"

import { getQuery, objectClean, pushQuery } from "@utils/router"
import { useMobxStores } from "@stores/stores"
import { MediaQuery } from "@utils/style"
import { commaOfNumber } from "@utils/format"

import agentApi from "@api/agent"

const initialValues = {
  name: null,
  phoneNumber: null,
  memo: null,
  service: {
    blackAndWhite: "NONE",
    serviceYN: true,
    acYN: false,
    colorRingCount: null,
    informRingCount: null,
    recordCount: null,
    mappingLimit: null,
  },
}

export default function AgentStatusDetail(props) {
  const { id, setViewComponent, fetchList } = props
  const [form] = Form.useForm()
  const [agent, setAgent] = useState()
  const { globalStore } = useMobxStores()

  const navigate = useNavigate()
  const location = useLocation()
  const query = getQuery(location)
  const [viewSiderProps, setViewSiderProps] = useState({
    okTitle: "수정",
    collapsed: false,
    disabled: false,
    customHeader: true,
    bottomActionBar: true,
    loading: false,
    onOk: () => {
      form.submit()
    },
    onClose: () => {
      close()
    },
  })

  function close() {
    delete query.id
    delete query.add
    pushQuery(query, navigate)
    setViewComponent(null)
  }

  async function updateAgent() {
    // 사업장 수정
    try {
      const data = form.getFieldValue()
      if (data?.name === null || data?.name === "")
        return globalStore.warningNoti("사업장 명을 입력해주세요.")
      if (data?.phoneNumber === null || data?.phoneNumber === "")
        return globalStore.warningNoti("대표번호를 입력해주세요.")
      if (
        data?.service?.colorRingCount === null ||
        data?.service?.colorRingCount === ""
      )
        return globalStore.warningNoti("컬러링 수를 입력해주세요.")
      if (
        data?.service?.recordCount === null ||
        data?.service?.recordCount === ""
      )
        return globalStore.warningNoti("레코딩 수를 입력해주세요.")
      if (
        data?.service?.informRingCount === null ||
        data?.service?.informRingCount === ""
      )
        return globalStore.warningNoti("알림멘트 수를 입력해주세요.")
      if (
        data?.service?.mappingLimit === null ||
        data?.service?.mappingLimit === ""
      )
        return globalStore.warningNoti("맵핑제한 수를 입력해주세요.")
      if (data?.service?.serviceYN === null)
        return globalStore.warningNoti("서비스 여부를 선택해주세요.")
      if (data?.service?.acYN === null)
        return globalStore.warningNoti("알림서비스를 선택해주세요.")

      if (
        data?.service?.blackAndWhite === "NONE" ||
        !data?.service?.blackAndWhite
      ) {
        delete data.service.blackAndWhite
      }
      if (id) {
        await agentApi.updateAgent(id, data)
        globalStore.successNoti("사업장이 수정되었습니다.")
      } else {
        objectClean(data)
        await agentApi.createAgent(data)
        globalStore.successNoti("사업장이 등록되었습니다.")
        close()
      }
      fetchList()
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  async function fetchAgent() {
    // 업체 조회
    try {
      const agentProm = await agentApi.getAgentById(id)
      const agentRes = agentProm?.data?.data
      setAgent(agentRes)
      form.setFieldsValue(agentRes)
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  useEffect(() => {
    if (id) {
      fetchAgent()
      setViewSiderProps({ ...viewSiderProps, okTitle: "수정" })
    } else {
      setViewSiderProps({ ...viewSiderProps, okTitle: "등록", disabled: true })
    }
  }, [id])

  return (
    <>
      <DetailContainer
        viewSiderProps={{
          title: id ? "사업장 상세" : "사업장 등록",
          onClose: () => {
            close()
          },
        }}
      >
        <div css={styles.container}>
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <Row gutter={[16, { xs: 16, sm: 24 }]}>
              <Col xs={24} sm={12}>
                <Form.Item name="name" label="사업장 명" required>
                  <Input placeholder="사업장 명을 입력하세요." />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="phoneNumber" label="대표번호" required>
                  <OnlyNumberInput placeholder="번호를 입력하세요." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24}>
                <Form.Item name="memo" label="메모">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <div>
                  <span className="title">서비스설정 </span>
                  <span className="sub-title">(등록 할 수 있는 수)</span>
                  <Divider />
                </div>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="컬러링 수">
                        <Form.Item name={["service", "colorRingCount"]}>
                          <OnlyNumberInput />
                        </Form.Item>
                        개
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="레코딩 수">
                        <Form.Item name={["service", "recordCount"]}>
                          <OnlyNumberInput />
                        </Form.Item>
                        개
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="알림멘트 수">
                        <Form.Item name={["service", "informRingCount"]}>
                          <OnlyNumberInput />
                        </Form.Item>
                        개
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="맵핑제한 수">
                        <Form.Item name={["service", "mappingLimit"]}>
                          <OnlyNumberInput />
                        </Form.Item>
                        개
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="서비스 여부">
                        <Form.Item name={["service", "serviceYN"]}>
                          <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            options={[
                              { label: "활성", value: true },
                              { label: "비활성", value: false },
                            ]}
                          />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="알림서비스 ">
                        <Form.Item name={["service", "acYN"]}>
                          <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            options={[
                              { label: "활성", value: true },
                              { label: "비활성", value: false },
                            ]}
                          />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Descriptions>
                      <Descriptions.Item label="BlackWhite">
                        <Form.Item name={["service", "blackAndWhite"]}>
                          <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            options={[
                              { label: "None", value: "NONE" },
                              { label: "Black", value: "BLACK" },
                              { label: "White", value: "WHITE" },
                            ]}
                          />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Col>
              {id && (
                <Col xs={24} className="result-col">
                  <div>
                    <span className="title">현재 현황 </span>
                    <span className="sub-title">(현재 등록되어 있는 수)</span>
                    <Divider />
                  </div>
                  <ItemView>
                    <ItemView.Descriptions column={2}>
                      <Descriptions.Item label="컬러링 수">
                        {commaOfNumber(
                          agent?.agentServiceStatus?.colorRingCount || 0
                        ) + " 개"}
                      </Descriptions.Item>
                      <Descriptions.Item label="레코딩 수">
                        {commaOfNumber(
                          agent?.agentServiceStatus?.recordingCount || 0
                        ) + " 개"}
                      </Descriptions.Item>
                      <Descriptions.Item label="전체 가상번호 수">
                        {commaOfNumber(
                          agent?.agentServiceStatus?.totalVirtualNumberCount ||
                            0
                        ) + " 개"}
                      </Descriptions.Item>
                      <Descriptions.Item label="할당 가상번호 수">
                        {commaOfNumber(
                          agent?.agentServiceStatus?.usingVirtualNumberCount ||
                            0
                        ) + " 개"}
                      </Descriptions.Item>
                      <Descriptions.Item label="알림멘트 수">
                        {commaOfNumber(
                          agent?.agentServiceStatus?.informRingCount || 0
                        ) + " 개"}
                      </Descriptions.Item>
                    </ItemView.Descriptions>
                  </ItemView>
                </Col>
              )}
              <Col xs={24} align="right">
                <Popconfirm
                  title={`${id ? "수정" : "등록"}하시겠습니까?`}
                  onConfirm={updateAgent}
                  placement="topLeft"
                >
                  <Button type="primary">{id ? "수정" : "등록"}</Button>
                </Popconfirm>
              </Col>
            </Row>
          </Form>
        </div>
      </DetailContainer>
    </>
  )
}

const styles = {
  container: {
    "& .ant-form-item": {
      marginBottom: 0,
    },
    "& .ant-divider": {
      margin: "6px 0 12px",
    },
    "& .title": {
      fontWeight: 600,
      fontSize: 15,
    },
    "& .sub-title": {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& .ant-descriptions-row": {
      "& .ant-descriptions-item-label": {
        alignItems: "center",
      },
      "& input": {
        maxWidth: 90,
        marginRight: 7,
      },
    },
    "& .ant-radio-button-wrapper": {
      padding: "0 13px",
    },
    "& .result-col": {
      [MediaQuery[1]]: {
        "& .ant-descriptions-item-label, & .ant-descriptions-item-content": {
          padding: "6px 12px",
        },
      },
    },
  },
}
