import React, { useEffect, useState } from "react"
import PropType from "prop-types"

import { Input as AntdInput } from "antd"

OnlyNumberInput.propTypes = {
  disabled: PropType.bool,
  id: PropType.string,
  type: PropType.string,
  value: PropType.oneOfType([PropType.string, PropType.number]),
  onChange: PropType.func,
  onBlur: PropType.func,
  onPressEnter: PropType.func,
  placeholder: PropType.string,
  minNumber: PropType.bool,
}
OnlyNumberInput.defaultProps = {
  disabled: false,
  type: "text",
  value: "",
  onChange: () => {},
  onBlur: () => {},
  onPressEnter: () => {},
  placeholder: "숫자를 입력하세요.",
}

function OnlyNumberInput(props) {
  const {
    onChange,
    onPressEnter,
    value,
    inputRef,
    style,
    size,
    placeholder,
    disabled,
    type,
    minNumber,
    addonAfter,
  } = props

  const [maskedValue, setMaskedValue] = useState(value)

  useEffect(() => {
    setMaskedValue(mask(value))
  }, [value])

  return (
    <AntdInput
      ref={inputRef}
      value={maskedValue}
      onChange={emitChange}
      onBlur={onBlur}
      onPressEnter={onPressEnter}
      style={style}
      size={size}
      placeholder={placeholder}
      disabled={disabled}
      type={type}
      addonAfter={addonAfter}
    />
  )

  function onBlur(event) {
    const { onBlur } = props
    if (onBlur) onBlur(event)
  }

  function mask(value) {
    if (!value && !minNumber && value !== 0) return ""
    if (!value && minNumber) return 0

    return value
      .toString()
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1")
      .replace(/(\.\d{4}).+/g, "$1")
  }

  function emitChange(e) {
    e.target.value = mask(e.target.value)
    const { value } = e.target
    setMaskedValue(value)

    if (onChange) onChange(e)
  }
}

export default OnlyNumberInput
