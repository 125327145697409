import React, { useState, useEffect } from "react"

export default function Timer(props) {
  const { mm, ss, onFinish } = props

  const [minutes, setMinutes] = useState(parseInt(mm))
  const [seconds, setSeconds] = useState(parseInt(ss))

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1)
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown)
          if (onFinish !== undefined) onFinish()
        } else {
          setMinutes(parseInt(minutes) - 1)
          setSeconds(59)
        }
      }
    }, 1000)

    return () => {
      clearInterval(countdown)
    }
  }, [minutes, seconds])

  return (
    <span>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </span>
  )
}
