import qs from "qs"
import _isEmpty from "lodash/isEmpty"
import { isCompositeComponent } from "react-dom/test-utils"

export function getQuery(location) {
  if (_isEmpty(location.search)) return {}
  return qs.parse(location.search.substr(1))
}

export function pushQuery(query, navigate) {
  navigate("?" + qs.stringify(query))
}

export function hasRoles(roles, userStore) {
  const user = userStore?.user
  const userAgent = user?.agent?.id
  const userRole = userStore?.userRole
  // 슈퍼어드민일 경우 모든 화면 보여짐
  if (userAgent === 1) {
    //슈퍼어드민: stats/agent-list
    //일반유저: stats/detail-agent_list 둘중하나의 페이지만 보여주면됨
    return roles?.path === "/stats/detail_agent_list" ? false : true
  }
  let hasRoles = false
  const myRoutes = [] // 해당 계정 권한의 라우터만 배열에 포함
  let isSKView = false //SK Tel 관리자인경우
  const defaultPath = [
    "/service/service",
    "/manage/color_list",
    "/manage/inform_list",
    "/manage/vn_mapp_list",
    "/stats/detail_agent_list",
    // "/calllog/list",
  ]

  // 그 외 계정은 기준데이터의 propertiesValue에
  // 해당 agentId 와 동일한 값이 있을 경우 보여줌
  userRole?.forEach((role) => {
    let isView = false
    const properties = role?.propertiesValue?.split(",")
    properties.forEach((prop) => {
      if (parseInt(prop) === userAgent) {
        isView = true
        myRoutes.push(role)
        //SK유저인지 체크
        if (
          role?.accessWeb?.fullPath === "/manage/sktel_list" ||
          role?.accessWeb?.fullPath === "/calllog/ext_list" ||
          role?.accessWeb?.fullPath === "/stats/ext_list"
        ) {
          isSKView = true
        }
      }
    })
    if (roles?.subMenus) {
      if (
        role?.accessWeb?.dir.indexOf(roles?.key) > -1 &&
        (isView ||
          (!isSKView && defaultPath.indexOf(role?.accessWeb?.fullPath) > -1))
      ) {
        // subMenus 가 있는 상단 탭일 경우
        hasRoles = true
      }
    } else {
      // subMenus 가 없는 하단 탭일 경우
      if (role?.accessWeb?.fullPath === roles?.path && isView) {
        hasRoles = true
      }
    }
    //블랙,화이트 리스트 페이지 권한의 경우 둘중 하나의 권한만 가지더라도 블랙/화이트 관리 페이지 이용가능
    if (
      role?.accessWeb?.fullPath === "/manage/whitelist" &&
      roles?.path === "/manage/blacklist" &&
      isView
    ) {
      hasRoles = true
    }
    //해당 페이지는 sk유저를 제외한 모든유저 공통
    if (defaultPath.indexOf(roles?.path) > -1 && !isSKView) {
      hasRoles = roles?.path === "/stats/agent_list" ? false : true
      myRoutes.push(role)
    }
  })
  userStore.setMyRoutes(myRoutes)
  return hasRoles
}

// 오브젝트 null 삭제
export function objectClean(object) {
  for (var propName in object) {
    if (
      object[propName] === null ||
      object[propName] === undefined ||
      object[propName] === ""
    ) {
      delete object[propName]
    }
  }
  return object
}

// object안의 객체 null 제거
export function removeEmpty(obj) {
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === "object" ? removeEmpty(v) : v))
      .filter((v) => !(v == null || v === ""))
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === "object" ? removeEmpty(v) : v])
      .reduce((a, [k, v]) => (v === null || v === "" ? a : ((a[k] = v), a)), {})
  }
}

//해당 페이지의 접근권한 체크
export function checkAccessKey(keyName, userStore, location) {
  // 슈퍼유저는 모든 권한 접근 가능
  if (userStore?.super) return true
  if (userStore?.myRoutes?.length > 0) {
    const myRoutesByKey = userStore?.myRoutes?.find(
      (route) =>
        route?.propertiesKey === keyName &&
        route?.accessWeb?.fullPath === location?.pathname
    )
    return myRoutesByKey || myRoutesByKey !== undefined ? true : false
  } else {
    return false
  }
}

//해당 페이지에서 가지고 있는 접근권한 키값 목록들 (배열)
export function getAccessKeys(userStore, location) {
  const keys = []
  userStore?.myRoutes?.forEach((route) => {
    if (
      keys.indexOf(route.propertiesKey) < 0 &&
      route?.accessWeb?.fullPath === location?.pathname
    )
      keys.push(route.propertiesKey)
  })
  return keys
}

//페이지 권한체크(no path)
//해당 페이지의 접근권한 체크
export function checkAccessKeyNoPath(keyName, userStore) {
  // 슈퍼유저는 모든 권한 접근 가능
  if (userStore?.super) return true
  if (userStore?.myRoutes?.length > 0) {
    const myRoutesByKey = userStore?.myRoutes?.find(
      (route) => route?.propertiesKey === keyName
    )
    return myRoutesByKey ? true : false
  } else {
    return false
  }
}
