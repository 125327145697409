import { axiosInstance as axios } from "@app/axios"
import qs from "qs"

export default {
  //컬러링 리스트 조회
  getColoringManageList: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/color-rings/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  //컬러링 상세
  getColoringDetail: (colorRingId) => {
    const requestOptions = {
      method: "GET",
      url: `/api/color-rings/v1/${colorRingId}/complete`,
    }
    return axios(requestOptions)
  },
  //컬러링 등록
  postColoring: (params, formData) => {
    const requestOptions = {
      method: "POST",
      url: `/api/color-rings/v1/upload?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      headers: {
        "Content-type": "multipart/form-data",
      },
    }
    if (formData) requestOptions.data = formData
    return axios(requestOptions)
  },
  //컬러링 수정
  putColoring: (params, formData) => {
    const requestOptions = {
      method: "PUT",
      url: `/api/color-rings/v1/upload?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      headers: {
        "Content-type": "multipart/form-data",
      },
    }
    if (formData) requestOptions.data = formData
    return axios(requestOptions)
  },
  //알림멘트 목록조회
  getAlarmManageList: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/inform-rings/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  //알림멘트 상세
  getAlarmDetail: (informRingId) => {
    const requestOptions = {
      method: "GET",
      url: `/api/inform-rings/v1/${informRingId}/complete`,
    }
    return axios(requestOptions)
  },
  //알림멘트 등록
  postAlarm: (params, formData) => {
    const requestOptions = {
      method: "POST",
      url: `/api/inform-rings/v1/upload?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      headers: {
        "Content-type": "multipart/form-data",
      },
    }
    if (formData) requestOptions.data = formData
    return axios(requestOptions)
  },
  //알림멘트 수정
  putAlarm: (params, formData) => {
    const requestOptions = {
      method: "PUT",
      url: `/api/inform-rings/v1/upload?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      headers: {
        "Content-type": "multipart/form-data",
      },
    }
    if (formData) requestOptions.data = formData
    return axios(requestOptions)
  },
  //050번호(가상번호) 페이지 조회
  getVirtualMappingList: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/virtual-numbers/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  //컬러링 상세
  getColoringDetail: (colorRingId) => {
    const requestOptions = {
      method: "GET",
      url: `/api/color-rings/v1/${colorRingId}/complete`,
    }
    return axios(requestOptions)
  },

  //050번호 단일조회
  getVirtualNumber: (virtualNumber) => {
    const requestOptions = {
      method: "GET",
      url: `/api/virtual-numbers/v1/${virtualNumber}/complete`,
    }
    return axios(requestOptions)
  },

  //알림멘트 리스트 조회
  getInforingsByAgent: (agentId) => {
    const requestOptions = {
      method: "GET",
      url: `/api/inform-rings/v1/${agentId}/contains`,
    }
    return axios(requestOptions)
  },

  //에이전트별 컬러링 조회
  getColoringListByAgent: (agentId) => {
    const requestOptions = {
      method: "GET",
      url: `/api/color-rings/v1/${agentId}/contains`,
    }
    return axios(requestOptions)
  },
  //가상번호 수정
  putVirtualNumber: (params) => {
    const requestOptions = {
      method: "PUT",
      url: `/api/virtual-numbers/v1`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(params),
    }
    return axios(requestOptions)
  },
  //가상번호 대량관리
  putVirtualNumberRange: (params) => {
    const requestOptions = {
      method: "PUT",
      url: `/api/virtual-numbers/v1/range`,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(params),
    }
    return axios(requestOptions)
  },
  // 050번호 엑셀리스트다운로드
  getVirtualMappingExcel: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/virtual-numbers/v1/excel-export?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 5 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 050번호 엑셀폼 다운로드
  getVirtualMappingFormExcel: () => {
    const requestOptions = {
      method: "GET",
      url: `/api/virtual-numbers/v1/excel-form`,
      timeout: 5 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  //050번호 엑셀 업로드
  uploadVirtualMappingExcel: (file) => {
    const formData = new FormData()
    formData.append("file ", file)
    const requestOptions = {
      method: "POST",
      url: "/api/virtual-numbers/v1/excel-import",
      timeout: 5 * 60 * 1000,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    }
    return axios(requestOptions)
  },
  //알림멘트 조회(일반 유저용)
  getInforingsAllList: () => {
    const requestOptions = {
      method: "GET",
      url: `/api/inform-rings/v1/contains`,
    }
    return axios(requestOptions)
  },
  //컬러링 조회(일반 유저)
  getColoringsAllList: () => {
    const requestOptions = {
      method: "GET",
      url: `/api/color-rings/v1/contains`,
    }
    return axios(requestOptions)
  },
  //가상번호 이력 조회
  getVirtualNumHistory: (virtualNumber, params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/virtual-numbers/v1/${virtualNumber}/history?${qs.stringify(
        params,
        {
          arrayFormat: "repeat",
        }
      )}`,
    }
    return axios(requestOptions)
  },
}
