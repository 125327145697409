import { useState, useRef } from "react"
import dayjs from "dayjs"
import _cloneDeep from "lodash/cloneDeep"
import { observer } from "mobx-react"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"
import DownLoadButton from "@components/button/DownLoadButton"

import SktlStatsSearch from "@containers/stats/sktl/SktlStatsSearch"
import SktlStatsTable from "@containers/stats/sktl/SktlStatsTable"

import { objectClean } from "@utils/router"
import { useMobxStores } from "@stores/stores"
import { fileDownload } from "@utils/file"

import statisticsApi from "@api/statistics"

export default observer(function SktlStatsPage() {
  const { userStore, globalStore } = useMobxStores()
  const [byMinute, setByMinute] = useState(true)
  const tableRef = useRef()
  const [lastParams, setLastParams] = useState(null)
  const [loading, setLoading] = useState(false)

  function search(params) {
    const filter = _cloneDeep(params)

    const to = dayjs(filter.toDate.toString())
    const from = dayjs(filter.fromDate.toString())
    if (to && from && to.diff(from, "day", false) > 120)
      return globalStore.warningNoti("120일 이내의 기록만 조회할 수 있습니다.")
    if (filter.anIsMobile === "all") delete filter.anIsMobile
    if (filter.rnIsMobile === "all") delete filter.rnIsMobile
    if (filter.agentId === "all") delete filter.agentId
    delete filter.dateRange
    setLastParams(_cloneDeep(filter))
    delete filter.byMinute

    objectClean(filter)
    tableRef?.current?.fetchList(filter)
  }

  async function onDownload() {
    // 엑셀 다운로드
    setLoading(true)
    try {
      let filter = lastParams
      objectClean(filter)

      const res = userStore?.super
        ? await statisticsApi.getStatisticsSktlExcelBySuper(filter)
        : await statisticsApi.getStatisticsSktlExcelByUser(filter)
      const resData = res.data
      const today = dayjs().format("YYYYMMDDHHmm")
      const fileTitle = "SKTL_통계_"
      fileDownload(resData, fileTitle + today + ".xlsx")
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  return (
    <>
      <PageContainer>
        <PageHeader
          title="SKTL 통계"
          subTitle="SKTL 통계를 조회합니다."
          rightActions={
            <>
              <DownLoadButton
                onClick={onDownload}
                loading={loading}
                text="다운로드"
              />
            </>
          }
        />
        <SktlStatsSearch search={search} setByMinute={setByMinute} />
        <ContentContainer wrapper>
          <SktlStatsTable ref={tableRef} byMinute={byMinute} />
        </ContentContainer>
      </PageContainer>
    </>
  )
})
