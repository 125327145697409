/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { Badge, Button, Card, Col, Pagination, Row, Table } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Breakpoints, Styles } from "@utils/style";
import { useMediaQuery } from "react-responsive";
import GlobalProgress from "../common/GlobalProgress";
import { useInView } from "react-intersection-observer";
import { SoundFilled, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useMobxStores } from "@app/stores/stores";
import { observer } from "mobx-react-lite";

ResponsiveTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  cardProps: PropTypes.object,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  cardProps: PropTypes.object,
  cardType: PropTypes.string,
  isPage: PropTypes.bool,
  onChange: PropTypes.func,
  doubleDataRow: PropTypes.array,
  useExpand: PropTypes.bool,
};

ResponsiveTable.defaultProps = {
  columns: [],
  dataSource: [],
  pagination: {},
  loading: false,
  cardProps: {
    onChange: () => {},
    onClick: () => {},
  },
  cardType: "A",
  isPage: false,
  onChange: () => {},
  doubleDataRow: [],
  useExpand: false,
};

function ResponsiveTable(props) {
  /* props
    isPage : 페이지네이션 사용여부 없을 경우 스크롤로 페이징처리함
    type: 카드 view 타입 (A: table, B: 한줄로 나열),
    pagination  : (필수값) 
      -current : 현재 페이지
      -total : 총 데이터 수
      -pageSize : 한 페이지 당 데이터 수
      -totalPage : 총 페이지 수
    cardProps : antd 카드 props들, 
      -onClick : 카드 클릭 이벤트
    loading
    onChange
    doubleDataRow: 
    - 한줄에 두개의 데이터 표기
    - columns의 key값을 배열로 내려주면, 해당 컬럼값과 그다음 컬럼 값이 한줄에 같이 표기됩니다.
    - 마지막 컬럼값을 배열에 주면 안됨!!
    useExpand: 카드뷰 확장버튼 사용여부, 확장버튼 클릭시에 columns.expanded : true 인 값은 on/off,
    colomns: {
      ...antdProps,
      mobileViewStyle: typeA 테이블: td스타일
      cardViewOnClick: cardView columns 클릭시 이벤트
    }
  */
  const {
    columns,
    dataSource,
    pagination,
    cardProps,
    cardType,
    isPage,
    loading,
    onChange,
    doubleDataRow,
    useExpand,
  } = props;

  const [cardScroll, inView] = useInView();
  const cardRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: Breakpoints[1] });
  const [cardViewData, setCardViewData] = useState([]);
  const { globalStore } = useMobxStores();
  const [columnExpand, setColumnExpand] = useState([]);
  const doubleDataIndex = useRef(null);

  useEffect(() => {
    return () => {
      globalStore.setViewTopButton(false);
    };
  }, []);

  /* 스크롤바 내릴 경우, 이벤트발생 */
  useEffect(() => {
    if (
      inView &&
      !loading &&
      !isPage &&
      pagination.current < pagination.totalPage
    ) {
      onChange({
        current: pagination.current + 1,
        pageSize: pagination.pageSize,
      });
    }
  }, [inView]);

  useEffect(() => {
    if (pagination.current === 1 || isPage) {
      setCardViewData(dataSource);
      globalStore.setViewTopButton(false);
    } else {
      const clone = [...cardViewData];
      setCardViewData([...clone, ...dataSource]);
      globalStore.setViewTopButton(true);
    }
  }, [dataSource]);

  function setColumnData(dataIndex, data) {
    let columData = "-";
    if (
      dataIndex?.length === 2 &&
      data[dataIndex[0]] &&
      data[dataIndex[0]][dataIndex[1]]
    ) {
      columData = data[dataIndex[0]][dataIndex[1]] || "-";
    }
    if (
      dataIndex?.length === 3 &&
      data[dataIndex[0]] &&
      data[dataIndex[0]][dataIndex[1]] &&
      data[dataIndex[0]][dataIndex[1]][dataIndex[2]]
    ) {
      columData = data[dataIndex[0]][dataIndex[1]][dataIndex[2]] || "-";
    }
    // dataIndex 배열값은 3개까지 받음, 추가로 사용할 경우 밑에 추가하기 바람
    return typeof dataIndex === "object" ? columData : data[dataIndex] || "-";
  }

  function setColumnsRow(column, cIndex, data) {
    //한줄에 두개 데이터 표시여부
    const isDoubleDataRow = doubleDataRow.indexOf(column?.key) > -1;
    //중복 데이터 여부(이미 표기했던 데이터인지 확인)
    const isEvenColumn = cIndex === doubleDataIndex?.current + 1;
    let row = <></>;
    if (isDoubleDataRow) {
      //한줄에 2개의 데이터를 표기하는 경우임, 다음 컬럼값까지 한줄에 같이 표기함
      doubleDataIndex.current = cIndex;
      const evenCol = getFilteredColumns(columns)[cIndex + 1];
      row = (
        <tr key={column.key}>
          {/* 왼쪽 컬럼 데이터  */}
          <th colSpan={2}>{column.title}</th>
          <td
            style={{
              ...column.mobileViewStyle,
              color: column?.cardViewOnClick
                ? column?.mobileViewStyle?.color || "#FCB813"
                : null,
              fontWeight: column?.cardViewOnClick ? "bold" : null,
            }}
            onClick={() =>
              column?.cardViewOnClick ? column?.cardViewOnClick(data) : null
            }
            colSpan={3}
          >
            {column.render
              ? column.render(
                  setColumnData(column?.dataIndex, data),
                  data,
                  cIndex
                )
              : setColumnData(column?.dataIndex, data) || "-"}
          </td>
          {/* 오른쪽 컬럼 데이터 */}
          {evenCol ? (
            <>
              <th colSpan={2}>{evenCol?.title}</th>
              <td
                style={{
                  ...evenCol.mobileViewStyle,
                  color: evenCol?.cardViewOnClick
                    ? column?.mobileViewStyle?.color || "#FCB813"
                    : null,
                  fontWeight: column?.cardViewOnClick ? "bold" : null,
                }}
                onClick={() =>
                  evenCol?.cardViewOnClick
                    ? evenCol?.cardViewOnClick(data)
                    : null
                }
                colSpan={3}
              >
                {evenCol.render
                  ? evenCol.render(
                      setColumnData(evenCol?.dataIndex, data),
                      data,
                      cIndex + 1
                    )
                  : setColumnData(evenCol?.dataIndex, data) || "-"}
              </td>
            </>
          ) : (
            <>
              <td colSpan={2}></td>
              <td colSpan={3}></td>
            </>
          )}
        </tr>
      );
    } else if (isEvenColumn) {
      //데이터 중복 생성 방지 (doubleDataIndex에 포함된 값은 그전 for문에서 이미 추가됨)
      row = <Fragment key={column.key}></Fragment>;
    } else {
      //일반적인 경우 하줄에 하나의 데이터만 표현
      row = (
        <tr
          key={column.key}
          style={
            columns.length - 1 !== cIndex
              ? {
                  borderBottom: "1px solid rgba(0,0,0,.06)",
                }
              : null
          }
        >
          <th colSpan={4}>{column.title}</th>
          <td
            style={{
              ...column.mobileViewStyle,
              color: column?.cardViewOnClick
                ? column?.mobileViewStyle?.color || "#FCB813"
                : null,
              fontWeight: column?.cardViewOnClick ? "bold" : null,
            }}
            onClick={() =>
              column?.cardViewOnClick ? column?.cardViewOnClick(data) : null
            }
            colSpan={6}
          >
            {column.render
              ? column.render(
                  setColumnData(column?.dataIndex, data),
                  data,
                  cIndex
                )
              : setColumnData(column?.dataIndex, data) || "-"}
          </td>
        </tr>
      );
    }
    return row;
  }

  function getFilteredColumns(column, index) {
    const filterd = column.filter((col) => {
      if (useExpand) {
        if (columnExpand?.indexOf(index) > -1) return col;
        else return !col.expanded;
      } else return col;
    });
    return filterd;
  }

  return (
    <Row css={styles.responsiveTableWrap}>
      {!isMobile && (
        <Col xs={24}>
          <Table {...props} />
        </Col>
      )}
      {isMobile && loading && <GlobalProgress isBackColor />}
      {isMobile &&
        cardViewData.length > 0 &&
        cardViewData.map((data, dataIndex) => (
          <Col
            xs={24}
            ref={dataIndex == cardViewData.length - 1 ? cardScroll : cardRef}
            css={{ marginBottom: "8px" }}
            key={dataIndex}
          >
            <Card
              bordered={false}
              onClick={() => cardProps.onClick(data)}
              className="cardWrap"
              key={dataIndex}
              bodyStyle={{ padding: 0 }}
              {...cardProps}
            >
              {data?.isRecordIcon && (
                <SoundFilled
                  style={{
                    color: "#007def",
                    fontSize: "20px",
                    position: "absolute",
                    right: -6,
                    top: -6,
                  }}
                />
              )}
              {/* type : A table 뷰, type : B 한줄로 나열 */}
              {cardType == "A" && (
                <table className="typeA_Table" key={`table-${dataIndex}`}>
                  <colgroup>
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                  </colgroup>
                  <tbody>
                    {getFilteredColumns(columns, dataIndex).map(
                      (column, cIndex) =>
                        setColumnsRow(column, cIndex, data, dataIndex)
                    )}
                  </tbody>
                </table>
              )}
              {/* Type B: TODO 미완성, 사용할 경우 보완필요 */}
              {cardType == "B" &&
                columns &&
                columns.map((column, cIndex) => (
                  <div className="typeB" key={cIndex}>
                    <p className="titleFont">{column.title}</p>
                    <p>
                      {column.render
                        ? column.render(
                            setColumnData(column?.dataIndex, column, data),
                            data,
                            cIndex
                          )
                        : setColumnData(column?.dataIndex, column, data)}
                    </p>
                  </div>
                ))}
              {useExpand && (
                <>
                  {columnExpand.indexOf(dataIndex) > -1 ? (
                    <div
                      style={{ background: "#52c41a" }}
                      css={styles.expandBtn}
                      onClick={() =>
                        setColumnExpand(
                          columnExpand.filter(
                            (columns) => columns !== dataIndex
                          )
                        )
                      }
                    >
                      <MinusOutlined />
                    </div>
                  ) : (
                    <div
                      style={{ background: "#1890ff" }}
                      css={styles.expandBtn}
                      onClick={() => {
                        const clone = [...columnExpand];
                        clone.push(dataIndex);
                        setColumnExpand(clone);
                      }}
                    >
                      <PlusOutlined />
                    </div>
                  )}
                </>
              )}
            </Card>
          </Col>
        ))}

      {isMobile && cardViewData.length == 0 && (
        <div css={styles.noData}>
          <span>데이터가 없습니다.</span>
        </div>
      )}
      {isMobile && pagination.current && isPage && (
        <Col xs={24}>
          <Pagination
            defaultCurrent={pagination?.current}
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            size="small"
            onChange={(e) => onChange({ current: e, pageSize: 10 })}
          />
        </Col>
      )}
    </Row>
  );
}

export default observer(ResponsiveTable);

const styles = {
  responsiveTableWrap: {
    "& .ant-pagination": {
      textAlign: "center",
      marginTop: "10px",
    },
    "& .ant-badge": { width: "100%" },
    "& .cardWrap": {
      "& .ant-card-body": {
        border: "1px solid #D9D9D9",
        padding: "7px",
        borderRadius: "5px",
      },
    },
    "& .typeA_Table": {
      width: "100%",
      fontSize: "11px",
      "& td, th": {
        padding: "2px !important",
      },
      "& th": {
        background: "#F7F7F7",
        fontWeight: "bold",
      },
    },
    "& .typeB": {
      padding: "0px",
      "& .ant-card-body": {
        border: "1px solid #616893",
      },
      "& p": {
        fontSize: "12px",
      },
      "& .titleFont": {
        fontSize: "12px",
        color: "#8287a8",
        margin: 0,
      },
    },
  },
  noData: {
    height: "200px",
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
  },
  expandBtn: {
    position: "absolute",
    right: -3,
    bottom: -3,
    borderRadius: "100px",
    width: "20px",
    height: "20px",
    color: "#ffffff",
    fontSize: "12px",
    textAlign: "center",
    background: "black",
    ...Styles.rowCenterCenter,
  },
};
