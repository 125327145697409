import { useEffect } from "react"
import { Form, Input, Radio, Select, Space, Row, Col } from "antd"

import AddButton from "@components/button/AddButton"
import SearchForm from "@components/form/SearchForm"
import DatePicker from "@components/picker/DatePicker"

const initValue = {
  fromDate: null,
  toDate: null,
  visible: "all",
  type: "all",
  keywordType: "title",
  keyword: null,
  sort: "created,desc",
}

export default function NoticeStatusSearch(props) {
  const { search, openViewSider } = props
  const [form] = Form.useForm()

  function onSearch() {
    const params = form.getFieldValue()
    search(params)
  }

  useEffect(() => {
    onSearch()
  }, [])

  return (
    <>
      <SearchForm
        onFinish={onSearch}
        footerButtons={
          <AddButton
            onClick={() => openViewSider("add", null)}
            text="공지사항 등록"
          />
        }
      >
        <Form form={form} initialValues={initValue}>
          <Form.Item label="조회 날짜" name="dateRange">
            <DatePicker.RangePicker
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  fromDate: e?.[0] ? parseInt(e[0].format("YYYYMMDD")) : null,
                  toDate: e?.[1] ? parseInt(e[1].format("YYYYMMDD")) : null,
                })
              }}
              placeholder={["시작일", "종료일"]}
              format="YYYY/MM/DD"
            />
          </Form.Item>
          <Form.Item name="visible" label="공지 여부">
            <Radio.Group
              options={[
                { label: "전체", value: "all" },
                { label: "활성", value: true },
                { label: "비활성", value: false },
              ]}
            ></Radio.Group>
          </Form.Item>
          <Form.Item name="type" label="타입">
            <Radio.Group
              options={[
                { label: "전체", value: "all" },
                { label: "로그인", value: "LOGIN" },
                { label: "메인", value: "MAIN" },
              ]}
            ></Radio.Group>
          </Form.Item>
          <Row>
            <Col sm={24} md={16} lg={18}>
              <Space>
                <Form.Item name="keywordType">
                  <Select
                    options={[
                      { label: "타이틀", value: "title" },
                      { label: "내용", value: "content" },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="keyword">
                  <Input />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
      </SearchForm>
    </>
  )
}
