/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom"
import { Form, Input, Button, Row, Col, Card, Popconfirm } from "antd"
import { observer } from "mobx-react"

import { useMobxStores } from "@stores/stores"
import { MediaQuery } from "@utils/style"
import { isPassword } from "@utils/validation"

import userApi from "@api/user"

const initialValues = {
  updatePW: null,
  checkUpdatePW: null,
}

export default observer(function ExpiredPage() {
  const [pwForm] = Form.useForm()
  const navigate = useNavigate()
  const { userStore, globalStore } = useMobxStores()

  async function extendPw() {
    // 비밀번호 만료일 연장(6개월)
    try {
      await userApi.extendPassword()
      await userStore.refreshToken()
      navigate(userStore?.myRoutes?.[0]?.accessWeb?.fullPath || "/")
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  async function updatePw() {
    // 비밀번호 변경
    try {
      const formValue = pwForm?.getFieldValue()
      const params = {
        updatePW: formValue?.updatePW,
        checkUpdatePW: formValue?.checkUpdatePW,
      }

      if (!isPassword(params?.updatePW))
        return globalStore.warningNoti("비밀번호 양식을 확인해주세요")
      if (params?.updatePW !== params?.checkUpdatePW)
        return globalStore.warningNoti(
          "비밀번호가 일치하지 않습니다. 다시 확인해주세요"
        )

      await userApi.updatePasswordNoAuth(params)
      await userStore.refreshToken()
      navigate(userStore?.myRoutes?.[0]?.accessWeb?.fullPath || "/")
    } catch (e) {
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  return (
    <div css={styles.container}>
      <Form form={pwForm} initialValues={initialValues}>
        <Card>
          <Col className="title">비밀번호 만료</Col>
          <p>
            비밀번호가 만료 되어 비밀번호 변경이 필요 합니다. 비밀번호 변경
            해주세요.
          </p>
          <Form.Item name="updatePW" label="신규 비밀번호" className="mb-0">
            <Input.Password />
          </Form.Item>
          <p style={{ fontSize: 12, color: "#FF0000" }}>
            {`비밀번호는 한글 제외,영문(대문자 1글자 이상
            필수),숫자,특수문자[~!@#$%^&*()_+|
            <>?:{}]를 모두 포함하여야 합니다.`}
          </p>
          <Form.Item name="checkUpdatePW" label="신규 비밀번호 확인">
            <Input.Password />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Popconfirm
                title={`비밀번호를 나중에 변경하시겠습니까?`}
                onConfirm={extendPw}
                placement="topLeft"
              >
                <Button block size="large">
                  나중에 변경
                </Button>
              </Popconfirm>
            </Col>
            <Col span={12}>
              <Popconfirm
                title={`비밀번호를 변경하시겠습니까?`}
                onConfirm={updatePw}
                placement="topLeft"
              >
                <Button block type="primary" size="large">
                  변경
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  )
})

const styles = {
  container: {
    height: "60vh",
    [MediaQuery[1]]: {
      height: "70vh",
    },
    display: "flex",
    alignItems: "center",
    "& .ant-card": {
      minWidth: "20vw",
      [MediaQuery[1]]: {
        minWidth: "50vw",
        "& .ant-card-body": {
          padding: 12,
          "& .ant-form-item": {
            marginBottom: 12,
          },
        },
      },
      "& p": {
        fontSize: "13px",
        color: "#7C7C7C",
        marginBottom: 24,
      },
      "& .title": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "22px",
        padding: "10px 0",
        borderBottom: "1px solid #D9D9D9;",
        marginBottom: 14,
      },
      "& button": {
        marginTop: 16,
      },
    },
  },
}
