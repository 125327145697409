/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { Modal, Carousel, Row, Col, Checkbox } from "antd"

import { useMobxStores } from "@stores/stores"

export default function Notification({ open, setOpen }) {
  const [expire, setExpire] = useState(false)
  const { userStore } = useMobxStores()

  function close() {
    if (expire) {
      const now = new Date()
      localStorage.setItem(
        userStore?.noti?.type === "MAIN" ? "noti-ex-main" : "noti-ex-login",
        new Date(now.setDate(now.getDate() + 1)).getTime()
      )
    }
    userStore?.clearNoti()
    setOpen(false)
  }

  return (
    <Modal
      footer={[
        <Checkbox onChange={(e) => setExpire(e.target.checked)} key={0}>
          하루 동안 열지 않기
        </Checkbox>,
      ]}
      open={open}
      title="공지 사항"
      onCancel={close}
      bodyStyle={{ padding: "12px" }}
      destroyOnClose
      css={styles.container}
    >
      {userStore?.noti?.open && userStore?.noti?.info?.length > 0 && (
        <Carousel autoplay autoplaySpeed={1000 * 10}>
          {userStore?.noti?.info?.map((e) => {
            let content = e.content.replaceAll("\n", " <br/>")
            // URL 일 경우 a태그로 변환
            const urls = content.match(/(https?:\/\/[^ ]*)|(http?:\/\/[^ ]*)/g)
            if (urls != null) {
              urls.forEach((url) => {
                content = content.replace(
                  url,
                  "<a href=" + url + ">" + url + "</a>"
                )
              })
            }
            return (
              <div key={e.id}>
                <Row wrap>
                  <Col xs={24} css={styles.header}>
                    {e.title}
                  </Col>
                  <Col xs={24} css={styles.body}>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </Col>
                </Row>
              </div>
            )
          })}
        </Carousel>
      )}
    </Modal>
  )
}

const styles = {
  container: {
    "& .slick-dots": {
      bottom: "0 !important",
      "& li button": {
        background: "black",
      },
      "& li.slick-active button": {
        background: "#FCB813",
      },
    },
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
  },
  body: {
    minHeight: 200,
    maxHeight: 450,
    overflowY: "auto",
    padding: "12px 0",
  },
}
