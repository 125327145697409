import { useState, forwardRef, useImperativeHandle } from "react"
import { ConfigProvider, Empty } from "antd"
import dayjs from "dayjs"

import ResponsiveTable from "@components/table/ResponsiveTable"

import { commaOfNumber } from "@utils/format"
import { useMobxStores } from "@stores/stores"

import noticeApi from "@api/notice"

export default forwardRef(function NoticeStatusTable(props, ref) {
  const { lastParams, setLastParams, openViewSider } = props
  const { globalStore } = useMobxStores()
  const [noticeList, setNoticeList] = useState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    // 페이징처리
    total: 0,
    current: 1,
    pageSize: 10,
  })

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchNoticeList({
        ...params,
        page: params?.page || 0,
        size: params?.size || pagination.pageSize,
      })
    },
  }))

  // 공지사항 리스트
  async function fetchNoticeList(params) {
    setLoading(true)
    try {
      const noticeProm = await noticeApi.getNotices(params)
      setNoticeList(noticeProm?.data?.data)
      const meta = noticeProm?.data?.meta
      const pageMeta = {
        total: meta.totalCount,
        current: meta.page.pageNumber + 1,
        pageSize: meta.page.pageSize,
        totalPage: meta.totalPage,
      }
      setPagination(pageMeta)
      setLoading(false)
      setLastParams({
        ...params,
        page: meta.page.pageNumber || 0,
        size: meta.page.pageSize || 10,
      })
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "아이디",
      align: "center",
      className: "selectable",
      render(text) {
        return text || "-"
      },
      onCell: (record) => ({
        onClick: () => openViewSider("detail", record.id),
      }),
    },
    {
      key: "title",
      dataIndex: "title",
      title: "제목",
      align: "center",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "content",
      dataIndex: "content",
      title: "내용",
      align: "center",
      width: 500,
      render(text) {
        return text || "-"
      },
    },
    {
      key: "type",
      dataIndex: "type",
      title: "타입",
      align: "center",
      render(_, record) {
        return record?.type === "LOGIN" ? "로그인" : "메인"
      },
    },
    {
      key: "visible",
      dataIndex: "visible",
      title: "공지 여부",
      align: "center",
      render(_, record) {
        return record?.visible ? "활성" : "비활성"
      },
    },
    {
      key: "fromDate",
      dataIndex: "fromDate",
      title: "공지 시작일",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "toDate",
      dataIndex: "toDate",
      title: "공지 종료일",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "created",
      dataIndex: "created",
      title: "등록일",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD") : "-"
      },
    },
  ]
  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="검색결과가 없습니다." />}
    >
      <p style={{ fontWeight: "bold" }}>
        공지사항 수 : {commaOfNumber(pagination?.total)}개
      </p>
      <ResponsiveTable
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={noticeList}
        pagination={pagination}
        onChange={(e) => {
          const filter = {
            ...lastParams,
            page: e.current - 1,
            size: e.pageSize,
          }
          fetchNoticeList(filter)
        }}
        scroll={{ x: 1600 }}
        loading={loading}
      />
    </ConfigProvider>
  )
})
