import { useEffect } from "react"
import { Form, Radio, Button, Select, Space, Row, Col } from "antd"
import dayjs from "dayjs"
import _cloneDeep from "lodash/cloneDeep"
import { observer } from "mobx-react"

import SearchForm from "@components/form/SearchForm"
import DatePicker from "@components/picker/DatePicker"

const initValue = {
  fromDate: dayjs().format("YYYYMM01"),
  toDate: dayjs().format("YYYYMM") + dayjs().daysInMonth(),
  dateRange: dayjs(),
  anIsMobile: "all",
  rnIsMobile: "all",
  inbound: "1,2",
  outbound: "1,2",
  byVnScope: "all",
}

const rnanOption = [
  { label: "전체", value: "all" },
  { label: "유선", value: true },
  { label: "무선", value: false },
]

const boundOption = [
  { label: "전체", value: "1,2" },
  { label: "onse", value: "1" },
  { label: "skb", value: "2" },
  { label: "분류안함", value: "None" },
]

const vnOption = [
  { label: "없음", value: "all" },
  { label: "050번호", value: true },
]

export default observer(function RouteStatsSearch(props) {
  const { search } = props
  const [form] = Form.useForm()

  function onSearch() {
    let params = form?.getFieldValue()
    search(params)
  }

  useEffect(() => {
    onSearch()
  }, [])

  return (
    <>
      <SearchForm onFinish={onSearch} footerButtons={true}>
        <Form form={form} initialValues={initValue}>
          <Form.Item label="조회 날짜" name="dateRange">
            <DatePicker.MonthPicker
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldValue(),
                  fromDate: e ? parseInt(e.format("YYYYMM01")) : null,
                  toDate: e
                    ? parseInt(e.format("YYYYMM") + e.daysInMonth())
                    : null,
                })
              }}
              placeholder={"조회 월 선택"}
              format="YYYY/MM"
            />
          </Form.Item>
          <Row>
            <Col xs={24}>
              <Space size={10}>
                <Form.Item name="anIsMobile" label="발신">
                  <Radio.Group options={rnanOption} />
                </Form.Item>
                <Form.Item name="rnIsMobile" label="착신">
                  <Radio.Group options={rnanOption} />
                </Form.Item>
              </Space>
            </Col>
          </Row>

          <Space size={10}>
            <Form.Item name="inbound" label="인바운드">
              <Select
                options={boundOption}
                style={{
                  width: 100,
                }}
              />
            </Form.Item>
            <Form.Item name="outbound" label="아웃바운드">
              <Select
                options={boundOption}
                style={{
                  width: 100,
                }}
              />
            </Form.Item>
            <Form.Item name="byVnScope" label="가상번호">
              <Select
                options={vnOption}
                style={{
                  width: 100,
                }}
              />
            </Form.Item>
          </Space>
        </Form>
      </SearchForm>
    </>
  )
})
