import { useState, forwardRef, useImperativeHandle } from "react"
import { ConfigProvider, Empty } from "antd"
import dayjs from "dayjs"

import ResponsiveTable from "@components/table/ResponsiveTable"

import { commaOfNumber, phoneFormatter } from "@utils/format"
import { useMobxStores } from "@stores/stores"

import logApi from "@api/log"

export default forwardRef(function AuthlogTable(props, ref) {
  const { lastParams } = props
  const { globalStore } = useMobxStores()
  const [logList, setLogList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    // 페이징처리
    total: 0,
    current: 1,
    pageSize: 10,
  })

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchLogList({
        ...params,
        page: 0,
        size: pagination.pageSize,
      })
    },
  }))

  // 콜로그 리스트
  async function fetchLogList(params) {
    setLoading(true)
    try {
      const logProm = await logApi.getAutoLogsBySuper(params)
      setLogList(logProm?.data?.data)
      const meta = logProm?.data?.meta
      const pageMeta = {
        total: meta.totalCount,
        current: meta.page.pageNumber + 1,
        pageSize: meta.page.pageSize,
        totalPage: meta.totalPage,
      }
      setPagination(pageMeta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  const columns = [
    {
      key: "agentName",
      dataIndex: ["agent", "name"],
      title: "업체명",
      align: "center",
      render(text) {
        return text || "-"
      },
    },
    {
      key: "callingNumber",
      dataIndex: "callingNumber",
      title: "발신번호",
      align: "center",
      render(text) {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "calledNumber",
      dataIndex: "calledNumber",
      title: "착신번호",
      align: "center",
      render(text) {
        return text ? phoneFormatter(text) : "-"
      },
    },
    {
      key: "placeCallDateTime",
      dataIndex: "placeCallDateTime",
      title: "호 시작 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "answerCallDateTime",
      dataIndex: "answerCallDateTime",
      title: "호 연결 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      key: "releaseCallDateTime",
      dataIndex: "releaseCallDateTime",
      title: "호 종료 시간",
      align: "center",
      render(text) {
        return text ? dayjs(text).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
  ]
  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="검색결과가 없습니다." />}
    >
      <p style={{ fontWeight: "bold" }}>
        콜로그 수 : {commaOfNumber(pagination?.total)}건
      </p>
      <ResponsiveTable
        rowKey={(record) => record.sequence}
        columns={columns}
        dataSource={logList}
        pagination={pagination}
        onChange={(e) => {
          const filter = {
            ...lastParams,
            page: e.current - 1,
            size: e.pageSize,
          }
          fetchLogList(filter)
        }}
        loading={loading}
      />
    </ConfigProvider>
  )
})
