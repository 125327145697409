/** @jsxImportSource @emotion/react */
import { Button } from "antd"
import { CloudDownloadOutlined } from "@ant-design/icons"

function DownLoadButton(props) {
  const { text, type } = props
  return (
    <Button type={type} {...props} icon={<CloudDownloadOutlined />}>
      {text}
    </Button>
  )
}

export default DownLoadButton
