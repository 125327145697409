/** @jsxImportSource @emotion/react */
import { useEffect } from "react"
import { observer } from "mobx-react"
import { useNavigate, useLocation } from "react-router-dom"

import { MediaQuery } from "@utils/style"
import { getQuery, pushQuery } from "@utils/router"

import { Space, Button, Popconfirm } from "antd"

import PageHeader from "@components/container/HeaderContainer"
// import Popconfirm from '@atoms/Popconfirm';

import PropTypes from "prop-types"

DetailContainer.propTypes = {
  viewSiderProps: PropTypes.object,
}
DetailContainer.defaultProps = {
  viewSiderProps: {
    customHeader: false, // header를 직접 만들것인지 여부(제목 오른쪽에 버튼생성 필요시 직접 만듬)
    title: "제목", // 제목
    okTitle: "등록", // 버튼 이름
    bottomActionBar: true, // 하단 버튼 두개 생성 여부
    component: null, // viewSider에 render할 component
    disabled: true, // 등록, 수정 버튼 disabled
    loading: false, // 버튼 로딩
    onClose: () => {}, // viewSider 닫기
    onOk: () => {}, // submit Popconfirm 확인 버튼 클릭 시 실행 함수
    onCancel: () => {}, // submit Popconfirm 취소 버튼 클릭 시 실행 함수
    onBack: () => {}, // 필요시 사용
    fullScreen: false, // 꽉찬화면 여부(default: width 40%)
  },
}

function DetailContainer(props) {
  const { viewSiderProps } = props
  const {
    customHeader,
    title,
    okTitle,
    bottomActionBar,
    onClose,
    cancelTitle,
    disabled,
    loading,
    onBack,
    onOk,
    onCancel,
  } = viewSiderProps
  const navigate = useNavigate()
  const location = useLocation()
  const query = getQuery(location)

  // ViewSider 종료
  const closeView = () => {
    delete query.id
    delete query.add
    pushQuery(query, navigate, {
      state: { viewSiderEvent: true },
    })
    onClose()
  }

  let additionalProps = {
    onClose: closeView,
  }

  let initialButtonProps = {}
  if (onClose !== null) {
    additionalProps.onClose = closeView
  }
  if (onBack !== null) {
    additionalProps.onBack = onBack
  }
  if (onOk !== null) {
    initialButtonProps.onOk = onOk
  }
  if (onCancel !== null) {
    initialButtonProps.onCancel = onCancel
  }

  useEffect(() => {
    const handleEsc = (event) => {
      // ESC로 사이드뷰 닫기
      if (event.keyCode === 27) {
        closeView()
      }
    }
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [])

  return (
    <div
      css={[
        styles.container,
        {
          display: viewSiderProps ? "flex" : "none",
          width: viewSiderProps.fullScreen ? "100%" : "40%",
        },
      ]}
    >
      {!customHeader && (
        <PageHeader title={title} breadcrumb={false} {...additionalProps} />
      )}

      <div css={styles.renderWrapper}>{props.children}</div>
      {bottomActionBar && (
        <div css={styles.bottomActionBarContainer}>
          <Space>
            <Button onClick={closeView}>
              {cancelTitle ? cancelTitle : "취소"}
            </Button>
            <Popconfirm
              title={`정말 ${okTitle}하시겠습니까?`}
              onConfirm={initialButtonProps.onOk}
              disabled={disabled}
              placement="topLeft"
            >
              <Button type="primary" loading={loading} disabled={disabled}>
                {okTitle}
              </Button>
            </Popconfirm>
          </Space>
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    backgroundColor: "#fff",
    boxShadow: "-2px 2px 10px rgba(0, 0, 0, 0.15)",
    height: "100%",
    zIndex: 4,
    display: "flex",
    flexDirection: "column",
    [MediaQuery[2]]: {
      position: "fixed",
      top: 48,
      right: 0,
      width: "60%",
      height: `calc(100% - 48px)`,
    },
    [MediaQuery[1]]: {
      width: "100%",
    },
  },
  renderWrapper: {
    flex: 1,
    overflowY: "auto",
    padding: "24px",
  },
  bottomActionBarContainer: {
    padding: "24px 24px",
    display: "flex",
    justifyContent: "flex-end",
  },
}

export default observer(DetailContainer)
