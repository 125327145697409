import SearchForm from "@app/components/form/SearchForm"
import DatePicker from "@app/components/picker/DatePicker"
import { Button, Form, Input, Radio, Select, Space } from "antd"
import { useMobxStores } from "@app/stores/stores"
import { observer } from "mobx-react"
import AgentSelect from "@app/components/select/AgentSelect"
import { checkAccessKeyNoPath } from "@app/utils/router"
import AddButton from "@components/button/AddButton"

export default observer(function ColoringManageSearch(props) {
  const { onSearch, openViewSider, query, closeDetail, isAlarm } = props
  const { userStore } = useMobxStores()
  const [form] = Form.useForm()
  //음원
  const typeOptions = [
    { label: "전체", value: "" },
    { label: "WAV", value: "1" },
    { label: "TTS", value: "2" },
  ]
  //종류
  const fgOptions = [
    { label: "전체", value: "" },
    { label: "기본", value: "1" },
    { label: "휴무", value: "2" },
    { label: "영업종료", value: "3" },
  ]
  const initValues = {
    dateRange: [null, null],
    type: "",
    fg: "",
    description: "",
    corporation: "",
    agentId: "",
  }

  return (
    <SearchForm
      onFinish={() => onSearch(form.getFieldsValue())}
      footerButtons={
        <AddButton
          onClick={() => {
            if (query?.add) {
              closeDetail()
            } else {
              openViewSider("add")
            }
          }}
          text={isAlarm ? "알림멘트 등록" : "컬러링 등록"}
        />
      }
    >
      <Form form={form} initialValues={initValues}>
        <Form.Item name="dateRange" label="조회 날짜">
          <DatePicker.RangePicker />
        </Form.Item>
        {userStore?.super && (
          <Form.Item label="사업장" name="agentId">
            <AgentSelect showSearch style={{ width: "280px" }} />
          </Form.Item>
        )}
        {checkAccessKeyNoPath("USINGTTS", userStore) && (
          <Form.Item name="type" label="음원 형식">
            <Radio.Group options={typeOptions} />
          </Form.Item>
        )}
        <Space>
          <Form.Item name="fg" label="종류">
            <Select options={fgOptions} style={{ width: "122px" }} />
          </Form.Item>
          <Form.Item label="설명" name="description">
            <Input />
          </Form.Item>
        </Space>
      </Form>
    </SearchForm>
  )
})
