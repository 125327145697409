/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useInView } from "react-intersection-observer"
import { Card, Badge, Divider } from "antd"
import { SoundOutlined } from "@ant-design/icons"
import dayjs from "dayjs"

import {
  commaOfNumber,
  phoneFormatter,
  virtualNumberFormatter,
} from "@utils/format"
import { checkAccessKey } from "@utils/router"
import { useMobxStores } from "@stores/stores"

export default function CalllogTableMobile(props) {
  const { dataSource, pagination, openModal, loading, setExpend, onChange } =
    props
  const { userStore, globalStore } = useMobxStores()
  const location = useLocation()

  const [cardScroll, inView] = useInView()
  const [viewData, setViewData] = useState(dataSource)
  const [isScroll, setIsScroll] = useState(false)

  /* 스크롤바 내릴 경우, 이벤트발생 */
  useEffect(() => {
    if (inView && !loading && pagination.current < pagination.totalPage) {
      setIsScroll(true)
      onChange({
        current: pagination.current + 1,
        pageSize: pagination.pageSize,
      })
      globalStore.setViewTopButton(true)
    }
  }, [inView])

  useEffect(() => {
    if (pagination.current === 1 || !isScroll) {
      setViewData(dataSource)
    } else {
      const clone = [...viewData]
      setViewData([...clone, ...dataSource])
      setIsScroll(false)
    }
  }, [dataSource])

  return (
    <>
      {viewData?.map((e, i) => {
        return (
          <Card
            bordered={false}
            loading={loading}
            css={styles.wrapper}
            key={e.sequence}
            ref={i == viewData?.length - 1 ? cardScroll : null}
          >
            {e.callRecord && e.duration && (
              <Badge
                css={styles.audio}
                count={
                  <SoundOutlined
                    onClick={() => {
                      openModal(e)
                    }}
                  />
                }
              />
            )}

            <Card.Grid css={[styles.span1, styles.th]}>사업장명</Card.Grid>
            <Card.Grid css={styles.span4}>{e?.agent?.name || "-"}</Card.Grid>

            <Divider />

            {checkAccessKey("STORE", userStore, location) && (
              <>
                <Card.Grid css={[styles.span1, styles.th]}>상점명</Card.Grid>
                <Card.Grid css={styles.span4}>{e?.memo1 || "-"}</Card.Grid>
              </>
            )}

            <Divider />

            <div className="th-row">
              <Card.Grid css={[styles.span2, styles.th]}>발신번호</Card.Grid>
              <Divider type="vertical" />
              <Card.Grid css={[styles.span2, styles.th]}>착신번호</Card.Grid>
              <Divider type="vertical" />
              <Card.Grid css={[styles.span2, styles.th]}>가상번호</Card.Grid>
            </div>
            <Card.Grid css={[styles.center, styles.span2]}>
              {e.callingNumber ? phoneFormatter(e.callingNumber) : "-"}
            </Card.Grid>
            <Card.Grid css={[styles.center, styles.span2]}>
              {e.realNumber ? phoneFormatter(e.realNumber) : "-"}
            </Card.Grid>
            <Card.Grid css={[styles.center, styles.span2]}>
              {e.virtualNumber ? virtualNumberFormatter(e.virtualNumber) : "-"}
            </Card.Grid>

            <Divider />

            <div className="th-row">
              <Card.Grid css={[styles.span3, styles.th]}>
                호 시작 시간
              </Card.Grid>
              <Divider type="vertical" />
              <Card.Grid css={[styles.span3, styles.th]}>
                호 응답 시간
              </Card.Grid>
            </div>
            <Card.Grid css={[styles.center, styles.span3]}>
              {e.incomeDateTime
                ? dayjs(e.incomeDateTime).format("YYYY-MM-DD HH:mm:ss")
                : "-"}
            </Card.Grid>
            <Card.Grid css={[styles.center, styles.span3]}>
              {e.outAnswerDateTime
                ? dayjs(e.outAnswerDateTime).format("YYYY-MM-DD HH:mm:ss")
                : "-"}
            </Card.Grid>

            <div className="th-row">
              <Card.Grid css={[styles.span3, styles.th]}>
                호 종료 시간
              </Card.Grid>
              <Divider type="vertical" />
              <Card.Grid css={[styles.span3, styles.th]}>통화시간</Card.Grid>
            </div>
            <Card.Grid css={[styles.center, styles.span3]}>
              {e.releaseDateTime
                ? dayjs(e.releaseDateTime).format("YYYY-MM-DD HH:mm:ss")
                : "-"}
            </Card.Grid>
            <Card.Grid css={[styles.center, styles.span3]}>
              {e.duration ? commaOfNumber(e.duration) : "-"}
            </Card.Grid>

            {e.expand && (
              <>
                <Divider />
                <Card.Grid css={[styles.span1, styles.th]}>CAUSE</Card.Grid>
                <Card.Grid css={styles.span4}>{e?.cause || "-"}</Card.Grid>

                <Divider />
                <Card.Grid css={[styles.span1, styles.th]}>메모</Card.Grid>
                <Card.Grid css={styles.span4}>{e?.memo1 || "-"}</Card.Grid>

                {checkAccessKey("MEMO2", userStore, location) && (
                  <>
                    <Divider />
                    <Card.Grid css={[styles.span1, styles.th]}>메모2</Card.Grid>
                    <Card.Grid css={styles.span4}>{e?.memo2 || "-"}</Card.Grid>
                  </>
                )}
              </>
            )}

            <Badge
              onClick={() => setExpend(viewData, i)}
              color={e.expand ? "green" : "blue"}
              css={styles.more}
              count={e.expand ? "-" : "+"}
            />
          </Card>
        )
      })}
    </>
  )
}

const styles = {
  wrapper: {
    border: "1px solid #D9D9D9",
    borderRadius: 5,
    fontSize: 11,
    marginBottom: 24,
    "& .ant-card-body": {
      padding: 0,
      "& .th-row": {
        background: "#F0F0F0",
      },
    },
    "& .ant-card-grid": {
      padding: "2px 6px",
      boxShadow: "none",
      display: "inline-block",
    },
    "& .ant-divider": {
      margin: 0,
    },
    "& .ant-divider-vertical": {
      borderLeft: "1px solid #D9D9D9",
    },
  },
  audio: {
    position: "absolute",
    right: -10,
    top: -10,
    background: "red",
    color: "white",
    borderRadius: "50px",
    padding: "5px",
  },
  more: {
    position: "absolute",
    right: -10,
    bottom: -10,
  },
  span1: { width: "25%" },
  span2: { width: "33.09%" },
  span3: { width: "49.8%" },
  span4: { width: "75%" },
  span5: { width: "100%" },
  center: { textAlign: "center" },
  th: { textAlign: "center", background: "#F0F0F0" },
}
