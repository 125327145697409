/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { Button, Card, Input } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useMobxStores } from "@app/stores/stores";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useRef } from "react";
import GlobalProgress from "@app/components/common/GlobalProgress";

export default function ColoringManageCardView(props) {
  const { dataSource, pagination, onChange, loading, openViewSider } = props;
  const fgType = {
    1: "기본",
    2: "휴무",
    3: "영업종료",
  };
  const soundType = {
    1: "WAV",
    2: "TTS",
  };
  const { globalStore } = useMobxStores();
  const [cardScroll, inView] = useInView();
  const cardRef = useRef();

  useEffect(() => {
    return () => {
      globalStore.setViewTopButton(false);
    };
  }, []);

  useEffect(() => {
    if (inView && !loading && pagination?.current < pagination?.totalPage) {
      onChange({
        current: pagination.current + 1,
        pageSize: pagination.pageSize,
      });
    }
  }, [inView]);
  const [cardViewData, setCardViewData] = useState([]);

  useEffect(() => {
    if (pagination?.current === 1) {
      setCardViewData(dataSource);
      globalStore.setViewTopButton(false);
    } else {
      const clone = [...cardViewData];
      setCardViewData([...clone, ...dataSource]);
      globalStore.setViewTopButton(true);
    }
  }, [dataSource]);
  return (
    <>
      {loading && <GlobalProgress isBackColor />}
      {cardViewData.length > 0 &&
        cardViewData.map((data, dataIndex) => (
          <Card
            bordered={false}
            key={dataIndex}
            css={styles.cardWrap}
            bodyStyle={{ padding: "5px 10px 10px" }}
            ref={dataIndex == cardViewData.length - 1 ? cardScroll : cardRef}
          >
            <div
              style={{ position: "absolute", top: 5, right: 10 }}
              className="bold_font"
            >
              {data?.agent?.name}
            </div>
            <Button
              style={{ position: "absolute", top: 45, right: 0 }}
              type="link"
              icon={
                <RightOutlined
                  style={{
                    color: "#000000",
                  }}
                />
              }
              onClick={() => openViewSider("detail", data?.ringId)}
            />
            <div className="bold_font">
              <span style={{ color: "#FCB813" }}>
                {soundType[data?.type] || "-"}
              </span>{" "}
              / {fgType[data?.fg] || "-"}
            </div>
            <div>
              설정된 가상 번호 수: &nbsp;&nbsp;&nbsp;
              <span className="virtual_count">
                <span style={{ fontWeight: "bold" }}>
                  {data?.useCount || 0}
                </span>
                &nbsp;개
              </span>
            </div>
            <div>등록일 : {data?.registDatetime || "-"}</div>
            <Input.TextArea
              rows={2}
              value={`${data?.ringId}(${data?.description || "-"})`}
            />
          </Card>
        ))}
    </>
  );
}

const styles = {
  cardWrap: {
    borderBottom: "1px solid #686868",
    fontFamily: "Noto Sans",
    marginBottom: "5px",
    "& .bold_font": {
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .virtual_count": {
      color: "#96979C",
    },
  },
};
