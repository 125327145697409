import ResponsiveTable from "@app/components/table/ResponsiveTable"
import {
  commaOfNumber,
  phoneFormatter,
  virtualNumberFormatter,
} from "@app/utils/format"
import { Table } from "antd"

export default function BlackWhiteListTable(props) {
  const {
    openViewSider,
    virtualMappings,
    pagination,
    filter,
    setFilter,
    loading,
    userStore,
  } = props
  const columns = [
    {
      key: "agent.name",
      dataIndex: ["agent", "name"],
      title: "업체명",
      align: "center",
      width: "116px",
      className: "selectable",
      onCell: (record) => ({
        onClick: () => {
          openViewSider(
            "detail",
            record?.agent?.id,
            record?.black_white
              ? record?.black_white
              : record?.agent?.service?.blackAndWhite
              ? record?.agent?.service?.blackAndWhite
              : null,
            record?.status
          )
        },
      }),
      cardViewOnClick: (record) => {
        openViewSider(
          "detail",
          record?.agent?.id,
          record?.black_white
            ? record?.black_white
            : record?.agent?.service?.blackAndWhite
            ? record?.agent?.service?.blackAndWhite
            : null,
          record?.status
        )
      },
    },
    {
      key: "black_white",
      dataIndex: "black_white",
      title: "전체 설정 타입",
      align: "center",
      width: "172px",
      render: (text, record) =>
        text ||
        record?.agent?.service?.blackAndWhite ||
        record?.black_white ||
        "-",
    },
    {
      key: "status",
      dataIndex: "status",
      title: "전체 사용 여부",
      align: "center",
      width: "117px",
      render: (text, record) => {
        const returnText = {
          on: "사용",
          off: "미사용",
        }
        return returnText[text] || "-"
      },
    },
    {
      key: "virtualNumberBlackCount",
      dataIndex: "virtualNumberBlackCount",
      title: "050별 블랙리스트 수",
      align: "center",
      width: "130px",
      render: (text) => {
        return text
      },
    },
    {
      key: "virtualNumberWhiteCount",
      dataIndex: "virtualNumberWhiteCount",
      title: "050별 화이트리스트 수",
      align: "center",
      width: "136px",
      render: (text, record) => text,
    },
  ]
  return (
    <>
      {userStore?.super && (
        <p style={{ fontWeight: "bold" }}>
          조회된 사업장 수 : {commaOfNumber(pagination?.total)}개
        </p>
      )}
      <ResponsiveTable
        columns={columns}
        dataSource={virtualMappings}
        pagination={pagination}
        rowKey={(e) => e.agent?.id}
        onChange={(e) => {
          const page = {
            ...filter,
            page: e.current - 1,
            size: e.pageSize,
          }
          setFilter(page)
        }}
        scroll={{ x: 1430 }}
        loading={loading}
      />
    </>
  )
}
