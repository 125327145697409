import SearchForm from "@app/components/form/SearchForm"
import { Form, Input, Radio, Select, Space } from "antd"
import { useMobxStores } from "@app/stores/stores"
import { observer } from "mobx-react"
import AgentSelect from "@app/components/select/AgentSelect"

export default observer(function SKTLNumberSearch(props) {
  const { onSearch } = props
  const { userStore } = useMobxStores()
  const [form] = Form.useForm()
  //음원
  const assignmentOptions = [
    { label: "전체", value: "ALL" },
    { label: "할당", value: "Y" },
    { label: "미할당", value: "N" },
  ]
  //키워드
  const keywordOptions = [
    { label: "전체", value: "" },
    { label: "가상번호", value: "virtualNumber" },
    { label: "착신번호", value: "realNumber" },
    { label: "메모", value: "memo" },
    { label: "메모2", value: "memo2" },
  ]
  const initValues = {
    dateRange: [null, null],
    assignment: "ALL",
    agentId: "",
    keywordType: "",
    keyword: "",
  }

  return (
    <SearchForm
      footerButtons={true}
      onFinish={() => onSearch(form.getFieldsValue())}
    >
      <Form form={form} initialValues={initValues}>
        {/* <Form.Item name="dateRange" label="조회 날짜">
          <DatePicker.RangePicker />
        </Form.Item> */}
        {userStore?.super && (
          <Form.Item label="사업장" name="agentId">
            <AgentSelect
              showSearch
              allowClear
              allSearch
              style={{ width: "280px" }}
              params={{ externalYN: true }}
            />
          </Form.Item>
        )}
        <Form.Item name="assignment" label="착신 번호 할당 여부">
          <Radio.Group options={assignmentOptions} />
        </Form.Item>
        <Space style={{ marginBottom: "14px" }}>
          <Form.Item name="keywordType" noStyle>
            <Select options={keywordOptions} style={{ width: "122px" }} />
          </Form.Item>
          <Form.Item name="keyword" noStyle>
            <Input />
          </Form.Item>
        </Space>
      </Form>
    </SearchForm>
  )
})
