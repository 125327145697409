import dayjs from "dayjs";

// 천단위 구분
export function commaOfNumber(number) {
  if (number === undefined) return "";
  var string = "" + number; // 문자로 바꾸기.

  string = string.replace(/[^-+\.\d]/g, ""); // ±기호와 소수점, 숫자들만 남기고 전부 지우기.

  var regExp = /^([-+]?\d+)(\d{3})(\.\d+)?/; // 필요한 정규식.

  while (regExp.test(string))
    string = string.replace(regExp, "$1" + "," + "$2" + "$3"); // 쉼표 삽입.

  return string;
}

//전화번호 포맷
export function phoneFormatter(value) {
  if (!value) {
    return "";
  }
  value = value?.replace(/[^0-9]/g, "");

  let result = [];
  let restNumber = "";

  // 지역번호와 나머지 번호로 나누기
  if (value.startsWith("02")) {
    // 서울 02 지역번호
    result.push(value.substr(0, 2));
    restNumber = value.substring(2);
  } else if (value.startsWith("1")) {
    // 지역 번호가 없는 경우
    // 1xxx-yyyy
    restNumber = value;
  } else {
    // 나머지 3자리 지역번호
    // 0xx-yyyy-zzzz
    result.push(value.substr(0, 3));
    restNumber = value.substring(3);
  }

  if (restNumber.length === 7) {
    // 7자리만 남았을 때는 xxx-yyyy
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }

  return result.filter((val) => val).join("-");
}

export function virtualNumberFormatter(value) {
  if (!value) {
    return "";
  }
  value = value?.replace(/[^0-9]/g, "");
  let result = [];
  if (value.length === 12 && value.startsWith("050")) {
    result.push(value.substr(0, 4));
    result.push(value.substr(4, 4));
    result.push(value.substr(8, 4));
    return result.filter((val) => val).join("-");
  } else {
    return phoneFormatter(value);
  }
}
//antd timePicker에 맞게 시간 재가공 필요
export function setTimePickerFormat(time) {
  const hour = time?.substring(0, 2);
  const minuete = time?.substring(2, 4);
  return dayjs(`${hour}:${minuete}:00`, "HH:mm:ss");
}
