import { axiosInstance as axios } from "@app/axios"

export default {
  // 전화번호 인증 요청
  getAuthNumber: (phoneNumber) => {
    const requestOptions = {
      method: "POST",
      url: `api/sms/v1/auth/generate/phone/${phoneNumber}`,
    }
    return axios(requestOptions)
  },
  // 전화번호 인증 요청(토큰없이)
  getNoAuthNumber: (phoneNumber) => {
    const requestOptions = {
      method: "POST",
      url: `api/sms/v1/no-auth/generate/phone/${phoneNumber}`,
    }
    return axios(requestOptions)
  },
  // 전화번호 인증 확인
  setAuthNumber: (params) => {
    const requestOptions = {
      method: "PUT",
      url: `api/sms/v1/match/${params.authNumber}/phone/${params.phoneNumber}`,
    }
    return axios(requestOptions)
  },
}
