/** @jsxImportSource @emotion/react */
export default function Fotter() {
  return (
    <div css={styles.text}>
      Copyright ©2016 Produced by Delphicom. 서울특별시 금천구 디지털로9길 B동
      1504호 (가산동, 갑을그레이트밸리) / 대표전화 : 02-3397-7880 / FAX :
      0303-3444-2377
    </div>
  )
}

const styles = {
  text: {
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.45)",
    fontSize: "14px",
    lineHeight: "22px",
  },
}
