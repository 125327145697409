import { axiosInstance as axios } from "@app/axios"
import qs from "qs"

export default {
  // 기간사별 통계 조회 (슈퍼유저)
  getStatisticsBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/by-route?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 기간사별 통계 조회 엑셀 다운로드 (슈퍼유저)
  getStatisticsExcelBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/by-route/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // SKTL 통계 조회 (슈퍼유저)
  getStatisticsSktlBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/external?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // SKTL 통계 조회 엑셀 다운로드 (슈퍼유저)
  getStatisticsSktlExcelBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/external/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // SKTL 통계 조회 (일반유저)
  getStatisticsSktlByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/external/my?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // SKTL 통계 조회 엑셀 다운로드 (일반유저)
  getStatisticsSktlExcelByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/external/my/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 사업자별 통계 조회 (월별, 슈퍼유저)
  getStatisticsAgentMonthBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/by-agent/month?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 사업자별 통계 조회 (일자별, 슈퍼유저)
  getStatisticsAgentDayBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/by-agent/day?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 사업자별 통계 조회 (시간대별, 슈퍼유저)
  getStatisticsAgentHourBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/by-agent/hour?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 사업자별 통계 조회 엑셀 다운로드 (월별, 슈퍼유저)
  getStatisticsAgentExcelMonthBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/by-agent/month/export.xlsx?${qs.stringify(
        params,
        {
          arrayFormat: "repeat",
        }
      )}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 사업자별 통계 조회 엑셀 다운로드 (일자별, 슈퍼유저)
  getStatisticsAgentExcelDayBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/by-agent/day/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 사업자별 통계 조회 엑셀 다운로드 (시간대별, 슈퍼유저)
  getStatisticsAgentExcelHourBySuper: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/by-agent/hour/export.xlsx?${qs.stringify(
        params,
        {
          arrayFormat: "repeat",
        }
      )}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 콜로그 통계 조회 (페이징,일반유저)
  getStatisticsAgentByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/my?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 콜로그 통계 조회 엑셀 다운로드 (일반유저)
  getStatisticsAgentExcelByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/my/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
  // 콜로그 통계 일반유저
  getStatisticsByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/my?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  //콜로그 total 통계 일반유저
  getStatisticsTotalByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/my/total?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },

  //콜로그 통계 엑셀 다운로드 일반유저
  getStatisticsExcelByUser: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/statistics/v1/my/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
}
