/** @jsxImportSource @emotion/react */
import { useState, forwardRef, useImperativeHandle } from "react"
import { ConfigProvider, Table, Empty, Space } from "antd"
import dayjs from "dayjs"

import { commaOfNumber, virtualNumberFormatter } from "@utils/format"
import { useMobxStores } from "@stores/stores"
import { useIsMobile } from "@utils/style"

import statisticsApi from "@api/statistics"

export default forwardRef(function AgentStatsTableByUser(props, ref) {
  const { viewVN, lastParams } = props
  const { globalStore } = useMobxStores()
  const isMobile = useIsMobile()

  const [stats, setStats] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState({})
  const [pagination, setPagination] = useState()

  // 부모컴포넌트에서 리스트 검색
  useImperativeHandle(ref, () => ({
    fetchList(params) {
      fetchStatsList({
        ...params,
      })
      fetchStatsTotalCnt({ ...params })
    },
  }))

  // 사업장별 통계 리스트
  async function fetchStatsList(params) {
    setLoading(true)
    try {
      const statsProm = await statisticsApi.getStatisticsByUser(params)
      const stats = statsProm?.data?.data?.map((e, i) => {
        e.key = i
        return e
      })
      const meta = statsProm?.data?.meta
      const pageMeta = {
        total: meta.totalCount,
        current: meta.page.pageNumber + 1,
        pageSize: meta.page.pageSize,
        totalPage: meta.totalPage,
      }
      setPagination(pageMeta)
      setStats(stats)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  async function fetchStatsTotalCnt(params) {
    try {
      const totalProm = await statisticsApi.getStatisticsTotalByUser(params)
      const total = totalProm?.data?.data
      total.duration = Math.floor(total.duration / 60)
      total.outDuration = Math.floor(total.outDuration / 60)

      setTotal(total)
    } catch (e) {
      console.log(e)
    }
  }

  const columns = [
    {
      key: "memo1",
      dataIndex: "memo1",
      title: "상점명",
      align: "center",
      width: "25%",
      render(text) {
        return text || "-"
      },
      isView: viewVN,
    },
    {
      key: "unitDate",
      dataIndex: "unitDate",
      title: "일",
      align: "center",
      render(text) {
        return text
          ? dayjs(text).format(
              text.length === 6 ? "YYYY년 MM월" : "YYYY년 MM월 DD일"
            )
          : "-"
      },
      isView: true,
    },
    {
      key: "virtualNumber",
      dataIndex: "virtualNumber",
      title: "가상번호",
      align: "center",
      isView: viewVN,
      render(text) {
        return text ? virtualNumberFormatter(text) : "-"
      },
    },
    {
      key: "duration",
      dataIndex: "duration",
      title: "통화시간(분)",
      align: "center",
      sorter: (a, b) => a.outDuration - b.duration,
      render(text) {
        return text ? commaOfNumber(Math.floor(text / 60)) : 0
      },
      isView: true,
    },
    {
      key: "outDuration",
      dataIndex: "outDuration",
      title: "착신 통화시간(분)",
      align: "center",
      sorter: (a, b) => a.outDuration - b.outDuration,
      render(text) {
        return text ? commaOfNumber(Math.floor(text / 60)) : 0
      },
      isView: true,
    },
    {
      key: "callCount",
      dataIndex: "callCount",
      title: "건수",
      align: "center",
      sorter: (a, b) => a.callCount - b.callCount,
      render(text) {
        return commaOfNumber(text || 0)
      },
      isView: true,
    },
  ]

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="검색결과가 없습니다." />}
    >
      {isMobile && (
        <Space style={{ fontWeight: "bold" }}>
          <span>통화시간(분) : {commaOfNumber(total?.duration || 0)}</span>
          <span> 건수 : {commaOfNumber(total?.callCount || 0)}</span>
        </Space>
      )}
      <Table
        tableLayout="fixed"
        rowKey={(record) => record.key}
        columns={columns.filter((column) => {
          if (column?.isView) return true
        })}
        dataSource={stats}
        pagination={pagination}
        onChange={(e) => {
          const page = {
            ...lastParams,
            page: e.current - 1,
            size: e.pageSize,
          }
          fetchStatsList(page)
        }}
        loading={loading}
        scroll={{ x: 800, y: 10000000 }}
        summary={() => {
          return (
            <Table.Summary fixed="top">
              {!isMobile && stats?.length > 0 && (
                <Table.Summary.Row>
                  {viewVN && <Table.Summary.Cell index={0} />}
                  <Table.Summary.Cell index={viewVN ? 1 : 0}>
                    합계
                  </Table.Summary.Cell>
                  {viewVN && <Table.Summary.Cell index={2} />}
                  <Table.Summary.Cell index={viewVN ? 3 : 1}>
                    {commaOfNumber(total?.duration)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={viewVN ? 4 : 2}>
                    {commaOfNumber(total?.outDuration)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={viewVN ? 5 : 3}>
                    {commaOfNumber(total?.callCount)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            </Table.Summary>
          )
        }}
      />
    </ConfigProvider>
  )
})
