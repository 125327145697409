import { axiosInstance as axios } from "@app/axios"
import qs from "qs"

export default {
  // 업체 리스트 기준정보 조회(페이징x)
  getAgents: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/agents/v1?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 업체 리스트 조회(페이징)
  getAgentsContains: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/agents/v1/contains?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
    }
    return axios(requestOptions)
  },
  // 업체 조회(단일)
  getAgentById: (agentId) => {
    const requestOptions = {
      method: "GET",
      url: `/api/agents/v1/${agentId}`,
    }
    return axios(requestOptions)
  },
  // 업체 등록
  createAgent: (data) => {
    const requestOptions = {
      method: "POST",
      url: "/api/agents/v1",
      data: JSON.stringify(data),
    }
    return axios(requestOptions)
  },
  // 업체 수정
  updateAgent: (agentId, data) => {
    const requestOptions = {
      method: "PUT",
      url: `/api/agents/v1/${agentId}`,
      data: JSON.stringify(data),
    }
    return axios(requestOptions)
  },
  // 업체 리스트 엑셀 다운로드
  getAgentsExcel: (params) => {
    const requestOptions = {
      method: "GET",
      url: `/api/agents/v1/contains/export.xlsx?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`,
      timeout: 3 * 60 * 1000,
      responseType: "blob",
    }
    return axios(requestOptions)
  },
}
