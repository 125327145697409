import { useState, useRef } from "react"
import dayjs from "dayjs"
import { observer } from "mobx-react"

import PageContainer from "@components/container/PageContainer"
import PageHeader from "@components/container/HeaderContainer"
import ContentContainer from "@components/container/ContentContainer"
import DownLoadButton from "@components/button/DownLoadButton"

import AgentStatsSearch from "@containers/stats/agent/AgentStatsSearch"
import AgentStatsTable from "@containers/stats/agent/AgentStatsTable"

import { objectClean } from "@utils/router"
import _cloneDeep from "lodash/cloneDeep"
import { useMobxStores } from "@stores/stores"
import { fileDownload } from "@utils/file"

import statisticsApi from "@api/statistics"

export default observer(function AgentStatsPage() {
  const { userStore, globalStore } = useMobxStores()
  const tableRef = useRef()
  const [lastParams, setLastParams] = useState(null)
  const [loading, setLoading] = useState(false)
  const [viewVN, setViewVN] = useState(false) //가상번호표시 검색

  function search(params) {
    const filter = _cloneDeep(params)
    const to = dayjs(filter.toDate?.toString())
    const from = dayjs(filter.fromDate?.toString())
    const limitRange = filter?.viewVN ? 31 : 120
    if (!filter.toDate || !filter.fromDate) {
      setLoading(false)
      return globalStore.warningNoti("조회날짜를 선택해주세요")
    }
    if (to && from && to.diff(from, "day", false) > limitRange)
      return globalStore.warningNoti(
        `${limitRange}일 이내의 기록만 조회할 수 있습니다.`
      )
    if (filter.agentId === "byAgent") {
      filter.byAgent = true
      delete filter.agentId
    } else {
      delete filter.byAgent
    }

    if (filter.agentId === "all") delete filter.agentId
    if (filter.anIsMobile === "all") delete filter.anIsMobile
    if (filter.rnIsMobile === "all") delete filter.rnIsMobile
    if (filter.viewVN === "all") delete filter.viewVN
    delete filter.dateRange
    delete filter.dateSingle

    setViewVN(filter?.viewVN)
    objectClean(filter)
    setLastParams(filter)
    tableRef?.current?.fetchList(filter)
  }

  async function onDownload() {
    // 엑셀 다운로드
    setLoading(true)
    try {
      let filter = _cloneDeep(lastParams)

      if (!filter.toDate || !filter.fromDate) {
        setLoading(false)
        return globalStore.warningNoti("조회날짜를 선택해주세요")
      }

      if (
        userStore?.super &&
        !filter.byAgent &&
        (filter.agentId === "all" || !filter.agentId)
      ) {
        setLoading(false)
        return globalStore.warningNoti("사업장을 선택해주세요")
      }

      const res =
        filter?.byDateType === "MONTH"
          ? await statisticsApi.getStatisticsAgentExcelMonthBySuper(filter)
          : filter?.byDateType === "DATE"
          ? await statisticsApi.getStatisticsAgentExcelDayBySuper(filter)
          : await statisticsApi.getStatisticsAgentExcelHourBySuper(filter)
      const resData = res.data
      const today = dayjs().format("YYYYMMDDHHmm")
      const fileTitle = "사업자별_통계_"
      fileDownload(resData, fileTitle + today + ".xlsx")
      setLoading(false)
    } catch (e) {
      setLoading(false)
      globalStore.errorNoti(
        e?.response?.data?.meta?.userMessage || e?.message || e
      )
    }
  }

  return (
    <>
      <PageContainer>
        <PageHeader
          title="사업자별"
          subTitle="사업자 별 월별통계를 조회합니다."
          rightActions={
            <>
              <DownLoadButton
                onClick={onDownload}
                loading={loading}
                text="다운로드"
              />
            </>
          }
        />
        <AgentStatsSearch search={search} />
        <ContentContainer wrapper>
          <AgentStatsTable ref={tableRef} viewVN={viewVN} />
        </ContentContainer>
      </PageContainer>
    </>
  )
})
