/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom"
import { Form, Input, Button, Col, Card } from "antd"

import { useMobxStores } from "@stores/stores"
import { MediaQuery } from "@utils/style"

import userApi from "@api/user"

export default function FindPage() {
  const [idForm] = Form.useForm()
  const [pwForm] = Form.useForm()
  const navigate = useNavigate()
  const { globalStore } = useMobxStores()

  async function findInfo(type) {
    /**
     * ID, PW 찾기
     * type : 1. id  2. pw
     **/
    const findPw = type === "pw"
    try {
      const value = findPw ? pwForm.getFieldValue() : idForm.getFieldValue()

      if (!value.name) return globalStore.warningNoti("이름을 입력해주세요.")
      if (findPw && !value.id)
        return globalStore.warningNoti("아이디를 입력해주세요.")
      if (!value.phoneNumber)
        return globalStore.warningNoti("핸드폰 번호를 입력해주세요.")

      const params = {
        userName: value.name,
        phoneNumber: value.phoneNumber,
      }
      if (findPw) {
        params.id = value.id
        await userApi.findUserPassWord(params)
      } else {
        await userApi.findUserId(params)
      }
      globalStore.successNoti(
        "이메일로 아이디/비밀번호 전달하였습니다.",
        "아이디/비밀번호 찾기 성공"
      )
      navigate("/login")
    } catch (e) {
      globalStore.errorNoti("존재하지 않는 사용자 정보입니다.")
    }
  }

  return (
    <div css={styles.container}>
      <Form form={idForm}>
        <Card bordered={false}>
          <Col className="title">아이디찾기</Col>
          <Form.Item name="name" label="이름">
            <Input />
          </Form.Item>
          <Form.Item name="phoneNumber" label="핸드폰 번호">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              block
              onClick={() => findInfo("id")}
            >
              아이디 찾기
            </Button>
          </Form.Item>
        </Card>
      </Form>
      <Form form={pwForm}>
        <Card bordered={false}>
          <Col className="title">비밀번호 찾기</Col>
          <Form.Item name="name" label="이름">
            <Input />
          </Form.Item>
          <Form.Item name="id" label="아이디">
            <Input />
          </Form.Item>
          <Form.Item name="phoneNumber" label="핸드폰 번호">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="primary"
              block
              onClick={() => findInfo("pw")}
            >
              비밀번호 찾기
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </div>
  )
}

const styles = {
  container: {
    height: "75vh",
    [MediaQuery[1]]: {
      height: "80vh",
    },
    "& .ant-card": {
      minWidth: "20vw",
      [MediaQuery[1]]: {
        minWidth: "50vw",
        "& .ant-card-body": {
          padding: 12,
          "& .ant-form-item": {
            marginBottom: 12,
          },
        },
      },
      "& .title": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "22px",
        padding: "10px 0",
        borderBottom: "1px solid #D9D9D9;",
        marginBottom: 14,
      },
    },
  },
}
