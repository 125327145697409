import ResponsiveTable from "@app/components/table/ResponsiveTable"
import { commaOfNumber } from "@app/utils/format"
import { useIsMobile } from "@app/utils/style"
import { Table } from "antd"
import ColoringManageCardView from "@app/containers/management/coloring/list/ColoringManageCardView"

export default function ColoringManageTable(props) {
  const {
    openViewSider,
    colorings,
    pagination,
    filter,
    setFilter,
    loading,
    isAlarm,
  } = props
  const isMobile = useIsMobile()
  const columns = [
    {
      key: "ringId",
      dataIndex: "ringId",
      title: "아이디",
      align: "center",
      width: 100,
      className: "selectable",
      onCell: (record) => ({
        onClick: () => openViewSider("detail", record?.ringId),
      }),
      cardViewOnClick: (record) => openViewSider("detail", record?.ringId),
    },
    {
      key: "agent.name",
      dataIndex: ["agent", "name"],
      title: "사업장",
      align: "center",
      width: 100,
    },
    {
      key: "type",
      dataIndex: "type",
      title: "음원 형식",
      align: "center",
      width: 100,
      render: (text, record) => {
        const returnText = {
          1: "WAV",
          2: "TTS",
        }
        return returnText[text]
      },
    },
    {
      key: "fg",
      dataIndex: "fg",
      title: "종류",
      align: "center",
      width: 100,
      render: (text) => {
        const returnText = {
          1: "기본",
          2: "휴무",
          3: "영업종료",
        }
        return returnText[text]
      },
    },
    {
      key: "description",
      dataIndex: "description",
      title: "설명",
      align: "center",
      width: 468,
    },
    {
      key: "useCount",
      dataIndex: "useCount",
      title: "설정된 번호 수",
      align: "center",
      width: 131,
    },
    {
      key: "registDatetime",
      dataIndex: "registDatetime",
      title: "등록일",
      align: "center",
      width: 163,
    },
  ]
  return (
    <>
      <p style={{ fontWeight: "bold" }}>
        {isAlarm ? "알림멘트 수" : "컬러링 수"} :{" "}
        {commaOfNumber(pagination?.total || 0)}개
      </p>
      {!isMobile ? (
        <Table
          columns={columns}
          dataSource={colorings}
          pagination={pagination}
          rowKey={(e) => e.ringId}
          onChange={(e) => {
            const page = {
              ...filter,
              page: e.current - 1,
              size: e.pageSize,
            }
            setFilter(page)
          }}
          scroll={{ x: 1400 }}
          loading={loading}
        />
      ) : (
        <ColoringManageCardView
          dataSource={colorings}
          pagination={pagination}
          onChange={(e) => {
            const page = {
              ...filter,
              page: e.current - 1,
              size: e.pageSize,
            }
            setFilter(page)
          }}
          loading={loading}
          openViewSider={openViewSider}
        />
      )}
    </>
  )
}
