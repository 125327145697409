import React, { useEffect, useState } from "react"
import PropType from "prop-types"

import { Input as AntdInput } from "antd"
import { phoneFormatter, virtualNumberFormatter } from "@app/utils/format"

PhoneNumberInput.propTypes = {
  disabled: PropType.bool,
  id: PropType.string,
  type: PropType.string,
  value: PropType.oneOfType([PropType.string, PropType.number]),
  onChange: PropType.func,
  onBlur: PropType.func,
  onPressEnter: PropType.func,
  placeholder: PropType.string,
  isVirtualNum: PropType.bool,
}
PhoneNumberInput.defaultProps = {
  disabled: false,
  type: "text",
  value: "",
  onChange: () => {},
  onBlur: () => {},
  onPressEnter: () => {},
  placeholder: "전화번호를 입력하세요.",
  isVirtualNum: false,
}

function PhoneNumberInput(props) {
  const {
    onChange,
    onPressEnter,
    value,
    inputRef,
    style,
    size,
    placeholder,
    disabled,
    type,
    allowClear,
    isVirtualNum,
    addonAfter,
  } = props

  const [maskedValue, setMaskedValue] = useState(value)
  useEffect(() => {
    setMaskedValue(
      isVirtualNum ? virtualNumberFormatter(value) : phoneFormatter(value)
    )
  }, [value])

  return (
    <AntdInput
      ref={inputRef}
      value={maskedValue}
      onChange={emitChange}
      onBlur={onBlur}
      onPressEnter={onPressEnter}
      style={style}
      size={size}
      placeholder={placeholder}
      disabled={disabled}
      type={type}
      allowClear={allowClear}
      addonAfter={addonAfter}
    />
  )

  function onBlur(event) {
    const { onBlur } = props
    if (onBlur) onBlur(event)
  }

  function emitChange(e) {
    const { value } = e.target
    setMaskedValue(
      isVirtualNum ? virtualNumberFormatter(value) : phoneFormatter(value)
    )

    if (onChange) onChange(e)
  }
}

export default PhoneNumberInput
