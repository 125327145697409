/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import { Button, Popconfirm, Table } from "antd";
import { PlusCircleFilled, CloseOutlined } from "@ant-design/icons";
import blackWhite from "@app/axios/blackWhite";
import { useMobxStores } from "@app/stores/stores";
import { useState } from "react";

export default function BlackWhiteDetailAllTable(props) {
  const { agentId, allList, setAllList, setInputModal, setAddNumberList } =
    props;
  const { globalStore } = useMobxStores();
  const [loading, setLoading] = useState(false);

  function deleteRow(record, index) {
    if (!record?.callingNumber) return;
    setLoading(true);
    blackWhite
      .deleteBlackWhiteData(agentId, record?.callingNumber)
      .then((res) => {
        const clone = [...allList];
        setAllList(clone.filter((data, dataIndex) => dataIndex !== index));
        globalStore.successNoti("삭제 성공");
      })
      .catch((e) => globalStore.errorNoti("삭제 실패"))
      .finally(() => setLoading(false));
  }

  const columns = [
    {
      key: "callingNumber",
      dataIndex: "callingNumber",
      title: "발신 번호",
      align: "center",
      width: "85%",
      render: (text, record) => text || "-",
    },
    {
      key: "delete",
      align: "center",
      width: "10%",
      dataIndex: "isBtnDisable",
      title: () => {
        return (
          <PlusCircleFilled
            style={{ color: "#007def" }}
            onClick={() => {
              console.log(allList);
              setInputModal("all");
              setAddNumberList(allList);
            }}
          />
        );
      },
      render: (text, record, index) => (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Popconfirm
              title={"정말 삭제 하시겠습니까?"}
              cancelText={"취소"}
              okText={"확인"}
              onConfirm={() => deleteRow(record, index)}
              onCancel={() => {}}
              style={{ width: "300px !important" }}
            >
              <Button type="text" icon={<CloseOutlined />} />
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={allList}
      tableLayout="fixed"
      size="small"
      pagination={false}
      scroll={{ x: 530, y: 300 }}
      loading={loading}
      rowKey={(data) => data?.callingNumber}
    />
  );
}
